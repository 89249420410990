import React from "react";

export default function TimeSheetDetailsView() {
  const details = [
    { label: "Name", value: "last_name" },
    { label: "Email", value: "email" },
    { label: "Type", value: "type" },
    { label: "Punched Time", value: "time" },
    { label: "Created Time", value: "createdAt" },
  ];

  return (
    <div className="grid grid-cols-3 gap-8">
      {details.map((detail, index) => (
        <div className="flex flex-col" key={index}>
          <h2 className="flex gap-2 mb-1 text-[14px] font-semibold leading-none">
            {detail.label}
          </h2>
          <h3 className="text-[13px] font-normal overflow-hidden text-ellipsis">
            {detail.value}
          </h3>
        </div>
      ))}
    </div>
  );
}
