import React, { useState } from "react";
import { Add } from "@mui/icons-material";
import SubHeader from "../../Components/Molecules/Subheader";
import CustomTable from "../../Components/Molecules/CustomTable";
import FilterSortby from "../../Components/Molecules/FilterSortBy";
import { useNavigate } from "react-router-dom";

const InvoicePage = () => {
  const navigate = useNavigate();
  const data = [
    {
      quoteID: "QU-5901",
      questionGroup: "Group 1",
      noOfQuestions: 5,
      notifyEndDate: "22.07.2024",
      notifyEndDates: "New",

      answeredStatus: "Answered",
      reviewedStatus: "Reviewed",
    },
  ];
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];

  return (
    <>
      <div className="">
        <SubHeader
          headings={[
            {
              name: "Invoice",
            },
          ]}
          actions={[
            {
              type: "button",
              name: "New Invoice",

              onclick: () => {
                navigate("/invoice/invoiceform");
              },
              icon: (
                <Add
                  sx={{
                    fontSize: "18px",
                    marginRight: "5px",
                    color: "#7F7F7F",
                  }}
                />
              ),
            },
          ]}
        />
        <div>
          {" "}
          <FilterSortby
            exportBtn="false"
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            onClickFilter={() => {}}
          />
          <div className="overflow-auto">
            <CustomTable
              tableTitle={[
                "INVOICE #",
                "CUSTOMER",
                "QUOTE TOTAL",
                "STATUS",
                "INVOICE DATE",
                "CREATED AT",
                "EXPIRY DATE",
                "ACTIONS",
              ]}
              navigateView={() => navigate("/employee/view")}
              tableData={data}
              viewButton={true}
              editButton={true}
              deleteButton={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoicePage;
