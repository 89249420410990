import React, { useRef, useState } from "react";
import SubHeader from "../../Components/Molecules/Subheader";
import Whitespace from "../../Components/Atoms/WhiteSpace";
import CustomInput from "../../Components/Atoms/CustomInput";
import ComponentTitle from "../../Components/Atoms/ComponentTitle";

import { imageFileFormat } from "../../constants";
import userdummy from "../../Assets/user-dummy.jpg";

import CustomSelect from "../../Components/Atoms/CustomSelect";
import PhoneNumber from "../../Components/Atoms/PhoneNumber";
import TextArea from "../../Components/Atoms/TextArea";

const CustomerForm = () => {
  const [checkphone, setCheckphone] = useState(0);

  const [image, setImage] = useState(userdummy);
  const hiddenFileInput = useRef(null);
  const handleChange = (e) => {
    const filename = e?.currentTarget?.files[0]?.name?.split(".");
    if (filename && filename[0]) {
      const format = filename[filename?.length - 1];
      if (imageFileFormat.includes(format)) {
        setImage(URL.createObjectURL(e.currentTarget.files[0]));
      }
    }
  };
  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  return (
    <>
      <div className="">
        <SubHeader
          headings={[
            {
              name: "Customer",
            },
            {
              name: "Create New Customer",
            },
          ]}
          actions={[
            {
              type: "button",
              btnType: "submit",
              name: "Save",
            },
          ]}
        />
        <Whitespace mt="23px" className={"px-14 py-7"}>
          <ComponentTitle title={"customer INFORMATION"} />
          <div className="flex gap-14 items-center mt-4">
            <div className="flex justify-start items-center">
              <div className="flex flex-col gap-2.5 justify-start items-center relative">
                <img
                  className="w-[165px] h-[145px] border border-solid border-gray-200 rounded-full flex items-center justify-center"
                  src={image}
                />
                <input
                  type="file"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  className="hidden"
                  accept="image/jpg,image/png,image/jpeg"
                />
                <div
                  className="px-6 py-2  rounded bg-primColor text-[#ffffff] flex justify-center items-center text-[13px] font-light leading-4.5 tracking-wide cursor-pointer  border border-[#A9A9A9] border-1"
                  onClick={handleClick}
                >
                  Upload
                </div>
              </div>
            </div>
            <div className="columnThree w-full">
              <CustomInput
                margin="true"
                labelText="First Name"
                name="username"
                placeholder="Enter first name"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Last Name"
                name="username"
                placeholder="Enter last name"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Choose Organization*"
                name="username"
                placeholder="Choose Oganization"
                type="text"
              />

              <CustomInput
                margin="true"
                labelText="Email"
                name="username"
                placeholder="Enter email"
                type="text"
              />

              <CustomSelect
                setValue={"_id"}
                labelText="Category"
                placeholder="Select a Customer Category"
              />
              <CustomInput
                margin="true"
                labelText="Customer Tags"
                name="username"
                placeholder="Enter Customer Tags"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Portal Access"
                name="username"
                placeholder="Pick An Option"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Has Slat"
                name="username"
                placeholder="Pick An Option"
                type="text"
              />
            </div>
          </div>
          <div>
            <TextArea
              labelText={"Description"}
              placeholder={"Enter Description"}
            />
            <ComponentTitle title="CONTACT INFORMATION" />
            <div className="columnThree w-full">
              <CustomInput
                margin="true"
                labelText="Home Number"
                name="username"
                placeholder="Enter Home Number"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Mobile Number"
                name="username"
                placeholder="Enter Mobile Number"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Work Number"
                name="username"
                placeholder="Enter Work Number"
                type="text"
              />
            </div>
          </div>
        </Whitespace>
        <Whitespace className={"mt-7"}>
          <ComponentTitle title="ACCOUNT DETAILS" />
          <div className="columnThree w-full">
            <CustomInput
              margin="true"
              labelText="Billing Frequency"
              name="username"
              placeholder="Choose Billing Frequency"
              type="text"
            />
            <CustomInput
              margin="true"
              labelText="Payment Terms"
              name="username"
              placeholder="Pick a Payment Term"
              type="text"
            />
            <CustomInput
              margin="true"
              labelText="Tax Group"
              name="username"
              placeholder="Pick a Tax Group"
              type="text"
            />
          </div>
        </Whitespace>
        <div className="flex gap-5 w-full mt-7">
          <Whitespace className={"w-[50%]"}>
            <ComponentTitle title="SERVICE CONTACT INFO" />
            <div className="columnTwo">
              <CustomInput
                margin="true"
                labelText="Contact First Name"
                name="username"
                placeholder="Enter contact first name"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Contact Last Name"
                name="username"
                placeholder="Enter contact last name"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Contact Email"
                name="username"
                placeholder="Enter contact email"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Contact Phone Number"
                name="username"
                placeholder="Enter contact phone number"
                type="text"
              />
            </div>
            <CustomInput
              margin="true"
              labelText="Street Address"
              name="username"
              placeholder="Flat / House No, Street / Location"
              type="text"
            />
            <div className="columnTwo">
              <CustomInput
                margin="true"
                labelText="Landmark"
                name="username"
                placeholder="Enter landmark"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="City"
                name="username"
                placeholder="Enter city"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="State / Province / Region"
                name="username"
                placeholder="Enter state / province / region"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Country"
                name="username"
                placeholder="Choose country"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Postal / Zipcode"
                name="username"
                placeholder="Enter Postal / Zipcode"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Latitude"
                name="username"
                placeholder="Enter Latitude"
                type="text"
              />{" "}
              <CustomInput
                margin="true"
                labelText="Longitude"
                name="username"
                placeholder="Enter Longtitude"
                type="text"
              />
            </div>
          </Whitespace>
          <Whitespace className={"w-[50%]"}>
            <div className="flex content-between items-center">
              <ComponentTitle title="BILLING CONTACT INFO" />
              <div className="flex gap-2 items-center whitespace-nowrap">
                <p className="font-[400]">Same as service contact</p>
                <input
                  type="checkbox"
                  className="border border-[#A9A9A9] outline-none w-6 h-5 rounded-[8px]"
                />
              </div>
            </div>
            <div className="columnTwo">
              <CustomInput
                margin="true"
                labelText="Contact First Name"
                name="username"
                placeholder="Enter contact first name"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Contact Last Name"
                name="username"
                placeholder="Enter contact last name"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Contact Email"
                name="username"
                placeholder="Enter contact email"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Contact Phone Number"
                name="username"
                placeholder="Enter contact phone number"
                type="text"
              />
            </div>
            <CustomInput
              margin="true"
              labelText="Street Address"
              name="username"
              placeholder="Flat / House No, Street / Location"
              type="text"
            />
            <div className="columnTwo">
              <CustomInput
                margin="true"
                labelText="Landmark"
                name="username"
                placeholder="Enter landmark"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="City"
                name="username"
                placeholder="Enter city"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="State / Province / Region"
                name="username"
                placeholder="Enter state / province / region"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Country"
                name="username"
                placeholder="Choose country"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Postal / Zipcode"
                name="username"
                placeholder="Enter Postal / Zipcode"
                type="text"
              />
            </div>
          </Whitespace>
        </div>
      </div>
    </>
  );
};

export default CustomerForm;
