import React from "react";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../Components/Atoms/CustomCancelSubmit";
import TextArea from "../../Components/Atoms/TextArea";

const TimeSheetDetailsForm = () => {
  return (
    <>
      <div className="">
        <div className=" ">
          <CustomInput
            margin="true"
            name="User"
            labelText="Type of Entry"
            placeholder="Choose Type of Entry"
            type="text"
          />
          <CustomInput
            margin="true"
            name="User"
            labelText="Select Location"
            placeholder="Choose Location"
            type="text"
          />{" "}
          <CustomInput
            margin="true"
            name="User"
            labelText="Select Date"
            type="date"
          />
          <CustomInput
            margin="true"
            name="User"
            labelText="Select Time"
            type="time"
          />
          <TextArea
            margin="true"
            name="User"
            labelText="Remarks"
            placeholder="Enter Remarks"
            type="text"
          />{" "}
        </div>

        <div className="mt-5">
          <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
        </div>
      </div>
    </>
  );
};

export default TimeSheetDetailsForm;
