import React from "react";
import CustomInput from "../../../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../../../Components/Atoms/CustomCancelSubmit";
import TextArea from "../../../../Components/Atoms/TextArea";
import InputLable from "../../../../Components/Atoms/InputLable";

const ProductCategoryForm = () => {
  return (
    <>
      <div className="">
        <div className="">
          <CustomInput
            margin="true"
            name="User"
            labelText="Category Name (Max 26 Characters)"
            placeholder="Maintenace"
            type="text"
          />
        </div>

        <TextArea
          margin="true"
          labelText="Category Description"
          name="lastname"
          placeholder="Enter Description"
          type="date"
        />
        <div className="flex items-center">
          <div className="w-[190px]">
            <div className="mb-1">
              <InputLable labelText={"Icon For Product Category"} />
            </div>
            <input
              type="file"
              name="icon"
              className="bg-transparent opacity-0 absolute"
              accept="image/*"
            />
            <label className="text-center text-sm font-medium text-black cursor-pointer bg-[#FCFAFA] border border-dashed border-[#A9A9A9] rounded w-[110px] h-[110px] flex justify-center items-center hover:border-black">
              + Add
            </label>
          </div>
          {/* {file ? (
            <div className="ml-2 mt-[45px]">
              <img
                className="w-[84px] h-[97px]"
                src={file}
                alt="product icon"
              />
            </div>
          ) : null} */}
        </div>

        <div className="mt-5">
          <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
        </div>
      </div>
    </>
  );
};

export default ProductCategoryForm;
