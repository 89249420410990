import React from "react";
import CustomInput from "../../../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../../../Components/Atoms/CustomCancelSubmit";

const TimeOffRequestForm = () => {
  return (
    <>
      <div className="">
        <div className="columnTwo">
          <CustomInput
            margin="true"
            name="User"
            labelText="Request Type Name"
            placeholder="Eg: Casual Leave"
            type="text"
          />
          <CustomInput
            margin="true"
            name="User"
            labelText="Number of days allowed (in a year)"
            placeholder="Eg: 1"
            type="text"
          />
        </div>
        <div className="columnTwo">
          <CustomInput
            margin="true"
            name="User"
            labelText="Type"
            placeholder="Pick an Option"
            type="text"
          />
        </div>
        <div className="mt-3">
          <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
        </div>
      </div>
    </>
  );
};

export default TimeOffRequestForm;
