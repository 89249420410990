import React from "react";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../../Components/Atoms/CustomCancelSubmit";
import TextArea from "../../../Components/Atoms/TextArea";
import CustomSelect from "../../../Components/Atoms/CustomSelect";

const TimesheetApprovalForm = () => {
  return (
    <>
      <div className="">
        <div className="columnTwo ">
          <CustomInput labelText="To Date" type="date" />

          <CustomInput
            margin="true"
            name="User"
            labelText="To Date"
            type="date"
          />

          <CustomSelect
            margin="true"
            name="User"
            labelText="Choose Team"
            placeholder="Enter Team Name"
            type="text"
          />
          <CustomSelect
            margin="true"
            name="User"
            labelText="User"
            placeholder="Choose User*"
            type="text"
          />
        </div>
        <TextArea labelText="Remarks" placeholder="Enter Remarks" />
        <div className="mt-3">
          <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
        </div>
      </div>
    </>
  );
};

export default TimesheetApprovalForm;
