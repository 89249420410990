import React, { useCallback, useState } from "react";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../../Components/Molecules/CustomTable";
import Whitespace from "../../../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../../../Components/Atoms/ComponentTitle";
import CustomButton from "../../../../Components/Atoms/CustomButton";
import CustomDialog from "../../../../Components/Molecules/CustomDialog";
import TaxForm from "./TaxForm";
import TaxGroupForm from "./TaxGroupForm";

const TaxSettings = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const [groupOpen, setGroupOpen] = useState(false);

  const handleGroupOpen = () => setGroupOpen(true);
  const handleGroupClose = useCallback(() => {
    setGroupOpen(false);
  }, [groupOpen]);

  const navigate = useNavigate();
  const data = [
    {
      siNo: 1,
      questionGroup: "Group 1",
      noOfQuestions: 5,
      notifyEndDate: "22.07.2024",
    },
    {
      siNo: 2,
      questionGroup: "Group 2",
      noOfQuestions: 10,
      notifyEndDate: "22.07.2024",
    },
  ];

  return (
    <>
      <CustomDialog handleClose={handleClose} open={open} Title={"Create Tax"}>
        <TaxForm />
      </CustomDialog>
      <CustomDialog
        handleClose={handleGroupClose}
        open={groupOpen}
        Title={"Create Tax Group"}
      >
        <TaxGroupForm />
      </CustomDialog>
      <div className="">
        <Whitespace>
          <div className="flex items-center mb-7">
            <ComponentTitle title="tax" mb="0" />
            <CustomButton size="medium" onClick={() => handleOpen()}>
              <Add sx={{ fontSize: "15px" }} />
              New&nbsp;Tax
            </CustomButton>
          </div>
          <div className="overflow-auto">
            <CustomTable
              tableTitle={["#", "TAX NAME", "RATE", "created by", "ACTIONS"]}
              navigateView={() => navigate("/employee/view")}
              tableData={data}
              viewButton={true}
              editButton={true}
              deleteButton={false}
            />
          </div>
        </Whitespace>

        <Whitespace className={"mt-5"}>
          <div className="flex items-center mb-7">
            <ComponentTitle title="TAX GROUP" mb="0" />
            <CustomButton size="medium" onClick={() => handleGroupOpen()}>
              <Add sx={{ fontSize: "15px" }} />
              New&nbsp;Tax&nbsp;Group
            </CustomButton>
          </div>
          <div className="overflow-auto">
            <CustomTable
              tableTitle={[
                "#",
                "TAX GROUP NAME",
                "CREATED BY",
                "created AT",
                "ACTIONS",
              ]}
              navigateView={() => navigate("/employee/view")}
              tableData={data}
              viewButton={true}
              editButton={true}
              deleteButton={false}
            />
          </div>
        </Whitespace>
      </div>
    </>
  );
};

export default TaxSettings;
