import React from "react";
import AuthLayout from "../../Components/Layout/AuthLayout";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton";

const ResetPassword = () => {
  return (
    <AuthLayout showButton={true}>
      <div className="w-full mx-auto">
        <form>
          <div className="flex items-center gap-1">
            <p className="text-customGray text-3xl font-semibold">Reset</p>
            <p className="text-primColor text-3xl font-semibold">Password</p>
          </div>
          <p className="text-secColor">
            Please reset your password before continue
          </p>
          <div className="mt-10">
            <CustomInput
              margin="true"
              labelText="Password"
              name="username"
              placeholder="Enter password"
              type="password"
            />

            <CustomInput
              margin="true"
              labelText="Confirm Password"
              name="password"
              placeholder="Enter confirm password"
              type="password"
            />

            <div className="">
              <CustomButton type="submit" btnType="primary" size="large">
                Continue
              </CustomButton>
            </div>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
