import React, { useCallback, useState } from "react";
import { Add } from "@mui/icons-material";
import SubHeader from "../../../Components/Molecules/Subheader";
import CustomTable from "../../../Components/Molecules/CustomTable";
import FilterSortby from "../../../Components/Molecules/FilterSortBy";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import AddCustomRoleForm from "./addCustomRoles";

const CustomRolesPage = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const onClickFilter = () => {
    handleOpen();
  };
  const navigate = useNavigate();
  const data = [
    {
      siNo: 1,
      questionGroup: "Group 1",
      noOfQuestions: 5,
      notifyEndDate: "22.07.2024",

      answeredStatus: "Answered",

      status: "Active",
    },
    {
      siNo: 2,
      questionGroup: "Group 2",
      noOfQuestions: 10,
      notifyEndDate: "22.07.2024",
      answeredStatus: "Unanswered",

      status: "Inactive",
    },
  ];
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];

  return (
    <>
      <CustomDialog
        width="600px"
        minWidth="650px"
        Title={"Add Custom Role"}
        open={open}
        handleClose={handleClose}
      >
        <AddCustomRoleForm />
      </CustomDialog>
      <div className="">
        <SubHeader
          headings={[
            {
              name: "Settings",
            },
            {
              name: "Custom Roles",
            },
          ]}
          actions={[
            {
              type: "button",
              name: "New Roles",

              onclick: () => {
                handleOpen();
              },
              icon: (
                <Add
                  sx={{
                    fontSize: "18px",
                    marginRight: "5px",
                    color: "#7F7F7F",
                  }}
                />
              ),
            },
          ]}
        />
        <div>
          <FilterSortby
            exportBtn="false"
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            onClickFilter={onClickFilter}
          />
          <div className="overflow-auto">
            <CustomTable
              tableTitle={[
                "#",
                "role name",
                "DESCRIPTION",
                "created by",
                "created at",
                "STATUS",
                "ACTIONS",
              ]}
              navigateEdit={() => navigate("/settings/editroles")}
              tableData={data}
              viewButton={true}
              editButton={true}
              deleteButton={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomRolesPage;
