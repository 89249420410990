import React, { useEffect, useMemo, useState } from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { dateFormat } from "../../../Utils/dateFormat";
let start;
export default function ShiftCalendar({
  initialView = "week",
  teamData,
  shiftData,
  weekOffData,
  events,
  onCellClick = (e, date, user) => {},
  onEventClick = (e, date, data, eves) => {},
  onMoreEventClick = (e, date, data) => {},
  onDateChange = (data) => {},
  onViewChange = (view) => {},
  onTeamFilter = (team_id) => {},
  onShiftFilter = (shift_id) => {},
}) {
  const [view, setView] = useState(initialView);
  const [isDragging, setIsDragging] = useState(false);
  const [drag, setDrag] = useState([]);
  const [dIndex, setDIndex] = useState(null);
  const [teamId, setTeamId] = useState("");
  const [shiftId, setShiftId] = useState("");
  const [date, setDate] = useState(
    new Date(new Date().setHours(0, 0, 0o0, 0o0))
  );
  const totalCalendarDays = 42;
  const totalCalendarWeeks = 6;
  const getDateInfo = (datee) => {
    const perDay = 24 * 60 * 60 * 1000;
    const date = new Date(datee);
    const weekStart = date.getTime() - date.getDay() * perDay;
    const weekEnd = weekStart + 6 * perDay;
    const monthEnd =
      new Date(
        new Date(new Date(date).setMonth(date.getMonth() + 1)).setDate(1)
      ).getTime() - perDay;
    const monthStart = new Date(new Date(new Date(date).setDate(1)));
    return {
      date,
      weekStart: new Date(weekStart),
      weekEnd: new Date(weekEnd),
      monthStart,
      monthEnd: new Date(monthEnd),
    };
  };

  const getMonthlyCalendar = (dateee) => {
    const dateInfo = getDateInfo(dateee);
    const monthWeekStart = getDateInfo(dateInfo.monthStart);
    const calendarStart = monthWeekStart.weekStart;

    const monthWeekEnd = getDateInfo(dateInfo.monthEnd);
    const calendarEnd = monthWeekEnd.weekEnd;
    return {
      date: dateInfo.date,
      calendarStart,
      calendarEnd,
      weekStart: dateInfo.weekStart,
      weekEnd: dateInfo.weekEnd,
      monthStart: dateInfo.monthStart,
      monthEnd: dateInfo.monthEnd,
    };
  };

  const generateCalendar = (startDate, endDate) => {
    const dateArray = [];
    let date = startDate;
    let nextdate = startDate.getTime();
    while (nextdate <= endDate.getTime()) {
      date = nextdate;
      dateArray.push(new Date(nextdate));
      //   .toISOString().split("T")[0])
      nextdate = nextdate + 24 * 60 * 60 * 1000;
    }
    return dateArray;
  };

  const data = useMemo(() => getMonthlyCalendar(date), [date]);

  useEffect(() => {
    onDateChange(data, dIndex || 0);
  }, [data]);

  const monthArray = useMemo(
    () => generateCalendar(data.calendarStart, data.calendarEnd),
    [data.calendarStart, data.calendarEnd]
  );
  const weekArray = useMemo(
    () => generateCalendar(data.weekStart, data.weekEnd),
    [data.weekStart, data.weekEnd]
  );

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursdate",
    "Friday",
    "Saturday",
  ];

  var months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  var smallMonths = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];

  const changeWeekOrmonth = (direction) => {
    const perDay = 24 * 60 * 60 * 1000;
    const no_of_days = 7;
    if (direction === "left") {
      if (view === "week") {
        setDate(new Date(new Date(date).getTime() - no_of_days * perDay));
      } else {
        setDate(new Date(new Date(data.monthStart).getTime() - perDay));
      }
      return;
    }
    if (view === "week") {
      setDate(new Date(new Date(date).getTime() + no_of_days * perDay));
    } else {
      setDate(new Date(new Date(data.monthEnd).getTime() + perDay));
    }
  };
  const handleDragLeave = (dataIndex, date) => {
    if (date === drag[drag.length - 1]) {
      return;
    }
    const exist = drag.indexOf(date);
    if (exist !== -1) {
      drag.splice(exist, drag.length - exist);
    }
    // if (view === "week") {
    //   setDrag((prev) => [...prev, date]);
    // } else {
    const exArray = [...drag, date].sort();
    const dateArray = [];
    let curDate = exArray[0];
    let nextdate = new Date(exArray[0]).getTime();
    while (nextdate <= new Date(exArray.at(-1)).getTime()) {
      curDate = nextdate;
      dateArray.push(dateFormat(new Date(nextdate)).split(" ")[0]);
      nextdate = nextdate + 24 * 60 * 60 * 1000;
    }
    setDrag(dateArray);
    // }
    setDIndex(dataIndex);
  };

  const handleDragEnd = (e, user) => {
    onCellClick(
      e,
      {
        from_date: drag.sort().at(0),
        to_date: drag.sort().at(-1),
      },
      user
    );
    setDrag([]);
    // setDIndex(null);
    setIsDragging(false);
  };

  const getHeaders = () => {
    let startArray = weekArray?.[0].toString().slice(0, 16).split(" ");
    let endArray = weekArray?.at(-1).toString().slice(0, 16).split(" ");
    startArray.shift();
    endArray.shift();
    if (view === "week") {
      const startString = `${startArray[1]} ${startArray[0]} ${startArray[2]}`;
      const endString = `${endArray[1]} ${endArray[0]} ${endArray[2]}`;
      return `${startString} - ${endString}`;
      // startArray = monthArray?.[0].toString().slice(0, 16).split(" ");
      // endArray = monthArray?.at(-1).toString().slice(0, 16).split(" ");
    }
    return `${months[date.getMonth()]} ${date.getFullYear()}`;
  };

  const options = [
    { value: "week", label: "week view" },
    { value: "month", label: "month view" },
  ];

  return (
    <div className="overflow-hidden">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <select
            onChange={(e) => {
              setTeamId(e.target.value);
              onTeamFilter(e.target.value);
            }}
            name="view"
            className="h-9 border border-gray-300 rounded text-sm font-medium pl-2 pr-8 bg-white outline-none"
            value={teamId}
          >
            <option value="">Team name</option>
            {teamData?.map((each, index) => (
              <option key={index} value={each?._id}>
                {each.name}
              </option>
            ))}
          </select>
          <select
            data-testid="sort-by-field"
            onChange={(e) => {
              setShiftId(e.target.value);
              onShiftFilter(e.target.value);
            }}
            name="shift_id"
            className="h-9 border border-gray-300 rounded text-sm font-medium pl-2 pr-8 bg-white outline-none"
            value={shiftId}
          >
            <option value="">Filter by shift</option>
            {shiftData?.map((each, index) => (
              <option key={index} value={each?._id}>
                {each.shift_name}
              </option>
            ))}
          </select>{" "}
          {view === "month" && (
            <select
              data-testid="sort-by-field"
              onChange={(e) => {
                setDIndex(e.target.value);
              }}
              name="view"
              className="h-9 border border-gray-300 rounded text-sm font-medium pl-2 pr-8 bg-white outline-none"
              value={dIndex}
            >
              {events?.map((each, index) => (
                <option key={index} value={index}>
                  {each.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="font-medium">{getHeaders()}</div>
        <div className="flex items-center gap-3">
          <select
            data-testid="sort-by-field"
            onChange={(e) => {
              setView(e.target.value);
              onViewChange(e.target.value);
            }}
            name="view"
            className="h-9 border border-gray-300 rounded text-sm font-medium pl-2 pr-8 bg-white outline-none"
            value={view}
          >
            {["week", "month"]?.map((each, index) => (
              <option key={index} value={each}>
                {each} view
              </option>
            ))}
          </select>
          <div className="flex">
            <button
              onClick={() => {
                changeWeekOrmonth("left");
              }}
              className="h-9 w-6 flex items-center justify-center text-sm bg-white border rounded"
            >
              <KeyboardArrowLeft sx={{ fontSize: "14px" }} />
            </button>
            <button
              onClick={() => {
                changeWeekOrmonth("right");
              }}
              className="h-9 w-6 flex items-center justify-center text-sm bg-white border rounded"
            >
              <KeyboardArrowRight sx={{ fontSize: "14px" }} />
            </button>
          </div>
        </div>
      </div>
      <div
        className={`overflow-y-auto mt-7 ${
          view === "week" ? "max-h-[calc(100vh-232px)]" : ""
        }`}
      >
        <table className="w-full border-collapse table-fixed">
          <thead>
            <tr
              className={`sticky top-[-0.5px] bg-white ${
                view === "week" ? "sticky" : ""
              }`}
            >
              {view === "week" && (
                <th className="text-left px-2 py-3  border font-medium">
                  User
                </th>
              )}
              {days?.map((day, i) => (
                <th
                  key={day}
                  className={`text-left px-2 py-3 border border-gray-300 font-medium`}
                >
                  {view === "week" && (
                    <p className="text-gray-500 text-lg">
                      {weekArray[i].getDate() < 10
                        ? `0${weekArray[i].getDate()}`
                        : weekArray[i].getDate()}{" "}
                      {smallMonths[weekArray[i].getMonth()]}
                    </p>
                  )}
                  <p
                    className={`text-sm font-medium ${
                      view === "month" ? "text-lg" : ""
                    }`}
                  >
                    {day}
                  </p>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {view === "week" &&
              events?.map((el, dataIndex) => (
                <tr key={el.id}>
                  <td className="border border-gray-300 px-2 py-3">
                    <div className="flex flex-col items-start h-24">
                      <p className="truncate">{el.name}</p>
                      <p className="text-sm text-teal-500 mt-1">
                        {`${
                          el.events?.filter((e) => {
                            if (view === "week") {
                              return (
                                new Date(e.date) > data.weekStart &&
                                new Date(e.date) <
                                  new Date(data.weekEnd.setHours(23, 59))
                              );
                            }
                            return (
                              new Date(e.date) > data.calendarStart &&
                              new Date(e.date) <
                                new Date(data.calendarEnd.setHours(23, 59))
                            );
                          }).length
                        } ${el.events.length > 1 ? "shifts" : "shift"}`}
                      </p>
                    </div>
                  </td>
                  {weekArray?.map((date, dayIndex) => {
                    const eves = el.events?.filter(
                      (el) =>
                        dateFormat(el.date).split(" ")[0] ==
                        dateFormat(date).split(" ")[0]
                    );
                    return (
                      <td
                        key={date.getDate()}
                        className={`border border-gray-300 p-2 min-w-[120px] ${
                          drag.includes(dateFormat(date).split(" ")[0]) &&
                          dIndex === dataIndex
                            ? "bg-gray-200"
                            : ""
                        } ${
                          !weekOffData.has(days[date.getDay()])
                            ? "bg-green-100"
                            : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          onCellClick(
                            e,
                            {
                              from_date: dateFormat(date).split(" ")[0],
                              to_date: dateFormat(date).split(" ")[0],
                            },
                            el?._id
                          );
                        }}
                        onDragEnter={() =>
                          handleDragLeave(
                            dataIndex,
                            dateFormat(date).split(" ")[0]
                          )
                        }
                        onPointerDown={() => {
                          setIsDragging(true);
                          handleDragLeave(
                            dataIndex,
                            dateFormat(date).split(" ")[0]
                          );
                          setDrag([dateFormat(date).split(" ")[0]]);
                        }}
                        onPointerEnter={() => {
                          if (!isDragging) return;
                          handleDragLeave(
                            dataIndex,
                            dateFormat(date).split(" ")[0]
                          );
                        }}
                        onPointerUp={(e) => {
                          if (!isDragging) return;
                          setIsDragging(false);
                          handleDragEnd(e, el?._id);
                        }}
                        onDragEnd={(e) => {
                          handleDragEnd(e, el?._id);
                        }}
                        draggable={false}
                      >
                        <div className="flex flex-col items-center justify-center h-full">
                          {eves.length > 0 && (
                            <div
                              className={`w-full bg-teal-200 text-center rounded-md h-15 flex flex-col items-center justify-center ${
                                eves[0]?.class || ""
                              }`}
                              onClick={(e) => {
                                e.stopPropagation();
                                onEventClick(
                                  e,
                                  dateFormat(date).split(" ")[0],
                                  eves[0],
                                  eves
                                );
                              }}
                              onPointerDown={(e) => e.stopPropagation()}
                              onPointerUp={(e) => {
                                if (!isDragging) return;
                                e.stopPropagation();
                                handleDragEnd(e, el?._id);
                              }}
                            >
                              <p className="text-sm font-medium">
                                {eves[0].start_time || "00:00"} -{" "}
                                {eves[0].end_time || "23:59"}
                              </p>
                              <p className="truncate text-sm">
                                {eves[0].title}
                              </p>
                            </div>
                          )}
                          {eves.length > 1 && (
                            <p
                              className="text-xs cursor-pointer mt-1 text-teal-500"
                              onClick={(e) => {
                                e.stopPropagation();
                                onMoreEventClick(
                                  e,
                                  dateFormat(date).split(" ")[0],
                                  eves
                                );
                              }}
                              onPointerDown={(e) => e.stopPropagation()}
                              onPointerUp={(e) => {
                                if (!isDragging) return;
                                e.stopPropagation();
                                handleDragEnd(e, el?._id);
                              }}
                            >{`+${eves.length - 1}`}</p>
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
