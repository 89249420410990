import React, { useCallback, useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import removear from "../../../Utils/RemoveArrayEle";
import SubHeader from "../../../Components/Molecules/Subheader";
import PasswordSettingsForm from "./PasswordSettings";
import OtherSettingsForm from "./OtherSettings";
import GeneralSetting from "./GeneralSettings";
import ApiKeysPage from "./ApiKeys";

function AccountSetting(props) {
  const [page, setPage] = useState("Job Category");
  const list = [
    "General Settings",
    "Password Settings",
    "Api Keys",
    "Other Settings",
  ];
  const [tablist, setTablist] = useState([]);

  useEffect(() => {
    let res = removear(list, null);
    setPage(res?.[0]);
    setTablist(res);
  }, []);

  const renderPage = () => {
    switch (page) {
      case "General Settings":
        return <GeneralSetting />;
      case "Password Settings":
        return <PasswordSettingsForm />;
      case "Api Keys":
        return <ApiKeysPage />;
      case "Other Settings":
        return <OtherSettingsForm />;

      default:
        return null;
    }
  };

  return (
    <>
      <SubHeader
        headings={[
          {
            name: "settings",
            url: "/setting",
          },
          {
            name: " Account Settings",
          },
        ]}
      />
      <div className="flex gap-8 mt-5">
        <section className="flex-[2]">
          <div className="bg-white border border-gray-300 rounded-md">
            <div className="p-2">
              <div className="flex gap-3 items-center">
                <AccountCircleIcon
                  sx={{ fontSize: "60px" }}
                  className="bg-white text-gray-300  w-14 h-14 rounded-full overflow-hidden"
                />
                <div className="">
                  <p className="tableColor font-semibold text-[16px]">
                    Luther King
                  </p>
                  <p className="tableColor text-[11px]">
                    luther.king@gwayerp.com
                  </p>
                </div>
              </div>
              {/* <ComponentTitle title="Configurations" mb="0" /> */}
            </div>
            {tablist.map((each) => (
              <div
                key={each?.value || each}
                id={each?.value || each}
                className={`flex justify-between p-3 cursor-pointer border-t ${
                  (each?.value || each) === page ? "bg-gray-200" : ""
                }`}
                onClick={() => setPage(each?.value || each)}
                onKeyDown={() => {}}
              >
                <h3 className="m-0 text-xs font-medium max-w-xs truncate">
                  {each?.name || each}
                </h3>
              </div>
            ))}
          </div>
        </section>
        <section className="flex-[8]">{renderPage()}</section>
      </div>
    </>
  );
}

export default AccountSetting;
