import React from "react";
import CustomInput from "../../../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../../../Components/Atoms/CustomCancelSubmit";

const MasterShiftForm = () => {
  return (
    <>
      <div className="">
        <div className=" ">
          <CustomInput
            margin="true"
            name="User"
            labelText="Shift Name"
            placeholder="Eg: Morning Shift"
            type="text"
          />
        </div>
        <div className="columnTwo">
          <CustomInput
            margin="true"
            name="User"
            labelText="From Time"
            placeholder="Eg: 09:00"
            type="text"
          />
          <CustomInput
            margin="true"
            name="User"
            labelText="To Time"
            placeholder="Eg: 09:00"
            type="text"
          />
          <CustomInput
            margin="true"
            name="User"
            labelText="Frequency"
            placeholder="Choose Frequency*"
            type="text"
          />{" "}
          <CustomInput
            margin="true"
            name="User"
            labelText="Repeat Every"
            placeholder="day(s)"
            customCls="text-right"
            type="text"
          />
        </div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            className="border border-[#A9A9A9] outline-none w-[18px] h-[18px] rounded-[15px]"
          />
          <p>Shift Exclusion</p>
        </div>
        <div className="mt-3">
          <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
        </div>
      </div>
    </>
  );
};

export default MasterShiftForm;
