import React, { useEffect, useState } from "react";
import SettingsTabsList from "../../../Components/Molecules/SettingsTabsList";
import SubHeader from "../../../Components/Molecules/Subheader";
import removear from "../../../Utils/RemoveArrayEle";
import ProductCategory from "./ProductCategory";
import ProductLocationForm from "./ProductLocation";
import DiscountFeePage from "./DiscountFee";

function ProductSettings() {
  const [page, setPage] = useState("Job Category");
  const list = ["Product Category", "Product Locations", "Discount & Fees"];
  const [tablist, setTablist] = useState([]);
  useEffect(() => {
    let res = removear(list, null);
    setPage(res?.[0]);
    setTablist(res);
  }, []);

  const renderPage = () => {
    switch (page) {
      case "Product Category":
        return <ProductCategory />;
      case "Product Locations":
        return <ProductLocationForm />;
      case "Discount & Fees":
        return <DiscountFeePage />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="">
        <div className="">
          <SubHeader
            headings={[
              {
                name: "Settings",
                url: "/setting",
              },
              {
                name: "Product Settings",
              },
            ]}
            actions={[]}
          />
        </div>
        <div className="flex gap-8 mt-5">
          <section className="flex-[2]">
            <SettingsTabsList
              list={tablist}
              current={page}
              title={"configurations"}
              page={setPage}
            />
          </section>

          <section className=" flex flex-col flex-[8]">{renderPage()}</section>
        </div>
      </div>
    </>
  );
}
export default ProductSettings;
