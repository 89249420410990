import React, { useRef, useState } from "react";
import SubHeader from "../../Components/Molecules/Subheader";
import Whitespace from "../../Components/Atoms/WhiteSpace";
import CustomInput from "../../Components/Atoms/CustomInput";
import ComponentTitle from "../../Components/Atoms/ComponentTitle";

import { imageFileFormat } from "../../constants";
import userdummy from "../../Assets/user-dummy.jpg";

import CustomSelect from "../../Components/Atoms/CustomSelect";
import PhoneNumber from "../../Components/Atoms/PhoneNumber";

const EmployeeForm = () => {
  const [checkphone, setCheckphone] = useState(0);

  const [image, setImage] = useState(userdummy);
  const hiddenFileInput = useRef(null);
  const handleChange = (e) => {
    const filename = e?.currentTarget?.files[0]?.name?.split(".");
    if (filename && filename[0]) {
      const format = filename[filename?.length - 1];
      if (imageFileFormat.includes(format)) {
        // formik.setFieldValue("profile", e.currentTarget.files[0]);
        setImage(URL.createObjectURL(e.currentTarget.files[0]));
      }
    }
  };
  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  return (
    <>
      <div className="">
        <SubHeader
          headings={[
            {
              name: "Employees",
            },
            {
              name: "Create New Employee",
            },
          ]}
          actions={[
            {
              type: "button",
              btnType: "submit",
              name: "Save",
            },
          ]}
        />
        <Whitespace mt="23px" className={"px-14 py-7"}>
          <ComponentTitle title={"Employee Details"} />
          <div className="flex gap-14 items-center mt-4">
            <div className="flex justify-start items-center">
              <div className="flex flex-col gap-2.5 justify-start items-center relative">
                <img
                  className="w-[165px] h-[145px] border border-solid border-gray-200 rounded-full flex items-center justify-center"
                  src={image}
                />
                <input
                  type="file"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  className="hidden"
                  accept="image/jpg,image/png,image/jpeg"
                />
                <div
                  className="px-6 py-2  rounded bg-primColor text-[#ffffff] flex justify-center items-center text-[13px] font-light leading-4.5 tracking-wide cursor-pointer  border border-[#A9A9A9] border-1"
                  onClick={handleClick}
                >
                  Upload
                </div>

                {/* {formik.values.profile && (
                  <div className="cursor-pointer" onClick={handleRemove}>
                    <DeleteIcon className="tax-modal-cancel-icon" />
                  </div>
                )} */}
              </div>
            </div>
            <div className="columnThree w-full">
              <CustomInput
                margin="true"
                labelText="First Name"
                name="username"
                placeholder="Enter first name"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Last Name"
                name="username"
                placeholder="Enter last name"
                type="text"
              />
              <CustomInput
                margin="true"
                labelText="Email"
                name="username"
                placeholder="Enter email"
                type="text"
              />

              <PhoneNumber
                // value={formik.values.phone}
                // onChange={formik.setFieldValue}
                labelText="Phone Number"
                name="phone"
                // scrollIntoView={scrollIntoView}
                // onBlur={formik.handleBlur}

                validcheck={setCheckphone}
              />

              <CustomSelect
                setValue={"_id"}
                labelText="Role"
                placeholder="Select Your Role"
              />
              <CustomInput
                margin="true"
                labelText="Designation"
                name="username"
                placeholder="Enter designation"
                type="text"
              />
            </div>
          </div>
          <div className="columnThree mt-9">
            <CustomSelect
              setValue={"_id"}
              labelText="Shift"
              placeholder="Select Your Shift"
            />
            <CustomInput
              margin="true"
              labelText="Labour Charge"
              name="username"
              placeholder="Eg: 100"
              type="text"
            />{" "}
            <CustomInput
              margin="true"
              labelText="Joining Date"
              name="username"
              placeholder="Pick Date"
              type="text"
            />{" "}
            <CustomInput
              margin="true"
              labelText="Address"
              name="username"
              placeholder="Enter Address"
              type="text"
            />
            <CustomInput
              margin="true"
              labelText="Country"
              name="username"
              placeholder="Select Your Country"
              type="text"
            />{" "}
            <CustomSelect
              setValue={"_id"}
              labelText="State"
              placeholder="Select Your State"
            />
            <CustomInput
              margin="true"
              labelText="City"
              name="username"
              placeholder="Enter City"
              type="text"
            />{" "}
            <CustomInput
              margin="true"
              labelText="Zipcode"
              name="username"
              placeholder="Enter Zipcode"
              type="text"
            />{" "}
            <CustomSelect
              setValue={"_id"}
              labelText="Time Zone"
              placeholder="Please choose one"
            />
            <CustomSelect
              setValue={"_id"}
              labelText="Regularization"
              placeholder="Please Choose One"
            />
            <CustomInput
              margin="true"
              labelText="Interview Date"
              name="username"
              placeholder="Enter your username"
              type="text"
            />{" "}
            <CustomInput
              margin="true"
              labelText="Total Experience"
              name="username"
              placeholder="Enter Total Experience"
              type="text"
            />
          </div>
        </Whitespace>
      </div>
    </>
  );
};

export default EmployeeForm;
