import React from "react";

function ToggleButton({ name, value, onChange, disabled }) {
  return (
    <label className="relative inline-block w-[33px] h-[19px]">
      <input
        type="checkbox"
        value={value ? value : false}
        checked={value ? value : false}
        name={name}
        onChange={onChange}
        disabled={disabled}
        className="opacity-0 w-0 h-0"
      />
      <span
        className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-300 rounded-[29px] transition duration-400
        ${value ? "bg-primColor" : "bg-gray-300"}`}
      >
        <span
          className={`absolute content-[''] h-[15px] w-[15px] left-[2px] bottom-[2px] bg-white rounded-full transition duration-400
          ${value ? "transform translate-x-[12px]" : ""}`}
        />
      </span>
    </label>
  );
}

export default ToggleButton;
