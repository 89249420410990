import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import DoneIcon from "@mui/icons-material/Done";
import CustomButton from "../../Atoms/CustomButton";
import MenuDropdown from "../../Atoms/MenuDropDown";

const FilterSortby = (props) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const {
    exportBtn = false,
    applied,
    setApplied,
    totalPage,
    currentPage,
    roleList,
    getPageNumber,
    getQuickFilter,
    getSortByValue,
    SortByMenuDropdown,
    sortList,
    onClickFilter,
    exportMethod,
    showFilter = false,
    newEntryBtn = false,
    defalterBtn = false,
    sortQuick = true,
    showQuickFilter = false,
    sortBymenucomponent,
    Children = false,
    sortValue = "createdAt-DSC",
    handleClickDefaulters,
  } = props;

  const formik = useFormik({
    initialValues: {
      sortBy: sortValue,
      quickFilter: "",
      page: 1,
    },
  });

  useEffect(() => {
    if (currentPage) {
      formik.setFieldValue("page", currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    getSortByValue(formik.values.sortBy);
    getPageNumber(1);
  }, [formik.values.sortBy]);

  useEffect(() => {
    formik.setFieldValue("sortBy", sortValue);
  }, [sortValue]);

  useEffect(() => {
    getQuickFilter(formik.values.quickFilter);
    getPageNumber(1);
  }, [formik.values.quickFilter]);

  useEffect(() => {
    if (formik.values.page > 0 && formik.values.page <= totalPage) {
      getPageNumber(formik.values.page);
    }
  }, [formik.values.page]);

  let filterSort = sortList.filter((val) => val.value === formik.values.sortBy);

  const menuItems = [
    { type: "link", label: "Created At", href: "#account" },

    {
      type: "button",
      label: "Updated At",
    },
  ];

  return (
    <div className="bg-transparent flex justify-between py-4">
      <div className="flex items-center">
        {sortQuick && (
          <>
            <p className="text-xs font-semibold text-filterColor mr-2 whitespace-nowrap">
              {props?.name || "Sort By"}
            </p>
            <div className="relative  border-[#d2d2d2] rounded-md bg-[#ffffff] border-[0.1px]">
              <MenuDropdown
                menuItems={menuItems}
                padding="px-2"
                right="left-0"
                minWidth="min-w-52"
              >
                <div className="flex gap-6 py-0">
                  <p className="text-[12px] text-filterColor">
                    {filterSort[0]?.name}
                  </p>
                  <div>
                    <KeyboardArrowDownIcon
                      sx={{ fontSize: "20px", color: "#696969" }}
                    />
                  </div>
                </div>
              </MenuDropdown>
            </div>
            {showQuickFilter && (
              <>
                <p className="text-xs font-semibold text-[#002e25] mr-2 whitespace-nowrap">
                  Quick Filter
                </p>
                <select
                  data-testid="filter-by-quick"
                  value={
                    formik.values.quickFilter
                      ? formik.values.quickFilter
                      : roleList?.filter((each) => each.disabled)[0]?.name
                  }
                  onChange={formik.handleChange}
                  name="quickFilter"
                  className="h-6 border border-[#909090] text-xs font-medium rounded-md px-2 mr-6 bg-white appearance-none"
                >
                  {roleList?.map((each) => (
                    <option
                      disabled={each.disabled}
                      key={each._id}
                      value={each._id}
                    >
                      {each.name}
                    </option>
                  ))}
                </select>
              </>
            )}
          </>
        )}
        {Children && (
          <select
            data-testid="filter-by-quick"
            value={
              formik.values.quickFilter
                ? formik.values.quickFilter
                : roleList?.filter((each) => each.disabled)[0]?.name
            }
            onChange={formik.handleChange}
            name="quickFilter"
            className="h-6 border border-[#909090] text-xs font-medium rounded-md px-2 bg-white appearance-none"
          >
            {roleList?.map((each) => (
              <option disabled={each.disabled} key={each._id} value={each._id}>
                {each.name}
              </option>
            ))}
          </select>
        )}
        {SortByMenuDropdown && sortBymenucomponent}
      </div>

      <div
        className="flex items-center gap-2"
        permission={props.pagePermission}
      >
        <p className="text-xs font-semibold text-filterColor">Page</p>

        <button
          size="medium"
          disabled={formik.values.page <= 1}
          className="border text-[11px] px-2.5 py-1 border-[#909090] rounded bg-white flex items-center justify-center text-filterColor"
        >
          {formik.values.page}
        </button>
        <p className="text-xs  text-filterColor">Of {totalPage || 1}</p>
        <div className="flex gap-2">
          <div className="flex items-center">
            <button
              size="medium"
              disabled={formik.values.page <= 1}
              onClick={() => {
                formik.setFieldValue("page", Number(formik.values.page) - 1);
                getPageNumber(formik.values.page - 1);
              }}
              className="border py-1  border-[#909090] rounded-l bg-white flex items-center justify-center text-gray-600"
            >
              <KeyboardArrowLeftIcon sx={{ fontSize: "17px" }} />
            </button>
            <button
              size="medium"
              disabled={formik.values.page >= totalPage}
              onClick={() => {
                formik.setFieldValue("page", Number(formik.values.page) + 1);
                getPageNumber(formik.values.page + 1);
              }}
              className="border py-1 border-[#909090] rounded-r bg-white flex items-center justify-center text-gray-600"
            >
              <KeyboardArrowRightIcon sx={{ fontSize: "17px" }} />
            </button>
          </div>
          {exportBtn && (
            <CustomButton
              onClick={exportMethod}
              className="flex items-center justify-center bg-white border border-[#909090] h-6 rounded-md text-xs font-semibold ml-2"
              size="medium"
            >
              <CloudDownloadIcon
                className="text-gray-600 mr-2"
                sx={{ fontSize: "18px" }}
              />
              {props?.exportName || "Export"}
            </CustomButton>
          )}
          {newEntryBtn && (
            <CustomButton
              size="medium"
              onClick={() => navigate("/timesheet/new-timesheet")}
              className="flex items-center justify-center bg-white border border-[#909090] h-6 rounded-md text-xs font-semibold ml-2"
            >
              <StickyNote2Icon
                className="text-gray-600 mr-2"
                sx={{ fontSize: "18px" }}
              />
              New Entry
            </CustomButton>
          )}
          {defalterBtn && (
            <CustomButton
              size="medium"
              onClick={handleClickDefaulters}
              className="flex items-center justify-center bg-white border border-[#909090] h-6 rounded-md text-xs font-semibold ml-2"
            >
              Defaulters
            </CustomButton>
          )}
          {showFilter && (
            <div
              onClick={() => {
                onClickFilter();
              }}
              className="w-7 h-7 border border-[#909090] bg-white rounded flex items-center justify-center text-gray-600 ml-2 cursor-pointer"
            >
              <FilterAltIcon sx={{ fontSize: "18px" }} />
              {applied && (
                <div className="absolute top-[-2px] right-[-2px] h-3 w-3 bg-[#009e90] rounded-full flex items-center justify-center">
                  <DoneIcon
                    sx={{
                      fontSize: "9px",
                      color: "#ffffff",
                      fontWeight: "1000",
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterSortby;

FilterSortby.propTypes = {
  exportBtn: PropTypes.string,
  totalPage: PropTypes.number,
  currentPage: PropTypes.number,
  roleList: PropTypes.array,
  getPageNumber: PropTypes.func,
  getQuickFilter: PropTypes.func,
  getSortByValue: PropTypes.func,
  sortList: PropTypes.array,
  onClickFilter: PropTypes.func,
  exportMethod: PropTypes.func,
};
