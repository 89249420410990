import React, { useRef, useState } from "react";
import SubHeader from "../../../Components/Molecules/Subheader";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import CustomInput from "../../../Components/Atoms/CustomInput";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import CustomSelect from "../../../Components/Atoms/CustomSelect";
import FilterSortby from "../../../Components/Molecules/FilterSortBy";
import CustomTable from "../../../Components/Molecules/CustomTable";

const TeamForm = () => {
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];
  return (
    <>
      <div className="">
        <SubHeader
          headings={[
            {
              name: "Employees",
            },
            {
              name: "Teams",
            },
            {
              name: "New Team",
            },
          ]}
          actions={[
            {
              type: "button",
              btnType: "submit",
              name: "Save",
            },
          ]}
        />
        <Whitespace mt="23px" className={"px-6 py-1"}>
          {/* <ComponentTitle title={"Employee Details"} /> */}

          <div className="columnFour mt-5">
            <CustomInput
              setValue={"_id"}
              labelText="Team Name"
              placeholder="Enter Name"
            />
            <CustomSelect
              margin="true"
              labelText="Team Lead"
              name="username"
              placeholder="Choose Lead"
              type="text"
            />
            <CustomInput
              margin="true"
              labelText="Team Color"
              name="username"
              placeholder="Pick Date"
              type="color"
              customCls="p-0"
            />
          </div>
        </Whitespace>

        <Whitespace mt="23px" className={"px-6 py-8"}>
          <ComponentTitle title={"Employee Lists"} mb="1" />

          <FilterSortby
            exportBtn="false"
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            onClickFilter={() => {}}
          />
          <div className="overflow-auto">
            <CustomTable
              tableTitle={[
                "S.no",
                "Employee code",
                "Name",
                "Role",
                "Designation",
                "STATUS",
                "email",
                "select",
              ]}
            />
          </div>
        </Whitespace>
      </div>
    </>
  );
};

export default TeamForm;
