import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import TextArea from "../../../Components/Atoms/TextArea";
import CustomInput from "../../../Components/Atoms/CustomInput";
import ToggleButton from "../../../Components/Atoms/ToggleButton";
import SubHeader from "../../../Components/Molecules/Subheader";

function EditCustomRole(props) {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col">
        <SubHeader
          headings={[
            {
              name: "Settings",
            },
            {
              name: "Edit Roles",
            },
          ]}
          actions={[
            {
              type: "button",
              btnType: "submit",
              name: "Save",
            },
          ]}
        />

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
          <div className="bg-white rounded border p-4">
            <ComponentTitle title="ROLE DETAILS" />
            <div className="mt-4">
              <CustomInput
                name="name"
                labelText="Role Name"
                placeholder="Enter Role Name"
                type="text"
              />
            </div>

            <div className="mt-4">
              <TextArea
                labelText="Role Description"
                name="description"
                required="true"
                placeholder="Enter role description"
                type="text"
              />
            </div>
          </div>

          {/* Available Modules Section */}
          <div className="bg-white rounded border max-h-[80vh] overflow-y-auto">
            <div className={`px-4 py-5 border-b font-[800]`}>
              <h4 className={`text-xs uppercase font-bold`}>
                AVAILABLE MODULES
              </h4>
            </div>
            <div
              className={`flex justify-between items-center hover:bg-gray-200  px-4 py-3 border-b `}
            >
              <h3 className="text-sm font-medium  ">Employees</h3>
              <ToggleButton />
            </div>
            <div
              className={`flex justify-between items-center hover:bg-gray-200  px-4 py-3 border-b `}
            >
              <h3 className="text-sm font-medium ">Jobs</h3>
              <ToggleButton />
            </div>{" "}
            <div
              className={`flex justify-between items-center hover:bg-gray-200  px-4 py-3 border-b `}
            >
              <h3 className="text-sm font-medium">Customer</h3>
              <ToggleButton />
            </div>
          </div>

          <div className="bg-white rounded border max-h-[80vh] overflow-y-auto">
            <div className={`px-4 py-5 border-b font-[800]`}>
              <h4 className={`text-xs uppercase font-bold`}>PERMISSIONS</h4>
            </div>
            <div
              className={`flex justify-between items-center hover:bg-gray-200  px-4 py-3 border-b `}
            >
              <h3 className="text-sm font-medium">Add Employees</h3>
              <ToggleButton />
            </div>
            <div
              className={`flex justify-between items-center hover:bg-gray-200  px-4 py-3 border-b `}
            >
              <h3 className="text-sm font-medium">Edit Employee</h3>
              <ToggleButton />
            </div>
            <div
              className={`flex justify-between items-center hover:bg-gray-200  px-4 py-3 border-b `}
            >
              <h3 className="text-sm font-medium">Delete Employee</h3>
              <ToggleButton />
            </div>
            <div
              className={`flex justify-between items-center hover:bg-gray-200  px-4 py-3 border-b `}
            >
              <h3 className="text-sm font-medium">View Employee</h3>
              <ToggleButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCustomRole;
