import React, { useCallback, useState } from "react";

import CustomTable from "../../../Components/Molecules/CustomTable";
import FilterSortby from "../../../Components/Molecules/FilterSortBy";
import { useNavigate } from "react-router-dom";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import CustomButton from "../../../Components/Atoms/CustomButton";

const ApiKeysPage = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const onClickFilter = () => {
    handleOpen();
  };
  const navigate = useNavigate();
  const data = [
    {
      siNo: 1,
      questionGroup: "***************************",

      status: "Active",
    },
    {
      siNo: 2,
      questionGroup: "Group 2",

      status: "Inactive",
    },
  ];
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];

  return (
    <>
      <Whitespace className="">
        <div className="flex content-between items-center">
          <ComponentTitle title="api keys" />
          <CustomButton
            // btnClass="mr-5"
            size="medium"
          >
            GenerateKeys
          </CustomButton>
        </div>

        <div>
          <FilterSortby
            exportBtn="false"
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            onClickFilter={onClickFilter}
          />
          <div className="overflow-auto">
            <CustomTable
              tableTitle={["S.NO", "KEY", "STATUS", "ACTIONS"]}
              navigateView={() => navigate("/employee/view")}
              tableData={data}
              deleteButton={true}
            />
          </div>
        </div>
      </Whitespace>
    </>
  );
};

export default ApiKeysPage;
