import React from "react";
import CustomInput from "../../../../Components/Atoms/CustomInput";
import CustomSelect from "../../../../Components/Atoms/CustomSelect";
import TextArea from "../../../../Components/Atoms/TextArea";
import CustomCancelSubmit from "../../../../Components/Atoms/CustomCancelSubmit";

const LeadStatusForm = () => {
  return (
    <>
      <div className="">
        <div className="columnTwo">
          <CustomInput
            margin="true"
            name="User"
            labelText="Status Name"
            placeholder="Lead Status Name"
            type="text"
          />
          <CustomSelect
            margin="true"
            name="lastname"
            labelText="Status Type*"
            placeholder={"Choose The Status Type"}
            type="text"
          />
        </div>
        <TextArea
          name="lastname"
          labelText="Status Description"
          type="text"
          placeholder={"Enter Description"}
        />
        <div className="columnTwo">
          <CustomInput
            margin="true"
            name="User"
            labelText="Status Color"
            placeholder="Eg: Sprint Document"
            type="text"
          />
          <CustomSelect
            margin="true"
            name="lastname"
            labelText="Require Customer Signature?"
            placeholder={"Choose One"}
            type="text"
          />
        </div>{" "}
        <div className="columnTwo">
          <CustomSelect
            margin="true"
            name="User"
            labelText="Require Facial Authentication?"
            placeholder={"Choose One"}
            type="text"
          />
          <CustomSelect
            margin="true"
            name="lastname"
            labelText="Require Geo-Fencing?"
            placeholder={"Choose One"}
            type="text"
          />
        </div>{" "}
        <div className="columnTwo">
          <CustomSelect
            margin="true"
            name="User"
            labelText="Capture Geo-Coordinates?"
            placeholder={"Choose One"}
            type="text"
          />
          <CustomSelect
            margin="true"
            name="lastname"
            placeholder={"Choose One"}
            labelText="Enabled For Field Executive?"
            type="text"
          />
        </div>{" "}
        <div className="columnTwo">
          <CustomSelect
            margin="true"
            name="User"
            labelText="Enabled For Manager?"
            placeholder={"Choose One"}
            type="text"
          />
          <CustomSelect
            margin="true"
            name="lastname"
            labelText="Dependent Status?"
            placeholder={"Choose One"}
            type="text"
          />
        </div>{" "}
        <div className="columnTwo mb-4">
          <CustomSelect
            margin="true"
            name="User"
            labelText="Allow Remarks"
            placeholder={"Choose One"}
            type="text"
          />
          <div></div>
        </div>
        <CustomCancelSubmit cancelLabel="Close" submitLabel="Add Status" />
      </div>
    </>
  );
};

export default LeadStatusForm;
