import React from "react";
import CustomInput from "../../../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../../../Components/Atoms/CustomCancelSubmit";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CustomSelect from "../../../../Components/Atoms/CustomSelect";

const TaxForm = () => {
  return (
    <>
      <div className="columnTwo">
        <CustomInput
          margin="true"
          name="User"
          labelText="Tax Name"
          placeholder="Eg: VAT"
          type="text"
        />
        <CustomSelect
          margin="true"
          name="lastname"
          labelText="Tax Rate (IN %)"
          type="text"
          placeholder={"Eg: 2%"}
        />
      </div>
      <div className="columnTwo">
        <CustomSelect
          margin="true"
          name="User"
          labelText="Is This A Regional Tax"
          placeholder="Pick an Option"
          type="text"
        />
        <div></div>
      </div>

      <div className="mb-2 font-[500] text-textColor text-[14px]">
        <label>Applicable To</label>
      </div>
      <div className="flex gap-1 items-center">
        <div className="mr-3 w-full">
          <CustomSelect
            name="applicable_to_state"
            placeholder={"Choose State / Province"}
          />
        </div>
        <CustomSelect
          placeholder="Choose / Enter city (optional)"
          showOption="name"
          labelText=""
        />
        <div className="flex flex-col items-center mb-5 gap-1">
          <AddCircleIcon
            sx={{ fontSize: "20px" }}
            className="text-[12px] font-normal leading-[15px] tracking-normal text-left text-[#009e90]"
          />
          <CancelIcon sx={{ fontSize: "20px" }} className="text-[#c20c0c]" />
        </div>
      </div>
      <div>
        <p className="text-[#6D6D6D]">
          Note: You can leave the city blank if the tax is applicable to the
          entire state
        </p>
      </div>
      <div className="flex gap-2 items-center mt-8">
        <input className="h-4 w-4" type="checkbox" />
        <label className="" htmlFor="show-password-checkbox">
          Associate Tax to Tax Group?
        </label>
      </div>

      <div className="mt-5">
        <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
      </div>
    </>
  );
};

export default TaxForm;
