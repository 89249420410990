import React from "react";
import CustomInput from "../../../Components/Atoms/CustomInput";

import CustomCancelSubmit from "../../../Components/Atoms/CustomCancelSubmit";
import CustomSelect from "../../../Components/Atoms/CustomSelect";
import TextArea from "../../../Components/Atoms/TextArea";
import ToggleButton from "../../../Components/Atoms/ToggleButton";

const AddCustomRoleForm = () => {
  return (
    <>
      <div className="w-full">
        <CustomInput
          margin="true"
          name="User"
          labelText="Name"
          placeholder={"Enter your role"}
          type="text"
        />

        <TextArea
          margin="true"
          name="lastname"
          labelText="Role Description*"
          placeholder={"Enter Description"}
        />
        <CustomSelect
          margin="true"
          name="User"
          labelText="Status"
          placeholder={"Choose Status"}
          type="text"
        />
        <div className="flex gap-2">
          <p>Show Only Related Records</p>
          <ToggleButton name="notes.is_private" value={""} />
        </div>
        <div className="mt-7">
          <CustomCancelSubmit cancelLabel="Close" submitLabel="Add Role" />
        </div>
      </div>
    </>
  );
};

export default AddCustomRoleForm;
