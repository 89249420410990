import { useRef, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InputLable from "../InputLable";

const PhoneNumber = ({
  value,
  onChange,
  labelText,
  name,
  disabled,
  placeholder,
  onBlur,
  validcheck,
  errorMsg,
  required,
  scrollIntoView = () => {},
}) => {
  const username = name;
  const errorRef = useRef(null);

  useEffect(() => {
    if (errorRef.current) {
      scrollIntoView(errorRef.current);
    }
  }, [errorMsg, scrollIntoView]);

  function findlength(country, code) {
    let count = 0;
    if (country) {
      for (let each of country) {
        if (each === ".") {
          count++;
        }
      }
    }
    return count;
  }

  return (
    <div className="flex flex-col w-full">
      {labelText ? (
        <InputLable labelText={labelText} required={required} />
      ) : null}
      <div className="w-full">
        <PhoneInput
          country={"in"}
          disabled={disabled}
          // containerStyle={{ width: "100%", backgroundColor: "none" }}
          inputStyle={{
            height: "3.1rem",
            borderColor: errorMsg ? "red" : "black",
            borderWidth: errorMsg ? "1px" : "0px",
            borderRadius: "0.375rem",
            fontWeight: "600",
            width: "100%",
            outline: "none",
            animation: errorMsg ? "bounce 1s infinite" : "none",
            border: "1px solid #A9A9A9",
            backgroundColor: "#FFFFFF",
          }}
          buttonStyle={{
            borderColor: errorMsg ? "red" : "black",
            // borderColor: errorMsg ? "red" : "yellow",
            borderWidth: "0px",
            // height: "30px",
            margin: "2px",

            // borderRadius: "0.375rem",
            backgroundColor: "white",
          }}
          value={value}
          placeholder={placeholder}
          onBlur={(phone) => onBlur(phone)}
          isValid={(value, country) => {
            let len = findlength(country?.format, country?.countryCode);
            validcheck(len);
          }}
          onChange={(phone) => {
            onChange(username, phone);
          }}
          inputProps={{
            name: username,
            required: true,
          }}
        />
      </div>
      {errorMsg && (
        <p ref={errorRef} className="text-red-500 text-xs">
          {errorMsg}
        </p>
      )}
    </div>
  );
};

export default PhoneNumber;
