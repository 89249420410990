import React from "react";

export default function Whitespace({
  mt,
  maxHg,
  mb,
  id,
  permission,
  width,
  children,
  fg = 0,
  br,
  br_t,
  br_l,
  br_r,
  br_b,
  pd,
  className,
}) {
  return (
    <div
      permission={permission}
      id={id && id}
      style={{
        marginTop: mt,
        marginBottom: mb,
        flexGrow: fg,
        width: width,
        border: br,
        borderRight: br_r,
        borderLeft: br_l,
        borderTop: br_t,
        borderBottom: br_b,
        padding: pd,
        maxHeight: maxHg,
      }}
      className={`p-4 h-auto bg-[#ffffff] border overflow-auto ${
        className ?? ""
      }`}
    >
      {children}
    </div>
  );
}
