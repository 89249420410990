import React from "react";
import AuthLayout from "../../Components/Layout/AuthLayout";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  return (
    <AuthLayout showButton={true}>
      <div className="w-full mx-auto">
        <form>
          <div className="flex items-center gap-1">
            <p className="text-customGray text-3xl font-semibold">Login</p>
            <p className="text-primColor text-3xl font-semibold">now</p>
          </div>
          <p className="text-secColor">Enter your credentials to login</p>
          <div className="mt-10">
            <CustomInput
              margin="true"
              labelText="Username"
              name="username"
              placeholder="Enter your username"
              type="text"
            />

            <CustomInput
              margin="true"
              labelText="Password"
              name="password"
              placeholder="Enter your password"
              type="password"
            />

            <div className="flex justify-between items-center mt-4 mb-3.5">
              <div className="flex gap-2 items-center">
                <input className="h-4" type="checkbox" />
                <label
                  className="login-show-pass-checkbox-label"
                  htmlFor="show-password-checkbox"
                >
                  Remember me
                </label>
              </div>
              <Link
                className="text-primColor font-semibold"
                to={"/forgotpassword"}
              >
                Forgot Password?
              </Link>
            </div>
            <div className="">
              <CustomButton
                type="submit"
                btnType="primary"
                size="large"
                onClick={() => {
                  navigate("/employee");
                }}
              >
                Login
              </CustomButton>
            </div>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Login;
