import * as React from "react";
import { useNavigate } from "react-router";
import CustomButton from "../../Atoms/CustomButton";
import SubHeaderMenu from "../SubHeaderMenu";

const SubHeader = (props) => {
  const navigate = useNavigate();
  const { headings, actions, information } = props;

  const renderInfo = (each) => {
    return (
      <div
        onClick={each?.onclick}
        className="flex items-center justify-start text-red-600 cursor-pointer mr-5 p-1 text-sm font-semibold"
      >
        {each.icon}
        <p className="m-0">{each?.name}</p>
      </div>
    );
  };

  const renderButton = (each) => {
    const { onclick = () => {} } = each;
    return (
      <CustomButton
        // btnClass="mr-5"
        size="medium"
        onClick={onclick}
        btnType={
          each.btnType === "submit"
            ? "terinary"
            : each.btnType === "hybrid"
            ? "hybrid"
            : each.btnType === "primary"
            ? "primary"
            : each.btnType === "custom_red"
            ? "btn_red"
            : "secondary"
        }
        disabled={each.disabled}
      >
        {each?.iconPosition === "right" ? (
          <>
            {each?.name}
            {each?.icon}
          </>
        ) : (
          <>
            {each?.icon} {each?.name}
          </>
        )}
      </CustomButton>
    );
  };
  return (
    <>
      <div
        className={`bg-[#FFFFFF] mt-6 p-3 flex justify-between items-center shadow-custom-orange ${props.containerCls}`}
      >
        {information?.length > 0 ? (
          <div>
            {information?.map((each) => {
              return renderInfo(each);
            })}
          </div>
        ) : (
          ""
        )}
        <h1
          style={{ fontSize: props.headerSize }}
          className="text-sm leading-4 m-2 text-primColor flex items-center cursor-pointer"
        >
          {headings?.map((each, index) => {
            return headings.length - 1 !== index ? (
              <>
                <span
                  className="text-[#7F7F7F] hover:text-primColor"
                  onClick={() =>
                    navigate(each?.url || index + 1 - headings.length)
                  }
                >
                  {each.name}
                </span>
                <span className="mx-2 text-[#7F7F7F]">/</span>
              </>
            ) : (
              each.name
            );
          })}
        </h1>
        <div className="flex gap-3">
          {actions?.map((each) => {
            const { permission, type = "button" } = each;
            let access = permission === undefined ? true : permission;

            if (access) {
              if (type === "button") {
                return renderButton(each);
              }
              if (type === "info") {
                return renderInfo(each);
              }
              // if (type === "select") {
              //   return renderButton(each);
              // }
              if (type === "menu_drop_down") {
                return <SubHeaderMenu each={each} />;
              }
            }
            return;
          })}
        </div>
      </div>
    </>
  );
};

export default SubHeader;
