import { useState } from "react";
import CustomButton from "../../Atoms/CustomButton";
import MenuDropdown from "../../Atoms/MenuDropDown";

const SubHeaderMenu = (props) => {
  const [open, setOpen] = useState(false);
  const { each } = props;
  const menuItems = [
    { type: "link", label: "Profile", href: "#account" },

    {
      type: "button",
      label: "Log out",
      onClick: () => console.log("clickedss"),
    },
  ];
  return (
    <MenuDropdown
      menuItems={menuItems}
      right=""
      minWidth="min-w-36"
    ></MenuDropdown>
  );
};

export default SubHeaderMenu;
