import React, { useRef, useState } from "react";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import CustomInput from "../../../Components/Atoms/CustomInput";
import TextArea from "../../../Components/Atoms/TextArea";
import { TableIconConstant } from "../../../constants";
import CustomButton from "../../../Components/Atoms/CustomButton";

const LeadDetails = (props) => {
  const handleFilesChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const obj = {
        file_name: e.target.files[i].name,
        url: "",
        created_by: props.userId,
        attachment: e.target.files[i],
      };
    }
  };
  const hiddenFileInput = useRef(null);
  const handleUploadBtn = () => {
    hiddenFileInput?.current?.click();
  };
  return (
    <>
      <Whitespace mt="23px" className={"px-14 py-7 w-full"}>
        <ComponentTitle title={"LEAD SETTINGS"} />
        <div className="columnThree">
          <CustomInput
            margin="true"
            labelText="Contact First Name / Company Name"
            name="username"
            placeholder="Enter contact first name"
            type="text"
          />
          <CustomInput
            margin="true"
            labelText="Contact Last Name"
            name="username"
            placeholder="Enter contact last name"
            type="text"
          />
          <CustomInput
            margin="true"
            labelText="Contact First Name / Company Name"
            name="username"
            placeholder="Enter contact first name"
            type="text"
          />
        </div>
        <div className="columnThree">
          <CustomInput
            margin="true"
            labelText="Contact First Name / Company Name"
            name="username"
            placeholder="Enter contact first name"
            type="text"
          />
          <CustomInput
            margin="true"
            labelText="Contact Last Name"
            name="username"
            placeholder="Enter contact last name"
            type="text"
          />
          <CustomInput
            margin="true"
            labelText="Contact First Name / Company Name"
            name="username"
            placeholder="Enter contact first name"
            type="text"
          />
        </div>
        <TextArea labelText={"Description"} placeholder={"Enter Description"} />
      </Whitespace>
      <Whitespace className={"mt-5"}>
        <div className="flex items-center justify-between mb-10">
          <ComponentTitle title={"Quotation attachments"}></ComponentTitle>
          <div className="flex">
            <CustomButton size="medium" buttonType={TableIconConstant.add}>
              {"Upload"}
            </CustomButton>
          </div>
        </div>
        <input
          onChange={handleFilesChange}
          ref={hiddenFileInput}
          style={{ display: "none" }}
          type="file"
          multiple
        />
        {/* {formik.values.attachments.length === 0 ? ( */}
        <div className="flex flex-col justify-center items-center py-9">
          <h1 className="text-[17px] font-semibold leading-[19px] text-black mb-[16px]">
            {"Upload Attachments"}
          </h1>
          <p className="text-[14px] font-normal leading-[15px] text-[#9d9d9d] mb-[25px]">
            {
              "Add any parts or services of any custom line item to this quotation"
            }
          </p>

          <CustomButton
            size="medium"
            onClick={handleUploadBtn}
            buttonType={TableIconConstant.add}
          >
            {"Upload"}
          </CustomButton>
        </div>
      </Whitespace>
    </>
  );
};

export default LeadDetails;
