import React from "react";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import CustomInput from "../../../Components/Atoms/CustomInput";

const BasicDetails = () => {
  return (
    <>
      <div className="flex gap-7">
        <Whitespace mt="23px" className={"px-14 py-7 w-[50%]"}>
          <ComponentTitle title={"LEAD CONTACT INFO"} />
          <div className="columnTwo w-full">
            <CustomInput
              margin="true"
              labelText="Contact First Name / Company Name"
              name="username"
              placeholder="Enter contact first name"
              type="text"
            />
            <CustomInput
              margin="true"
              labelText="Contact Last Name"
              name="username"
              placeholder="Enter contact last name"
              type="text"
            />
          </div>
          <div className="columnTwo w-full">
            <CustomInput
              labelText={"Contact Email"}
              placeholder={"Enter contact email"}
              type="text"
            />
            <CustomInput
              labelText={"Contact Phone Number"}
              placeholder={"Enter contact phone number"}
              type="text"
            />
          </div>
          <div className="">
            <CustomInput
              labelText={"Address"}
              placeholder={"Enter customer address"}
              type={"text"}
            />
          </div>
          <div className="">
            <CustomInput
              labelText={"City"}
              placeholder={"Enter city"}
              type={"text"}
            />
          </div>
          <div className="">
            <CustomInput
              labelText={"Landmark"}
              placeholder={"Enter landmark"}
              type={"text"}
            />
          </div>
          <div className="columnTwo">
            <CustomInput
              labelText={"State / Province / Region"}
              placeholder={"Enter state / province / region"}
              type={"text"}
            />
            <CustomInput
              labelText={"Postal / Zipcode"}
              placeholder={"Enter postal / zipcode"}
              type={"text"}
            />
          </div>
        </Whitespace>
        <Whitespace mt="23px" className={"px-14 py-7 w-[50%]"}>
          <ComponentTitle title={"BILLING CONTACT INFO"} />
          <div className="columnTwo w-full">
            <CustomInput
              margin="true"
              labelText="Contact First Name / Company Name"
              name="username"
              placeholder="Enter contact first name"
              type="text"
            />
            <CustomInput
              margin="true"
              labelText="Contact Last Name"
              name="username"
              placeholder="Enter contact last name"
              type="text"
            />
          </div>
          <div className="columnTwo w-full">
            <CustomInput
              labelText={"Contact Email"}
              placeholder={"Enter contact email"}
              type="text"
            />
            <CustomInput
              labelText={"Contact Phone Number"}
              placeholder={"Enter contact phone number"}
              type="text"
            />
          </div>
          <div className="">
            <CustomInput
              labelText={"Address"}
              placeholder={"Enter customer address"}
              type={"text"}
            />
          </div>
          <div className="">
            <CustomInput
              labelText={"City"}
              placeholder={"Enter city"}
              type={"text"}
            />
          </div>
          <div className="">
            <CustomInput
              labelText={"Landmark"}
              placeholder={"Enter landmark"}
              type={"text"}
            />
          </div>
          <div className="columnTwo">
            <CustomInput
              labelText={"State / Province / Region"}
              placeholder={"Enter state / province / region"}
              type={"text"}
            />
            <CustomInput
              labelText={"Postal / Zipcode"}
              placeholder={"Enter postal / zipcode"}
              type={"text"}
            />
          </div>
        </Whitespace>
      </div>
    </>
  );
};

export default BasicDetails;
