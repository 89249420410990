import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { useNavigate } from "react-router-dom";
import MenuDropdown from "../../Atoms/MenuDropDown";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import TimelapseIcon from "@mui/icons-material/Timelapse";

const Navbar = ({ profile }) => {
  const navigate = useNavigate();
  const currentTab = window.location.href.split("/")[3];

  const profileMenuItems = [
    { type: "link", label: "Profile", href: "#account" },
    {
      type: "button",
      label: "Log out",
    },
  ];

  const purchaseMenuItems = [
    { type: "link", label: "New Purchase", href: "/purchase/new" },
    { type: "link", label: "Purchase View", href: "/purchase/view" },
  ];

  const navItems = [
    {
      label: "Dashboard",
      icon: <DashboardIcon sx={{ fontSize: "22px" }} />,
      route: "dashboard",
    },
    // {
    //   label: "Production",
    //   icon: <ViewInArIcon sx={{ fontSize: "22px" }} />,
    //   route: "production",
    // },
    {
      label: "Employees",
      icon: <GroupsIcon sx={{ fontSize: "22px" }} />,
      route: "employee",
      // isDropdown: true,
    },
    {
      label: "Lead",
      icon: <PermIdentityIcon sx={{ fontSize: "22px" }} />,
      route: "lead",
      // isDropdown: true,
    },
    {
      label: "Purchase",
      icon: <LocalOfferIcon sx={{ fontSize: "22px" }} />,
      route: "purchase",
      // isDropdown: true,
    },
    {
      label: "Quotation",
      icon: <RequestQuoteIcon sx={{ fontSize: "22px" }} />,
      route: "quotation",
    },
    {
      label: "Invoices",
      icon: <ReceiptLongIcon sx={{ fontSize: "22px" }} />,
      route: "invoice",
    },
    // {
    //   label: "Reports",
    //   icon: <PieChartIcon sx={{ fontSize: "22px" }} />,
    //   route: "report",
    // },

    {
      label: "Product",
      icon: <ViewInArIcon sx={{ fontSize: "22px" }} />,
      route: "product",
    },
    {
      label: "Customer",
      icon: <SupervisedUserCircleIcon sx={{ fontSize: "22px" }} />,
      route: "customer",
    },
    {
      label: "Timesheet",
      icon: <TimelapseIcon sx={{ fontSize: "22px" }} />,
      route: "timesheet",
    },
    {
      label: "Settings",
      icon: <SettingsIcon sx={{ fontSize: "22px" }} />,
      route: "settings",
    },
  ];

  return (
    <div className="flex justify-between items-center bg-white border px-[125px]">
      {navItems.map((item) => (
        <div
          key={item.route}
          className={`flex flex-col items-center gap-1 cursor-pointer  p-3 ${
            currentTab === item.route
              ? "text-primColor border-b-2 border-primColor"
              : "text-[#7F7F7F]"
          }`}
          onClick={() => navigate(`/${item.route}`)}
        >
          {item.icon}
          {item.isDropdown ? (
            <MenuDropdown
              menuItems={
                item.route === "purchase" ? purchaseMenuItems : profileMenuItems
              }
              padding="px-4"
              right="left-0"
              minWidth="min-w-52"
            >
              <p
                className={`font-medium ${
                  currentTab === item.route
                    ? "text-primColor"
                    : "text-[#7F7F7F]"
                }`}
              >
                {item.label}
              </p>
            </MenuDropdown>
          ) : (
            <p
              className={`font-medium ${
                currentTab === item.route ? "text-primColor" : "text-[#7F7F7F]"
              }`}
            >
              {item.label}
            </p>
          )}
        </div>
      ))}
      {/* <div className="flex flex-col items-center text-[#7F7F7F]">
        <MoreHorizIcon sx={{ fontSize: "22px" }} />
      </div> */}
    </div>
  );
};

export default Navbar;
