import React, { useCallback, useState } from "react";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../../Components/Molecules/CustomTable";
import Whitespace from "../../../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../../../Components/Atoms/ComponentTitle";
import CustomButton from "../../../../Components/Atoms/CustomButton";

const FollowUpRemindersSettings = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const navigate = useNavigate();
  const data = [
    {
      siNo: 1,
      questionGroup: "QUOTE",
      noOfQuestions: "Before Expiry Date - 2 days",
      notifyEndDate: "EMAIL",
      createBy: "User one",
    },
  ];

  return (
    <>
      <Whitespace>
        <div className="flex items-center mb-7">
          <ComponentTitle title="FOLLOW UP REMINDER" mb="0" />
          <CustomButton size="medium" onClick={() => handleOpen()}>
            <Add sx={{ fontSize: "15px" }} />
            New&nbsp;Reminder
          </CustomButton>
        </div>
        <div className="overflow-auto">
          <CustomTable
            tableTitle={[
              "#",
              "MODULE",
              "REMINDER NAME",
              "TEMPLATE description",
              "created by",
              "Actions",
            ]}
            navigateView={() => navigate("/employee/view")}
            tableData={data}
            viewButton={true}
            editButton={true}
            deleteButton={false}
          />
        </div>
      </Whitespace>
    </>
  );
};

export default FollowUpRemindersSettings;
