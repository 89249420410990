import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import EmailIcon from "@mui/icons-material/Email";
import * as React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Add } from "@mui/icons-material";
import SubHeader from "../../../Components/Molecules/Subheader";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimeOffRequestForm from "./timeOffRequestForm";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import { useCallback } from "react";

const TimeoffPage = (props) => {
  const localizer = momentLocalizer(moment);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);
  return (
    <>
      <CustomDialog
        width="600px"
        minWidth="750px"
        Title={"Create New Timeoff Request"}
        open={open}
        handleClose={handleClose}
      >
        <TimeOffRequestForm />
      </CustomDialog>
      <div className="main-container">
        <SubHeader
          headings={[
            {
              name: "Timesheet",
              url: "/timesheet",
            },
            {
              name: " Time off Requests",
            },
          ]}
          actions={[
            {
              type: "button",
              name: "Add TimeOff",
              onclick: () => {
                handleOpen();
              },
              icon: (
                <Add
                  sx={{
                    fontSize: "18px",
                    marginRight: "5px",
                    color: "#7F7F7F",
                  }}
                />
              ),
            },
          ]}
        />
        <div className="flex gap-8 mt-6">
          <div className="w-[30%] bg-red-4003">
            <Whitespace>
              <div className="px-2">
                <ComponentTitle
                  title="PENDING TIMEOFF REQUESTS"
                  className="mb-0"
                  mb="2"
                />
                <div>
                  <p className="-mb-1 text-[12px] text-[#9E2F00] font-medium">
                    #1234 Trevor Alan
                  </p>
                  <div className="-mb-1  flex text-[#2E0E00] items-center gap-1">
                    <EmailIcon sx={{ fontSize: "10px" }} />
                    <p className="text-[12px] text-[#2E0E00]">
                      trevor.alan@gmail.com
                    </p>
                  </div>
                  <div className="-mb-1  flex text-[#2E0E00] items-center gap-1">
                    <AccessTimeIcon sx={{ fontSize: "10px" }} />
                    <p className=" text-[12px] text-[#2E0E00]">
                      2023/12/16 - 2023/12/20
                    </p>
                  </div>
                </div>
                <div className="mt-3">
                  <p className="-mb-1 text-[12px] text-[#9E2F00] font-medium">
                    #1234 Trevor Alan
                  </p>
                  <div className="-mb-1  flex text-[#2E0E00] items-center gap-1">
                    <EmailIcon sx={{ fontSize: "10px" }} />
                    <p className="text-[12px] text-[#2E0E00]">
                      trevor.alan@gmail.com
                    </p>
                  </div>
                  <div className="-mb-1  flex text-[#2E0E00] items-center gap-1">
                    <AccessTimeIcon sx={{ fontSize: "10px" }} />
                    <p className=" text-[12px] text-[#2E0E00]">
                      2023/12/16 - 2023/12/20
                    </p>
                  </div>
                </div>
                <div className="mt-3">
                  <p className="-mb-1 text-[12px] text-[#9E2F00] font-medium">
                    #1234 Trevor Alan
                  </p>
                  <div className="-mb-1  flex text-[#2E0E00] items-center gap-1">
                    <EmailIcon sx={{ fontSize: "10px" }} />
                    <p className="text-[12px] text-[#2E0E00]">
                      trevor.alan@gmail.com
                    </p>
                  </div>
                  <div className="-mb-1  flex text-[#2E0E00] items-center gap-1">
                    <AccessTimeIcon sx={{ fontSize: "10px" }} />
                    <p className=" text-[12px] text-[#2E0E00]">
                      2023/12/16 - 2023/12/20
                    </p>
                  </div>
                </div>
              </div>
            </Whitespace>
          </div>
          <div className="time_off_container w-full w-7/10">
            <Whitespace>
              <div className="time_off_container_right">
                <Calendar
                  localizer={localizer}
                  views={["month", "week"]}
                  selectable
                  startAccessor="start"
                  popup={true}
                  endAccessor="end"
                  style={{ height: "83vh" }}
                />
              </div>
            </Whitespace>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeoffPage;
