import React, { useEffect, useRef, useState } from "react";

const MenuDropdown = ({
  menuItems,
  children,
  padding = "p-0",
  right = "right-0",
  minWidth = "min-w-48",
  top,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        if (setIsOpen) {
          setIsOpen(false);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      className={`relative inline-block text-left ${padding} bg-[]`}
      ref={ref}
    >
      <div>
        <button
          type="button"
          onClick={toggleMenu}
          className="inline-flex w-full  justify-center gap-x-1.5 rounded-md py-1 text-sm font-semibold text-gray-900 ring-white  border-none"
        >
          {children}
        </button>
      </div>

      {isOpen && (
        <div
          className={`absolute ${right} ${minWidth} ${top} z-10 mt-2  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none `}
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            {menuItems.map((item, index) =>
              item.type === "link" ? (
                <a
                  key={index}
                  href={item.href}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-primColor hover:text-white"
                  role="menuitem"
                  // tabIndex="-1"
                >
                  {item.label}
                </a>
              ) : (
                <button
                  key={index}
                  onClick={item.onClick}
                  className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-primColor hover:text-white"
                  role="menuitem"
                  tabIndex="-1"
                >
                  {item.label}
                </button>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuDropdown;
