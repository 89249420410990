import React from "react";
import CustomInput from "../../Components/Atoms/CustomInput";
import AuthLayout from "../../Components/Layout/AuthLayout";
import CustomButton from "../../Components/Atoms/CustomButton";

const Forgotpassword = () => {
  return (
    <AuthLayout showButton={true}>
      <div className="">
        <form>
          <div className="flex items-center gap-2">
            <p className="text-customGray text-3xl font-semibold">Forgot</p>
            <p className="text-primColor text-3xl font-semibold">Password</p>
          </div>

          <p className="text-secColor">Enter your email to reset password</p>

          <div className="mt-10">
            <CustomInput
              margin="true"
              labelText="Email"
              name="username"
              placeholder="Enter your mail"
              type="text"
            />

            <CustomButton type="submit" btnType="primary" size="large">
              Send Mail
            </CustomButton>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};
export default Forgotpassword;
