import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../Views/Login/Login";
import Forgotpassword from "../Views/Login/ForgotPassword";
import ResetPassword from "../Views/Login/ResetPassword";
import Layout from "../Components/Organisms/Layout";
import EmployeePage from "../Views/Employee";
import EmployeeForm from "../Views/Employee/EmployeeForm";
import Team from "../Views/Employee/Teams";
import TeamForm from "../Views/Employee/Teams/TeamForm";
import ShiftManagementPage from "../Views/Employee/ShiftManagement";
import EmployeeViewPage from "../Views/Employee/EmployeeView";
import QuotationPage from "../Views/Quotation";
import QuotationForm from "../Views/Quotation/quotationForm";
import LeadPage from "../Views/Leads";
import LeadForm from "../Views/Leads/NewLead";
import SettingPage from "../Views/Settings";
import LeadSettings from "../Views/Settings/LeadSettings";
import LeadViewPage from "../Views/Leads/LeadView";
import InvoicePage from "../Views/Invoice";
import InvoiceForm from "../Views/Invoice/invoiceForm";
import PurchasePage from "../Views/PurchaseOrder";
import QuoteAndInvoiceSettings from "../Views/Settings/QuoteAndInvoice";
import InvoiceView from "../Views/Invoice/invoiceView";
import AccountSetting from "../Views/Settings/AccountSettings";
import EmployeeFieldsSettings from "../Views/Settings/CustomFeildSettings/EmployeeFeildsSettings";
import HolidaySettingsPage from "../Views/Settings/OtherSettings/HolidaySettings";
import CustomRolesPage from "../Views/Settings/CustomRoles";
import EditCustomRole from "../Views/Settings/CustomRoles/editCustomRoles";
import CustomerPage from "../Views/Customer";
import CustomerForm from "../Views/Customer/customerForm";
import ProductPage from "../Views/Product";
import ProductForm from "../Views/Product/ProductForm";
import ProductSettings from "../Views/Settings/ProductSettings";
import TimeSheetSettings from "../Views/Settings/TimeSheetSettings";
import TimeSheetPage from "../Views/TimeSheet";
import TimeSheetApprovalPage from "../Views/TimeSheet/TimeSheetApprovals";
import TimesheetApprovalView from "../Views/TimeSheet/TimeSheetApprovals/timeSheetApprovalView";
import TimeOffRequestPage from "../Views/Settings/TimeSheetSettings/TimeOffRequest";
import TimeoffPage from "../Views/TimeSheet/TimeOffRequest";
import ProductGroupPage from "../Views/Product/productGroup";
import ProductGroupForm from "../Views/Product/productGroup/productGroupform";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/" element={<Login />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/layout" element={<Layout />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/dashboard" element={"dashboard"} />
          <Route path="/production" element={"production"} />
          <Route path="/purchase" element={<PurchasePage />} />
          <Route path="/customer" element={<CustomerPage />} />
          <Route path="/customer/customerform" element={<CustomerForm />} />
          <Route path="/quotation" element={<QuotationPage />} />
          <Route path="/quotation/quotationform" element={<QuotationForm />} />
          <Route path="/invoice" element={<InvoicePage />} />
          <Route path="/invoice/invoiceform" element={<InvoiceForm />} />
          <Route path="/invoice/invoicview" element={<InvoiceView />} />
          <Route path="/report" element={"report"} />
          <Route path="/settings" element={<SettingPage />} />
          <Route
            path="/settings/holidaysettings"
            element={<HolidaySettingsPage />}
          />
          <Route path="/settings/leadsettings" element={<LeadSettings />} />
          <Route path="/timesheet" element={<TimeSheetPage />} />
          <Route
            path="/timesheet/timesheetapproval"
            element={<TimeSheetApprovalPage />}
          />
          <Route
            path="/timesheet/approvalview"
            element={<TimesheetApprovalView />}
          />
          <Route path="/timesheet/timeoffpage" element={<TimeoffPage />} />

          <Route
            path="/settings/timesheetsettings"
            element={<TimeSheetSettings />}
          />
          <Route
            path="/settings/quoteandinvoicesettings"
            element={<QuoteAndInvoiceSettings />}
          />
          <Route
            path="/settings/accountsettings"
            element={<AccountSetting />}
          />
          <Route path="/settings/customroles" element={<CustomRolesPage />} />
          <Route path="/settings/editroles" element={<EditCustomRole />} />
          <Route path="/employee" element={<EmployeePage />} />
          <Route path="/employee/employeeform" element={<EmployeeForm />} />
          <Route path="/employee/teams" element={<Team />} />
          <Route path="/employee/teamform" element={<TeamForm />} />
          <Route
            path="/employee/customsettings"
            element={<EmployeeFieldsSettings />}
          />
          <Route
            path="/employee/shiftmanagement"
            element={<ShiftManagementPage />}
          />
          <Route path="/employee/view" element={<EmployeeViewPage />} />
          <Route path="/lead" element={<LeadPage />} />
          <Route path="lead/leadform" element={<LeadForm />} />
          <Route path="lead/leadview" element={<LeadViewPage />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/product/productgroup" element={<ProductGroupPage />} />
          <Route
            path="/product/productgroupform"
            element={<ProductGroupForm />}
          />

          <Route path="/product/productform" element={<ProductForm />} />
          <Route path="/settings/product" element={<ProductSettings />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
