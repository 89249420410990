import React from "react";
import CustomInput from "../../../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../../../Components/Atoms/CustomCancelSubmit";
import CustomSelect from "../../../../Components/Atoms/CustomSelect";

const DiscountFeeForm = () => {
  return (
    <>
      <div className="">
        <div className="columnTwo">
          <CustomInput
            margin="true"
            name="User"
            labelText="Discount / Fee Name*"
            placeholder="Eg: Spring Season Discount"
            type="text"
          />
          <CustomInput
            margin="true"
            name="User"
            labelText="Code"
            placeholder="Eg: SPRING20"
            type="text"
          />{" "}
          <CustomSelect
            margin="true"
            name="User"
            labelText="Type"
            placeholder="Select Type"
            type="text"
          />{" "}
          <CustomInput
            margin="true"
            name="User"
            labelText="Percentage"
            placeholder="Eg: 2"
            type="text"
          />{" "}
          <CustomInput
            margin="true"
            name="User"
            labelText="Max Discount Amount"
            placeholder="Maintenace"
            type="text"
          />{" "}
        </div>

        <div className="mt-5">
          <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
        </div>
      </div>
    </>
  );
};

export default DiscountFeeForm;
