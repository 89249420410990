import React, { useCallback, useState } from "react";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../../Components/Molecules/CustomTable";
import Whitespace from "../../../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../../../Components/Atoms/ComponentTitle";
import CustomButton from "../../../../Components/Atoms/CustomButton";
import CustomDialog from "../../../../Components/Molecules/CustomDialog";
import TimeOffRequestForm from "./timeOffRequestForm";

const TimeOffRequestPage = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const navigate = useNavigate();
  const data = [
    {
      siNo: 1,
      questionGroup: "Group 1",
      noOfQuestions: 5,
      notifyEndDate: "22.07.2024",
    },
  ];

  return (
    <>
      <CustomDialog
        width="600px"
        minWidth="750px"
        Title={"Create New Timeoff Request Type"}
        open={open}
        handleClose={handleClose}
      >
        <TimeOffRequestForm />
      </CustomDialog>
      <div className="">
        <Whitespace>
          <div className="flex items-center mb-7">
            <ComponentTitle title="TIMEOFF REQUEST SETTINGS" mb="0" />
            <CustomButton size="medium" onClick={() => handleOpen()}>
              <Add sx={{ fontSize: "15px" }} />
              New&nbsp;Timeoff
            </CustomButton>
          </div>
          <div className="overflow-auto">
            <CustomTable
              tableTitle={["ID", "NAME", "TYPE", "NUMBER OF DAYS", "ACTIONS"]}
              navigateView={() => navigate("/employee/view")}
              tableData={data}
              viewButton={true}
              editButton={true}
              deleteButton={false}
            />
          </div>
        </Whitespace>
      </div>
    </>
  );
};

export default TimeOffRequestPage;
