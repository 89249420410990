import React, { useState } from "react";
import { Add } from "@mui/icons-material";
import SubHeader from "../../../Components/Molecules/Subheader";
import CustomTable from "../../../Components/Molecules/CustomTable";
import FilterSortby from "../../../Components/Molecules/FilterSortBy";
import { useNavigate } from "react-router-dom";

const Team = () => {
  const navigate = useNavigate();
  const data = [
    {
      siNo: 1,
      questionGroup: "Group 1",
      noOfQuestions: 5,
      notifyEndDate: "22.07.2024",
    },
    {
      siNo: 2,
      questionGroup: "Group 2",
      noOfQuestions: 10,
      notifyEndDate: "22.07.2024",
    },
  ];
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];
  return (
    <>
      {/* <Dropdown /> */}
      <div className="">
        <SubHeader
          headings={[
            {
              name: "Employees",
            },
            {
              name: "Teams",
            },
          ]}
          actions={[
            {
              type: "button",
              name: "New Team",

              onclick: () => {
                navigate("/employee/teamform");
              },
              icon: (
                <Add
                  className="custom-role-add-icon"
                  sx={{
                    fontSize: "18px",
                    marginRight: "5px",
                    color: "#7F7F7F",
                  }}
                />
              ),
            },
          ]}
        />
        <div>
          {" "}
          <FilterSortby
            exportBtn="false"
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            onClickFilter={() => {}}
          />
          <div className="overflow-auto">
            <CustomTable
              tableTitle={[
                "ID",
                "TEAM NAME",
                "NO. OF USERS",
                "CREATED ON",
                "ACTIONS",
              ]}
              // permission={props.permission}
              // navigateEdit={navigateEdit}
              // navigateView={() => navigate("/comingsoon")}
              // loading={getCustomRoleLoading}
              // toggleStatus={changeRoleStatus}
              tableData={data}
              viewButton={true}
              editButton={true}
              deleteButton={true}
              // statusButton={true}
              // add={permissionList.Roles.addRoles}
              // show={permissionList.Roles.showRoles}
              // del={permissionList.Roles.deleteRoles}
              // edit={permissionList.Roles.editRoles}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
