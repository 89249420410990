import React from "react";
import CustomInput from "../../../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../../../Components/Atoms/CustomCancelSubmit";
import TextArea from "../../../../Components/Atoms/TextArea";

const ProductLocationForm = () => {
  return (
    <>
      <div className="">
        <div className="columnTwo">
          <CustomInput
            margin="true"
            name="User"
            labelText="Category Name (Max 26 Characters)"
            placeholder="Maintenace"
            type="text"
          />
          <CustomInput
            margin="true"
            name="User"
            labelText="Category Name (Max 26 Characters)"
            placeholder="Maintenace"
            type="text"
          />
        </div>
        <CustomInput
          margin="true"
          name="User"
          labelText="Category Name (Max 26 Characters)"
          placeholder="Maintenace"
          type="text"
        />
        <CustomInput
          margin="true"
          name="User"
          labelText="Category Name (Max 26 Characters)"
          placeholder="Maintenace"
          type="text"
        />
        <div className="columnTwo">
          <CustomInput
            margin="true"
            name="User"
            labelText="Category Name (Max 26 Characters)"
            placeholder="Maintenace"
            type="text"
          />
          <CustomInput
            margin="true"
            name="User"
            labelText="Category Name (Max 26 Characters)"
            placeholder="Maintenace"
            type="text"
          />
        </div>{" "}
        <div className="columnTwo">
          <CustomInput
            margin="true"
            name="User"
            labelText="Category Name (Max 26 Characters)"
            placeholder="Maintenace"
            type="text"
          />
          <CustomInput
            margin="true"
            name="User"
            labelText="Category Name (Max 26 Characters)"
            placeholder="Maintenace"
            type="text"
          />
          <CustomInput
            margin="true"
            name="User"
            labelText="Category Name (Max 26 Characters)"
            placeholder="Maintenace"
            type="text"
          />
        </div>
        <TextArea
          margin="true"
          labelText="Category Description"
          name="lastname"
          placeholder="Enter Description"
          type="date"
        />
        <div className="mt-6">
          <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
        </div>
      </div>
    </>
  );
};

export default ProductLocationForm;
