import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import SubHeader from "../../Components/Molecules/Subheader";
import FilterSortby from "../../Components/Molecules/FilterSortBy";
import TableButton from "../../Components/Atoms/TableButton";
import { TableIconConstant } from "../../constants";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import TimeSheetDetailsForm from "./timeSheetDetailsForm";
import { useCallback } from "react";
import TimeSheetDetailsView from "./timeSheetDetailView";
import TimeSheetDefaulerPage from "./timeSheetDefaulters";

const TimeSheetPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [ViewOpen, setViewOpen] = useState(false);
  const [defaulterOpen, setDefaulterOpen] = useState(false);
  const handleDefaulterOpen = () => setDefaulterOpen(true);
  const handleDefaulterClose = useCallback(() => {
    setDefaulterOpen(false);
  }, [open]);

  const handleViewOpen = () => setViewOpen(true);
  const handleViewClose = useCallback(() => {
    setViewOpen(false);
  }, [open]);
  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];

  return (
    <>
      <CustomDialog
        width="600px"
        minWidth="550px"
        Title={"Edit Timesheet Details"}
        open={open}
        handleClose={handleClose}
      >
        <TimeSheetDetailsForm />
      </CustomDialog>
      <CustomDialog
        width="600px"
        minWidth="550px"
        Title={"Timesheet Details - New"}
        open={ViewOpen}
        handleClose={handleViewClose}
      >
        <TimeSheetDetailsView />
      </CustomDialog>
      <CustomDialog
        width="600px"
        minWidth="550px"
        Title={"Timesheet Defaulters for 2024/01/12"}
        open={defaulterOpen}
        handleClose={handleDefaulterClose}
      >
        <TimeSheetDefaulerPage />
      </CustomDialog>
      <div className="">
        <SubHeader
          headings={[
            {
              name: "TimeSheet",
            },
          ]}
          actions={[
            {
              type: "button",

              name: "Manage Timeoff",
              onclick: () => {
                navigate("/timesheet/timeoffpage");
              },
              iconPosition: "right",

              icon: (
                <AccessTimeIcon
                  sx={{
                    fontSize: "18px",
                    marginRight: "5px",
                    color: "#7F7F7F",
                  }}
                />
              ),
            },
            {
              type: "button",
              iconPosition: "right",

              name: "Timesheet Approvals",
              onclick: () => {
                navigate("/timesheet/timesheetapproval");
              },

              icon: (
                <DoneIcon
                  sx={{
                    fontSize: "18px",
                    marginRight: "5px",
                    color: "#7F7F7F",
                  }}
                />
              ),
            },
            {
              type: "menu_drop_down",

              children: [
                {
                  name: "regularization_request",
                  className: "job-profile-menu-para",
                },
                {
                  name: "regularization_approval",
                  className: "job-profile-menu-para",
                },
              ],
              name: "more",
              icon: (
                <KeyboardArrowDownIcon
                  className="job-profile-add-icon"
                  sx={{ fontSize: "16px", marginLeft: "8px" }}
                />
              ),
              iconPosition: "right",
            },
          ]}
        />
        <>
          <FilterSortby
            exportBtn={true}
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            onClickFilter={() => {}}
            newEntryBtn={true}
            defalterBtn={true}
            handleClickDefaulters={handleDefaulterOpen}
          />
          <div className="overflow-auto">
            <table className="bg-[#ffffff] border border-gray-300 border-collapse w-full">
              <tbody>
                <tr className="border-b border-gray-300">
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"S.No"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"NAME"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"TYPE"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"PUNCHED TIME"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"auth picture"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"LOCATION"}
                  </th>

                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"actions"}
                  </th>
                </tr>

                <tr className="border-b border-gray-300">
                  <td className="text-[12px] font-bold  tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7">
                    01
                  </td>
                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <div className="">
                      <p className="text-[13px] font-bold">Sample customer</p>
                      <p className="text-[12px] text-[#140C00] opacity-80 font-medium -mb-2">
                        timesheet@gmail.com
                      </p>
                    </div>
                  </td>
                  <td className="text-[12px] font-bold  tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7">
                    Punch Out
                  </td>
                  <td className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    11 14:58
                  </td>
                  <td className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase"></td>
                  <td
                    onClick={() => handleViewOpen()}
                    className="text-sm font-medium leading-4 tracking-normal text-left text-primColor py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis"
                  >
                    View on Map
                  </td>

                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <div className="flex gap-2">
                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.edit}
                        onClickBtn={() => handleOpen()}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      </div>
    </>
  );
};

export default TimeSheetPage;
