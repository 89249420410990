import React from "react";
import AddIcon from "@mui/icons-material/Add";
import InventoryIcon from "@mui/icons-material/Inventory";
import { TableIconConstant } from "../../../constants";

const CustomButton = (props) => {
  const { buttonType, size, btnType, btnColor, btnBackground, permission } =
    props;

  const renderBtn = () => {
    switch (buttonType) {
      case TableIconConstant.add:
        return <AddIcon sx={{ fontSize: "15px" }} />;
      case TableIconConstant.inventory:
        return <InventoryIcon sx={{ fontSize: "15px" }} />;
      default:
        return null;
    }
  };

  const getButtonClass = () => {
    switch (btnType) {
      case "terinary":
        return `bg-[#FFEBD8] border border-[#FE5202] text-[#FE5202] border-[#B1B1B1] text-[15px] font-[400] ${
          size == "small"
            ? "py-[3px] px-1"
            : size == "large"
            ? "py-2 px-9"
            : "px-7 py-1"
        }`;
      case "primary":
        return `bg-primColor text-white    ${
          size == "small"
            ? "py-[3px] px-1"
            : size == "large"
            ? "py-3 px-9 font-semibold"
            : "px-9 py-2"
        }`;
      case "btn_red":
        return "bg-red-600 text-white";
      case "hybrid":
        return "bg-white text-teal-600 border-teal-600";
      default:
        return `bg-white border text-[#757575] border-[#B1B1B1] text-sm ${
          size == "small"
            ? "py-[3px] px-1"
            : size == "large"
            ? "py-2 px-9"
            : "px-2 py-1"
        }`;
    }
  };

  return (
    <div permission={permission}>
      <button
        className={`rounded  ${getButtonClass()} w-full`}
        style={{ color: btnColor, backgroundColor: btnBackground }}
        onClick={props.onClick}
        type={props.type}
        disabled={props.disabled}
      >
        <div className="flex items-center gap-1 justify-center">
          {renderBtn()}
          {props.children}
        </div>
      </button>
    </div>
  );
};

export default CustomButton;
