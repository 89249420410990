import React, { useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";

const CustomDialog = React.memo(
  ({
    width,
    minWidth,
    height,
    right,
    left,
    position,
    maxHeight,
    innerHeight,
    open,
    Title,
    titleCls,
    titleMt,
    titleMb,
    titleMl,
    titleMr,
    handleClose,
    TitleClick,
    overflow,
    children,
    padding,
  }) => {
    const style = {
      width: width || "40%",
      minWidth: minWidth || "250px",
      height: height,
      right: right,
      left: left,
      position: position,
      overflow: overflow || "visible",
      maxHeight: maxHeight || "80%",
    };

    const style_inner_model = {
      padding: padding || "",
    };

    const ref = useRef(null);

    useEffect(() => {
      function handleClickOutside(e) {
        e.stopPropagation();
        if (ref.current && !ref.current.contains(e.target)) {
          handleClose();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);

    const style1 = {
      maxHeight: innerHeight,
    };

    return open ? (
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-20 flex justify-center items-center z-50 animate-fadein">
        <div
          ref={ref}
          style={style}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-5"
        >
          <div
            style={style_inner_model}
            className="flex justify-between items-center mb-7"
          >
            <div
              style={{
                marginTop: titleMt,
                marginBottom: titleMb,
                marginLeft: titleMl,
                marginRight: titleMr,
              }}
              className=""
            >
              <h4
                className={`${titleCls} text-[#140C00] text-[17px] font-semibold`}
              >
                {Title} {TitleClick ? <span>{TitleClick}</span> : null}
              </h4>
            </div>
            <button onClick={handleClose} className="modal-close-btn">
              <CloseIcon sx={{ fontSize: "20px" }} />
            </button>
          </div>
          <div style={style1} className="overflow-auto max-h-[64vh] px-3">
            {children}
          </div>
        </div>
      </div>
    ) : null;
  }
);

export default CustomDialog;
