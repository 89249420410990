import React, { useEffect, useState } from "react";
import SettingsTabsList from "../../../Components/Molecules/SettingsTabsList";
import SubHeader from "../../../Components/Molecules/Subheader";
import removear from "../../../Utils/RemoveArrayEle";
import LeadCategory from "./LeadCategory";
import LeadStatus from "./LeadStatus";

function LeadSettings() {
  const [page, setPage] = useState("Job Category");
  const list = ["Lead Category", "Lead Status", "Lead Card Templates"];
  const [tablist, setTablist] = useState([]);
  useEffect(() => {
    let res = removear(list, null);
    setPage(res?.[0]);
    setTablist(res);
  }, []);

  const renderPage = () => {
    switch (page) {
      case "Lead Category":
        return <LeadCategory />;
      case "Lead Status":
        return <LeadStatus />;
      case "Lead Card Templates":
        return "name";
      default:
        return null;
    }
  };

  return (
    <>
      <div className="">
        <div className="">
          <SubHeader
            headings={[
              {
                name: "settings",
                url: "/setting",
              },
              {
                name: "Lead Settings",
              },
            ]}
            actions={[]}
          />
        </div>
        <div className="flex gap-8 mt-5">
          <section className="flex-[2]">
            <SettingsTabsList
              list={tablist}
              current={page}
              title={"configurations"}
              page={setPage}
            />
          </section>

          <section className=" flex flex-col flex-[8]">{renderPage()}</section>
        </div>
      </div>
    </>
  );
}
export default LeadSettings;
