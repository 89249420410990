import BusinessIcon from "@mui/icons-material/Business";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import PeopleIcon from "@mui/icons-material/People";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import WebhookIcon from "@mui/icons-material/Webhook";
import ReceiptIcon from "@mui/icons-material/Receipt";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import DateRangeIcon from "@mui/icons-material/DateRange";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import { EventSeat, Money } from "@mui/icons-material";
import { ShoppingCart } from "@mui/icons-material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SettingsInputHdmiIcon from "@mui/icons-material/SettingsInputHdmi";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

export const TableIconConstant = {
  view: "view",
  edit: "edit",
  addUser: "addUser",
  status: "status",
  time: "time",
  delete: "delete",
  copy: "copy",
  barcode: "barcode",
  qrcode: "qrcode",
  location: "location",
  close: "close",
  history: "history",
  add: "add",
  inventory: "inventory",
  remove: "remove",
  link: "link",
  answer: "answer",
  reject: "reject",
};

export const imageFileFormat = ["jpeg", "jpg", "png", "gif"];

export const customSettingsImgList = {
  "Customer Fields": <PeopleIcon />,
  "Employee Fields": <PeopleIcon />,
  "Asset Fields": <ImportantDevicesIcon />,
  "Organization Fields": <BusinessIcon />,
  "Quotation Fields": <ReceiptIcon />,
  "Job Checklist": <PlaylistAddCheckIcon />,
};
export const iconslist = [
  <PersonIcon />,
  <RecentActorsIcon />,
  <TimelapseIcon />,
  <PeopleIcon />,
  <ImportantDevicesIcon />,
  <ReceiptIcon />,
  <BusinessIcon />,
  <PlaylistAddCheckIcon />,
  <LinearScaleIcon />,
  <NotificationsIcon />,
];
export const settingsList = (languageData, choosedLanguage, showPermission) => [
  {
    title: "GENERAL SETTINGS",
    data: [
      {
        img: <PersonIcon />,
        name: "account_Settings",
        description: "update_your_profile",
        url: "generalsettings",
        id: "users",
      },
      {
        img: <BusinessIcon />,
        name: "organization_Settings",
        description: "update_company_configs",
        url: "organization",
        id: "Organization",
      },
      {
        img: <RecentActorsIcon />,
        name: "custom_roles",
        description: "roles_and_permission",
        url: "custom-role",
        id: "Roles",
      },
      {
        img: <ImportExportIcon />,
        name: "data_import",
        description: "import_your_existing_data",
        url: "/comingsoon",
        id: "Data",
      },
    ],
  },
  {
    title: "CONFIGURATION SETTINGS",
    data: [
      {
        img: <EventSeat />,
        name: "ooking_settings",
        description: "manage_slots",
        url: "bookingsettings",
      },
      {
        img: <PeopleIcon />,
        name: "customer_settings",
        description: "manage_customers",
        url: "customer-settings",
      },
      {
        img: <TimelapseIcon />,
        name: "timesheet_roles",
        description: "manage_timesheet_configs",
        url: "timesheet_settings",

        id: "TimeSheet_settings",
      },
      {
        img: <ReceiptIcon />,
        name: "quotes_and_invoices",
        description: "manage_payment_taxes",
        url: "quoteandinvoice-setting",

        id: "Quotes_settings",
      },
      {
        img: <ContentPasteIcon />,
        name: "contracts",
        description: "manage_packages",
        url: "/comingsoon",
        id: "Contracts_settings",
      },
      {
        img: <ImportantDevicesIcon />,
        name: "assets",
        description: "manage_asset_configs",
        url: "/comingsoon",
        id: "Assets_settings",
      },
      {
        img: <NotificationsIcon />,
        name: "notification_alert",
        description: "manage_notifctaion_settings",
        id: "Notification_settings",

        url: "notificationsettings",
      },
      {
        img: <PermDataSettingIcon />,
        name: "products",
        description: "manage_parts_and_services",
        id: "Products_settings",

        url: "partandservice_settings",
      },
      {
        img: <WorkIcon />,
        name: "job_settings",
        description: "manage_job_settings",
        url: "job_settings",
        id: "Job_settings",
      },

      {
        img: <TimelapseIcon />,
        name: "custom_function_settings",
        description: "manage_custom_function_settings",
        id: "CustomFunction_settings",

        url: "custom_function_settings",
      },
      {
        img: <Money />,
        name: "finance_settings",
        description: "manage_finance_settings",
        id: "Finance_settings",

        url: "finance-settings",
      },
      {
        img: <AttachMoneyIcon />,
        name: "payroll_settings",
        description: "manage_payroll_settings",
        id: "Payroll_settings",

        url: "payroll-settings",
      },
      {
        img: <ShoppingCart />,
        name: "aurchase_order_settings",
        description: "manage_paurchase_order_settings",
        id: "Purchase_Order_settings",

        url: "purchase_order_settings",
      },
      {
        img: <WebhookIcon />,
        name: "webhook_settings",
        description: "manage_webhook_settings",
        id: "Webhook_settings",

        url: "webhook-settings",
      },
      {
        img: <LocalShippingIcon />,
        name: "shipping_settings",
        description: "shipping_settings",
        id: "shipping_settings",

        url: "shipping_settings",
      },
      {
        img: <PeopleIcon />,
        name: "request_settings",
        description: "request_settings_description",
        url: "requestsetting",
      },
    ],
  },
  {
    title: "CUSTOM FIELDS SETTINGS",
    data: [
      {
        img: <PeopleIcon />,
        name: "Customer Fields",
        description: "Manage customer",
        url: "/comingsoon",
        id: "Customer_fields",
      },

      {
        img: <PeopleIcon />,
        name: "Employee Fields",
        description: "Manage timesheet configs",
        url: "/settings/timesheetsettings",
        id: "",
      },

      {
        img: <ImportantDevicesIcon />,
        name: "Asset Fields",
        description: "Manage payments, taxes, etc..,",
        url: "/comingsoon",
        id: "",
      },

      {
        img: <BusinessIcon />,
        name: "Organization Fields",
        description: "Manage packages, billings, etc.,",
        url: "/comingsoon",
      },
      {
        img: <ReceiptIcon />,
        name: "Quotation Fields",
        description: "Manage asset configs",
        url: "/comingsoon",
        id: "",
      },

      {
        img: <PlaylistAddCheckIcon />,
        name: "Job Checklist",
        description: "Manage notification configs",
        url: "/comingsoon",
        id: "",
      },
    ],
  },
  {
    title: "OTHER SETTINGS",
    data: [
      {
        img: <DateRangeIcon />,
        name: "holidays",
        description: "manage_holidays",
        url: "holiday",
        id: "Holiday",
      },

      {
        img: <MarkEmailReadIcon />,
        name: "email_templates",
        description: "manage_email_templates",
        url: "/comingsoon",
        id: "Email",
      },
      {
        img: <RateReviewIcon />,
        name: "performance_review",
        description: "performance_review_questions",
        id: "Performance",
        url: "/setting/performancereview",
      },
      {
        img: <LinearScaleIcon />,
        name: "workflows",
        description: "manage_predefined_actions",
        id: "Workflows",
        url: "/setting/workflows",
      },
      {
        img: <CompareArrowsIcon />,
        name: "webhooks",
        description: "manage_webhooks",
        id: "Webhooks",
        url: "/setting/webhook",
      },
      {
        img: <SettingsInputHdmiIcon />,
        name: "Plugins",
        description: "Manage Plugins",
        id: "Plugins",
        url: "/setting/plugin",
      },
    ],
  },
];
