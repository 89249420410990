import React from "react";
import { useState } from "react";
import CustomInput from "../../../Components/Atoms/CustomInput";
import PhoneNumber from "../../../Components/Atoms/PhoneNumber";
import CustomSelect from "../../../Components/Atoms/CustomSelect";
import userdummy from "../../../Assets/user-dummy.jpg";
import { useRef } from "react";
import { imageFileFormat } from "../../../constants";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import CustomButton from "../../../Components/Atoms/CustomButton";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import MessageIcon from "@mui/icons-material/Message";
import EmailIcon from "@mui/icons-material/Email";
import { Phone } from "@mui/icons-material";

function GeneralSetting(props) {
  const [checkphone, setCheckphone] = useState(0);

  const [image, setImage] = useState(userdummy);
  const hiddenFileInput = useRef(null);
  const handleChange = (e) => {
    const filename = e?.currentTarget?.files[0]?.name?.split(".");
    if (filename && filename[0]) {
      const format = filename[filename?.length - 1];
      if (imageFileFormat.includes(format)) {
        // formik.setFieldValue("profile", e.currentTarget.files[0]);
        setImage(URL.createObjectURL(e.currentTarget.files[0]));
      }
    }
  };
  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  return (
    <>
      <Whitespace>
        <ComponentTitle title="ACCOUNT SETTINGS" />

        <div className="flex gap-14 items-center mt-4">
          <div className="flex justify-start items-center">
            <div className="flex flex-col gap-2.5 justify-start items-center relative">
              <img
                className="w-[165px] h-[145px] border border-solid border-gray-200 rounded-full flex items-center justify-center"
                src={image}
              />
              <input
                type="file"
                onChange={handleChange}
                ref={hiddenFileInput}
                className="hidden"
                accept="image/jpg,image/png,image/jpeg"
              />
              <div
                className="px-6 py-2  rounded bg-primColor text-[#ffffff] flex justify-center items-center text-[13px] font-light leading-4.5 tracking-wide cursor-pointer  border border-[#A9A9A9] border-1"
                onClick={handleClick}
              >
                Upload
              </div>
            </div>
          </div>
          <div className="columnTwo w-full">
            <CustomInput
              margin="true"
              labelText="Employee Code"
              name="username"
              placeholder="Enter Code"
              type="text"
            />
            <CustomInput
              margin="true"
              labelText="First Name"
              name="username"
              placeholder="Enter Name"
              type="text"
            />
            <CustomInput
              labelText="Last Name"
              name="phone"
              placeholder="Enter Name"
            />
            <CustomInput
              setValue={"_id"}
              labelText="Designation"
              placeholder="Enter Designation"
            />
            <CustomInput
              setValue={"_id"}
              labelText="Email"
              placeholder="Enter Email"
            />{" "}
            <PhoneNumber
              setValue={"_id"}
              labelText="Phone Number"
              validcheck={setCheckphone}
            />
          </div>
        </div>
        <div className="flex justify-end mb-4">
          <CustomButton btnType="primary">Save</CustomButton>
        </div>
      </Whitespace>
      <Whitespace mt="10px">
        <ComponentTitle title="MULTI-FACTOR AUTHENTICATION" mt="10px" />
        <div className="grid grid-cols-[repeat(auto-fill,minmax(30%,1fr))] gap-5">
          <div className="border border-gray-400 h-14 flex justify-between items-center rounded-md px-2 min-w-[200px]">
            <div className="flex justify-around items-center w-20">
              <MessageIcon className="text-gray-500" /> SMS
            </div>
            <button className="rounded-md border border-gray-400 bg-[#F4F1F1] px-6  py-[6px]">
              Setup
            </button>
          </div>
          <div className="border border-gray-400 h-14 flex justify-between items-center rounded-md px-2 min-w-[200px]">
            <div className="flex justify-around items-center w-20">
              <EmailIcon className="text-gray-500" /> Email
            </div>
            <button className="rounded-md border border-gray-400 bg-[#F4F1F1] px-6 py-[6px]">
              Setup
            </button>
          </div>
        </div>
      </Whitespace>
    </>
  );
}

export default GeneralSetting;
