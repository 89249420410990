import React from "react";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomSelect from "../../../Components/Atoms/CustomSelect";
import CustomCancelSubmit from "../../../Components/Atoms/CustomCancelSubmit";

const ShiftForm = () => {
  return (
    <>
      <div className="">
        <div className="columnTwo">
          <CustomSelect
            margin="true"
            name="User"
            labelText="User"
            placeholder="Select user"
            type="text"
          />
          <CustomSelect
            margin="true"
            name="lastname"
            labelText="Choose Shift"
            placeholder="Choose an option"
            type="text"
          />
        </div>
        <div className="columnTwo">
          <CustomInput
            margin="true"
            labelText="Shift Start Date"
            name="username"
            placeholder="Enter first name"
            type="date"
          />
          <CustomInput
            margin="true"
            labelText="Shift End Date"
            name="lastname"
            placeholder="Enter last name"
            type="date"
          />
        </div>

        <div className="container mx-auto my-8">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="">
                <th className="py-2 px-4 text-left text-[#140C00] text-[12px] font-bold">
                  #
                </th>
                <th className="py-2 px-4 text-left text-[#140C00] text-[12px] font-bold">
                  DATE
                </th>
                <th className="py-2 px-4 text-left text-[#140C00] text-[12px] font-bold">
                  DAY
                </th>
                <th className="py-2 px-4 text-left text-[#140C00] text-[12px] font-bold">
                  START TIME
                </th>
                <th className="py-2 px-4 text-left text-[#140C00] text-[12px] font-bold">
                  END TIME
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border">
                <td className="py-2 px-4 text-[#140C00] text-[12px]">
                  <input
                    className="border outline-none w-6 h-5 rounded"
                    type="checkbox"
                  />
                </td>
                <td className="py-2 px-4 text-[#140C00] text-[12px] font-medium">
                  Data 2
                </td>
                <td className="py-2 px-4 text-[#140C00] text-[12px] font-medium">
                  Data 3
                </td>
                <td className="py-2 px-4 text-[#140C00] text-[12px] font-medium">
                  <div className="border rounded border-[#A9A9A9] pl-3 bg-[#F6F6F6] pt-2 pb-2">
                    Data 3
                  </div>
                </td>
                <td className="py-2 px-4 text-[#140C00] text-[12px] font-medium">
                  <div className="border rounded border-[#A9A9A9] pl-3 bg-[#F6F6F6] pt-2 pb-2">
                    Data 3
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
      </div>
    </>
  );
};

export default ShiftForm;
