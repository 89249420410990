import React from "react";
import CustomSelect from "../../Components/Atoms/CustomSelect";

const EmployeeFilter = () => {
  return (
    <>
      <div className="p-0 m-0">
        <CustomSelect
          labelText={"FILTER BY EMPLOYEE ROLE"}
          placeholder={"Choose Any Role"}
          //   customCls="h-9 w-full border border-gray-200 text-[13px] font-semibold leading-tight rounded px-1.5 bg-white outline-none overflow-hidden whitespace-nowrap text-ellipsis"
        />
      </div>
    </>
  );
};

export default EmployeeFilter;
