import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MenuDropdown from "../../Atoms/MenuDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Header = ({ profile }) => {
  const menuItems = [
    { type: "link", label: "Profile", href: "#account" },

    {
      type: "button",
      label: "Log out",
      onClick: () => console.log("clickedss"),
    },
  ];
  return (
    <>
      <div className="flex justify-between items-center py-4 px-[120px] bg-white border-b-2  border-[#FFDCCC]">
        <div className="flex items-center">
          <div>
            <img src="/Assets/logo.png" alt="Login" className="h-10" />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <div className="flex items-center bg-[#F6F6F6] p-2 rounded-md mr-5">
            <input
              data-testid="search-field-header"
              // onChange={(e) => dispatch(setSearchInput(e.target.value))}
              className="w-full lg:w-64 h-8 bg-transparent outline-none"
              id="search-input-field"
              placeholder="Search"
              type="search"
            />
            <button
              data-testid="search-button-header"
              onClick={() => {}}
              className="bg-transparent border-0 outline-none"
            >
              <SearchIcon
                sx={{ fontSize: "25px" }}
                className="search-input-icon h-5 w-5 text-primColor"
              />
            </button>
          </div>

          <div className="relative">
            <IconButton>
              <NotificationsNoneIcon
                sx={{ fontSize: "29px", color: "#000000" }}
              />
            </IconButton>
            <span className="absolute top-0 right-0 inline-flex items-center justify-center h-5 w-5 rounded-full bg-red-500 text-white text-xs">
              {"2"}
            </span>
          </div>

          <MenuDropdown
            menuItems={menuItems}
            right="right-2"
            minWidth="min-w-36"
          >
            <div className="flex items-center gap-2">
              <div className="profile-img-container">
                {profile ? (
                  <img src={profile} alt="user_profile" />
                ) : (
                  <AccountCircleIcon
                    sx={{ fontSize: "44px" }}
                    className="bg-white text-gray-300 w-14 h-14 rounded-full overflow-hidden"
                  />
                )}
              </div>
              <div className="flex items-center">
                <p className="text-sm text-[#4E4E4E]">{"Pavithra new"}</p>
                <div>
                  <ArrowDropDownIcon />
                </div>
              </div>
            </div>
          </MenuDropdown>
        </div>
      </div>
    </>
  );
};

export default Header;
