import React, { useCallback, useState } from "react";
import CustomDialog from "../../../../Components/Molecules/CustomDialog";
import ReorderIcon from "@mui/icons-material/Reorder";
import { Add } from "@mui/icons-material";
import TableButton from "../../../../Components/Atoms/TableButton";
import { TableIconConstant } from "../../../../constants";
import ComponentTitle from "../../../../Components/Atoms/ComponentTitle";
import CustomButton from "../../../../Components/Atoms/CustomButton";
import Whitespace from "../../../../Components/Atoms/WhiteSpace";
import CustomSelect from "../../../../Components/Atoms/CustomSelect";
import LeadStatusForm from "./LeadStatusForm";

const LeadStatus = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const data = [
    {
      siNo: 1,
      questionGroup: "Group 1",
      noOfQuestions: 5,
      notifyEndDate: "22.07.2024",
    },
    {
      siNo: 2,
      questionGroup: "Group 2",
      noOfQuestions: 10,
      notifyEndDate: "22.07.2024",
    },
    {
      siNo: 2,
      questionGroup: "Group 2",
      noOfQuestions: 10,
      notifyEndDate: "22.07.2024",
    },
  ];

  return (
    <>
      <CustomDialog
        width="600px"
        minWidth="750px"
        Title={"New Lead Status"}
        open={open}
        handleClose={handleClose}
      >
        <LeadStatusForm />
      </CustomDialog>

      <Whitespace className="">
        <ComponentTitle title="Lead Status" />
        <div className="columnTwo">
          <CustomSelect placeholder={"Pending"} />
          <div></div>
        </div>
        <div className="flex items-center mb-3">
          <ComponentTitle title="Lead CATEGORIES" mb="0" />
          <CustomButton size="medium" onClick={() => handleOpen()}>
            <Add sx={{ fontSize: "15px" }} />
            New&nbsp;Category
          </CustomButton>
        </div>
        {data?.length > 0 ? (
          <>
            <ul className="p-3 border border-dashed border-gray-300 bg-gray-50 m-0 rounded">
              {data?.map((each, index) => (
                <>
                  <li className="flex justify-between items-center mb-2.5 border border-solid border-gray-300 p-2.5">
                    <div className="flex items-center">
                      <ReorderIcon className="mr-2" />
                      <p className="m-0 font-quicksand text-xs font-medium leading-4 text-left text-green-900">
                        Pending
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.edit}
                      />

                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.delete}
                      />
                    </div>
                  </li>
                </>
              ))}
            </ul>
          </>
        ) : (
          <p className="table-not-found-para1">{"no_records_found"}</p>
        )}
      </Whitespace>
    </>
  );
};

export default LeadStatus;
