import { useRef, useEffect } from "react";

const TextArea = ({
  value,
  onChange,
  labelText,
  name,
  customCls = "",
  customContainerCls = "",
  placeholder,
  errorMsg,
  onBlur,
  type,
  disabled,
  required,
  height,
  scrollIntoView = () => {},
}) => {
  const errorRef = useRef(null);

  useEffect(() => {
    if (errorRef.current) {
      scrollIntoView(errorRef.current);
    }
  }, [scrollIntoView]);

  return (
    <div className={`flex flex-col w-full mb-5 relative ${customContainerCls}`}>
      {labelText && (
        <label className="text-sm font-medium mb-1">
          {labelText}
          {required ? <span className="text-red-500">*</span> : null}
        </label>
      )}

      <textarea
        style={{ height: height }}
        disabled={disabled}
        className={
          errorMsg
            ? `min-h-[98px] h-full pt-2.5 resize-y text-sm font-medium border rounded border-red-500 outline-none pl-3 pr-2 ${customCls} animate-bounce`
            : `min-h-[98px] h-full pt-2.5 resize-y text-sm font-medium border rounded border-gray-400 outline-none pl-3 pr-2 ${customCls}`
        }
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        type={type}
        onBlur={onBlur}
      ></textarea>

      {errorMsg && (
        <p
          ref={errorRef}
          className="text-red-500 text-xs absolute -bottom-7 mb-3.5"
        >
          {errorMsg}
        </p>
      )}
    </div>
  );
};

export default TextArea;
