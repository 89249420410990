export const dateFormat = (
  date,
  seconds = false,
  format_char = "/",
  date_alone = false
) => {
  if (date) {
    const localdate = new Date(date).toLocaleString();

    let date1 = localdate?.split(",");
    let year = date1?.[0]?.split("/");
    let time = date1?.[1]?.split(":");
    const format = time?.[2]?.split(" ")[1] || "";

    const newDate = new Date(date);
    const cYear = newDate.getFullYear();
    const cMonth =
      newDate.getMonth() + 1 < 10
        ? `0${newDate.getMonth() + 1}`
        : newDate.getMonth() + 1;
    const cDate =
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();
    if (date_alone) {
      return year?.[2] + format_char + year?.[1] + format_char + year?.[0];
    }
    return (
      cYear +
      format_char +
      cMonth +
      format_char +
      cDate +
      " " +
      time?.[0] +
      ":" +
      time?.[1] +
      (seconds ? ":" + time?.[2] : "") +
      " " +
      format
    );
  }

  return "";
};
