import InputLable from "../InputLable";
import CustomInput from "../CustomInput";

const FileInput = ({
  label,
  id,
  onChange,
  disabled,
  multiple,
  accept,
  text,
}) => {
  return (
    <div className="flex flex-col w-full mb-2.5 relative">
      {label && <InputLable labelText={label} />}
      <div className="relative h-12 box-border p-1 border border-gray-400 rounded-md">
        <label
          className="h-full w-[104px] rounded-[4px] bg-[#d9d9d9] flex justify-center items-center mr-[7px] text-[12px] text-black font-medium"
          htmlFor={id}
        >
          Browse
        </label>
        <CustomInput
          id={id}
          onChange={onChange}
          visibility="hidden"
          name="name"
          accept={accept}
          disabled={disabled}
          multiple={multiple}
          type="file"
        />
        <h2 className="overflow-hidden max-w-full whitespace-nowrap truncate m-0 text-[12px] absolute font-medium bottom-[14px] left-[116px]">
          {text}
        </h2>
      </div>
    </div>
  );
};

export default FileInput;
