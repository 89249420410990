import React, { useState } from "react";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import SettingsIcon from "@mui/icons-material/Settings";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import RemoveIcon from "@mui/icons-material/Remove";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import SubHeader from "../../../Components/Molecules/Subheader";

function EmployeeFieldsSettings(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <CustomDialog
        handleClose={handleClose}
        open={open}
        Title={"add_custom_field_group"}
      >
        h
      </CustomDialog>
      <SubHeader
        headings={[
          {
            name: "settings",
            url: "/setting",
          },
          {
            name: "Employees Fields",
          },
        ]}
        actions={[
          {
            type: "button",
            btnType: "submit",
            name: "Save",
          },
        ]}
      />
      <div className="p-4">
        <div className="flex gap-9 justify-between w-full">
          <div className="bg-white border flex-[2] h-fit border-[#d1dddb] rounded">
            <div className="flex justify-between items-center m-4">
              <ComponentTitle title={"CUSTOM FIELD GROUPS"} mb="0" />
              <SettingsIcon sx={{ color: "#696969", fontSize: "18px" }} />
            </div>
            <div className="flex justify-between border-t px-4 py-2 cursor-pointer hover:bg-[#efefef]">
              <h3 className="text-[13px] text-tableColor font-medium max-w-[180px] truncate m-0">
                Basic Info
              </h3>
              <h3 className="text-[11px]  text-tableColor font-medium bg-[#ececec] px-3 py-[2px] rounded-full m-0">
                4 Fields
              </h3>
            </div>
            <div className="flex justify-between border-t px-4 py-2 cursor-pointer hover:bg-[#efefef]">
              <h3 className="text-[13px] text-tableColor font-medium max-w-[180px] truncate m-0">
                Address Info
              </h3>
              <h3 className="text-[11px]  text-tableColor font-medium bg-[#ececec] px-3 py-[2px] rounded-full m-0">
                4 Fields
              </h3>
            </div>
            <div className="flex justify-between border-t px-4 py-2 cursor-pointer hover:bg-[#efefef]">
              <h3 className="text-[13px] text-tableColor font-medium max-w-[180px] truncate m-0">
                Other Info
              </h3>
              <h3 className="text-[11px]  text-tableColor font-medium bg-[#ececec] px-3 py-[2px] rounded-full m-0">
                4 Fields
              </h3>
            </div>
          </div>
          <div className=" flex flex-col flex-[4]">
            <p className="font-semibold text-[15px] mb-3">
              Current Employee Custom Fields
            </p>

            <Whitespace>
              <div className="flex w-full gap-4 pl-20">
                <p className="text-tableColor font-medium mt-3">Email</p>
                <div className="w-full">
                  <div className="w-full">
                    <p className="border border-[#D1DDDB] w-full p-3 text-[#CCBCB5]">
                      Placeholder
                    </p>
                  </div>
                  <p className="text-[13px]  text-[#CCBCB5]">Description</p>
                </div>
              </div>
              <div className="flex w-full gap-4 pl-20 mt-3">
                <p className="text-tableColor font-medium mt-3">Email</p>
                <div className="w-full">
                  <div className="w-full">
                    <p className="border border-[#D1DDDB] w-full p-3 text-[#CCBCB5]">
                      Placeholder
                    </p>
                  </div>
                  <p className="text-[13px]  text-[#CCBCB5]">Description</p>
                </div>
              </div>
            </Whitespace>
          </div>
          <div className=" flex flex-col flex-[4]">
            <p className="font-semibold text-[15px] mb-3">
              Available Components
            </p>
            <Whitespace>
              <div className="flex gap-4">
                <div className="flex items-center gap-2 border cursor-pointer  border-[#c9c9c9] p-3 w-full ">
                  <RemoveIcon sx={{ color: "#6b6b6b", fontSize: "21px" }} />
                  <p className="font-medium text-[14px] text-[#474747]">
                    Singles Line Input
                  </p>
                </div>
                <div className="flex gap-2 border cursor-pointer items-center border-[#c9c9c9] p-3 w-full ">
                  <CalendarTodayIcon
                    sx={{ color: "#6b6b6b", fontSize: "21px" }}
                  />
                  <p className="font-medium text-[14px] text-[#474747]">
                    Date Input
                  </p>
                </div>
              </div>
              <div className="flex gap-4 mt-3">
                <div className="flex gap-2 cursor-pointer  items-center border border-[#c9c9c9] p-3 w-full ">
                  <WysiwygIcon sx={{ color: "#6b6b6b", fontSize: "21px" }} />
                  <p className="font-medium text-[14px] text-[#474747]">
                    Multi Line Input
                  </p>
                </div>
                <div className="flex gap-2 cursor-pointer items-center border-[#c9c9c9] border p-3 w-full ">
                  <FormatListBulletedIcon
                    sx={{ color: "#6b6b6b", fontSize: "21px" }}
                  />
                  <p className="font-medium text-[14px] text-[#474747]">
                    Dropdown
                  </p>
                </div>
              </div>{" "}
              <div className="flex gap-4 mt-3">
                <div className="flex gap-2 cursor-pointer items-center  border-[#c9c9c9] border p-3 w-full ">
                  <CheckBoxIcon sx={{ color: "#6b6b6b", fontSize: "21px" }} />
                  <p className="font-medium text-[14px] text-[#474747]">
                    Checkbox
                  </p>
                </div>
                <div className="flex gap-2 cursor-pointer items-center border-[#c9c9c9] border p-3 w-full ">
                  <RadioButtonCheckedIcon
                    sx={{ color: "#6b6b6b", fontSize: "21px" }}
                  />
                  <p className="font-medium text-[14px] text-[#474747]">
                    Radio Button
                  </p>
                </div>
              </div>
            </Whitespace>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeFieldsSettings;
