import React from "react";
import SubHeader from "../../../Components/Molecules/Subheader";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import CustomInput from "../../../Components/Atoms/CustomInput";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import TextArea from "../../../Components/Atoms/TextArea";
import { TableIconConstant } from "../../../constants";
import CustomButton from "../../../Components/Atoms/CustomButton";
import { useRef } from "react";

export default function ProductGroupForm(props) {
  const handleFilesChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const obj = {
        file_name: e.target.files[i].name,
        url: "",
        created_by: props.userId,
        attachment: e.target.files[i],
      };
    }
  };
  const hiddenFileInput = useRef(null);
  const handleUploadBtn = () => {
    hiddenFileInput?.current?.click();
  };

  return (
    <>
      <div className="">
        <SubHeader
          headings={[
            {
              name: "Products",
              url: "/product",
            },
            {
              name: "Groups",
            },
            {
              name: "New Group",
            },
          ]}
          actions={[
            {
              name: "Save",

              btnType: "submit",
              type: "button",
            },
          ]}
        />

        <div className="mt-2">
          <Whitespace className="">
            <ComponentTitle title="PRIMARY INFORMATION" />
            <div className="columnTwo">
              <CustomInput
                labelText={"Group Name"}
                placeholder={"Enter Group Name"}
              />
            </div>
            <TextArea
              labelText={"Description"}
              placeholder={"Enter Description"}
            />
          </Whitespace>
          <Whitespace className={"mt-5"}>
            <div className="flex items-center justify-between mb-10">
              <ComponentTitle title={"GROUP ITEMS"}></ComponentTitle>
              <div className="flex">
                <CustomButton size="medium" buttonType={TableIconConstant.add}>
                  Add&nbsp;Item
                </CustomButton>
              </div>
            </div>
            <input
              onChange={handleFilesChange}
              ref={hiddenFileInput}
              style={{ display: "none" }}
              type="file"
              multiple
            />

            <div className="flex flex-col justify-center items-center py-9">
              <h1 className="text-[17px] font-semibold leading-[19px] text-black mb-[16px]">
                {"Add Parts & Services"}
              </h1>
              <p className="text-[14px] font-normal leading-[15px] text-[#9d9d9d] mb-[25px]">
                {
                  "Add any parts or services of any custom line item to this invoice"
                }
              </p>

              <CustomButton
                size="medium"
                onClick={handleUploadBtn}
                buttonType={TableIconConstant.add}
              >
                {"Add Item"}
              </CustomButton>
            </div>
          </Whitespace>
        </div>
      </div>
    </>
  );
}
