import React from "react";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomSelect from "../../../Components/Atoms/CustomSelect";

const OtherSettingsForm = () => {
  return (
    <>
      <section className="">
        <Whitespace>
          <>
            <ComponentTitle title="OTHER SETTINGS" />
            <form className="">
              <div className="columnTwo">
                <CustomSelect
                  name="currentpassword"
                  labelText="Time Zone"
                  placeholder="Choose Time Zone"
                />
                <CustomSelect
                  name="currentpassword"
                  labelText="Language"
                  placeholder="Choose Language"
                />
              </div>
              <div className="columnTwo">
                <CustomSelect
                  name="currentpassword"
                  labelText="Currency"
                  placeholder="Choose Currency"
                />
                <CustomSelect
                  name="currentpassword"
                  labelText="Date Format"
                  placeholder="Choose Date Format"
                />
              </div>
              <div className="columnTwo">
                <CustomSelect
                  name="currentpassword"
                  labelText="Time Format"
                  placeholder="Choose Time Format"
                />
                <CustomSelect
                  name="currentpassword"
                  labelText="Length Metrics"
                  placeholder="Choose Length Metrics"
                />
              </div>
              <div className="columnTwo">
                <CustomSelect
                  name="currentpassword"
                  labelText="Weight Metrics"
                  placeholder="Choose Weight Metrics"
                />
                <div></div>
              </div>
              <div className="flex justify-end mb-4">
                <CustomButton btnType="primary">Save</CustomButton>
              </div>
            </form>
          </>
        </Whitespace>
      </section>
    </>
  );
};

export default OtherSettingsForm;
