import React from "react";

export default function AvailableStatus({
  type,
  status,
  color = "",
  bgColor = "",
  customCls = "",
}) {
  const availClassName = type
    ? "text-[#00933b] bg-[#cfffe2]"
    : "text-[#cf0404] bg-[#ffd6d6]";

  return (
    <p
      style={{ color, backgroundColor: bgColor }}
      className={`inline-block rounded-[15px] px-[13px] py-[7px] text-[12px] font-medium leading-[15px] text-center w-auto m-0 ${
        customCls ? customCls : availClassName
      }`}
    >
      {status}
    </p>
  );
}
