import React, { useState } from "react";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";

import { Edit } from "@mui/icons-material";

export default function LeadDetails({}) {
  const [priorityedit, setPriorityedit] = useState(false);

  return (
    <div className="">
      <ComponentTitle title="PRIMARY DETAILS" mt="5" />
      <div className=" grid grid-cols-3 gap-7.5">
        <div className="flex flex-col">
          <h2 className="flex gap-2  text-[14px] font-semibold min-h-[15px]">
            Lead Category
            <div className="border px-1 rounded-md border-[#bebebe]  h-5 flex justify-center items-center">
              <Edit sx={{ fontSize: "11px", color: "#696969" }} />
            </div>
          </h2>
          {priorityedit ? (
            <input className="border" />
          ) : (
            <p className="text-[13px]">{"Category - one"}</p>
          )}
        </div>
        <div className="flex flex-col">
          <h2 className="flex gap-2  text-[14px] font-semibold min-h-[15px]">
            Lead Category
            <div className="border px-1 rounded-md border-[#bebebe]  h-5 flex justify-center items-center">
              <Edit sx={{ fontSize: "11px", color: "#696969" }} />
            </div>
          </h2>
          {priorityedit ? (
            <input className="border" />
          ) : (
            <p className="text-[13px]">{"Category - one"}</p>
          )}
        </div>{" "}
        <div className="flex flex-col">
          <h2 className="flex gap-2 text-[14px] font-semibold min-h-[15px]">
            Lead Category
            <div
              className="border px-1 rounded-md border-[#bebebe]  h-5 flex justify-center items-center"
              onClick={() => setPriorityedit(!priorityedit)}
            >
              <Edit sx={{ fontSize: "11px", color: "#696969" }} />
            </div>
          </h2>
          {priorityedit ? (
            <input className="border" />
          ) : (
            <p className="text-[13px]">{"Category - one"}</p>
          )}
        </div>
      </div>
      <div className="mt-8 grid grid-cols-3 gap-7.5">
        <div className="flex flex-col">
          <h2 className="flex gap-2 text-[14px] font-semibold min-h-[15px]">
            Lead Category
            <div className="border px-1 rounded-md border-[#bebebe]  h-5 flex justify-center items-center">
              <Edit sx={{ fontSize: "11px", color: "#696969" }} />
            </div>
          </h2>
          {priorityedit ? (
            <input className="border" />
          ) : (
            <p className="text-[13px]">{"Category - one"}</p>
          )}
        </div>
        <div className="flex flex-col">
          <h2 className="flex gap-2 text-[14px] font-semibold min-h-[15px]">
            Lead Category
            <div className="border px-1 rounded-md border-[#bebebe]  h-5 flex justify-center items-center">
              <Edit sx={{ fontSize: "12px", color: "#696969" }} />
            </div>
          </h2>
          {priorityedit ? (
            <input className="border" />
          ) : (
            <p className="text-[13px]">{"Category - one"}</p>
          )}
        </div>{" "}
        <div className="flex flex-col">
          <h2 className="flex gap-2  text-[14px] font-semibold min-h-[15px]">
            Lead Category
            <div className="border px-1 rounded-md border-[#bebebe]  h-5 flex justify-center items-center">
              <Edit sx={{ fontSize: "11px", color: "#696969" }} />
            </div>
          </h2>
          {priorityedit ? (
            <input className="border" />
          ) : (
            <p className="text-[13px]">{"Category - one"}</p>
          )}
        </div>
      </div>

      <ComponentTitle
        title="SERVICE & BILLING INFO"
        span=" ( Edit address)"
        mt="10"
      />
      <div className=" grid grid-cols-3 gap-7.5">
        <div className="flex flex-col gap-1.5">
          <h2 className="text-[14px] font-semibold min-h-[15px]">
            Service Address
          </h2>
          <div>
            <p className="text-[13px]">{"Sample address,"}</p>
            <p className="text-[13px]">{"Sample address,"}</p>
            <p className="text-[13px]">{"Sample address,"}</p>
          </div>
        </div>

        <div className="flex flex-col gap-1.5">
          <h2 className="text-[14px] font-semibold min-h-[15px]">
            Billing Address
          </h2>
          <div>
            <p className="text-[13px] m-0">{"Sample address,"}</p>
            <p className="text-[13px]">{"sample city, sample state"}</p>
            <p className="text-[13px]">{"Sample country,"}</p>
          </div>
        </div>
      </div>

      <ComponentTitle title="Customer Location" mt="10" />
    </div>
  );
}
