import React, { useCallback, useState } from "react";
import { Add } from "@mui/icons-material";
import SubHeader from "../../../Components/Molecules/Subheader";
import CustomTable from "../../../Components/Molecules/CustomTable";
import FilterSortby from "../../../Components/Molecules/FilterSortBy";
import { useNavigate } from "react-router-dom";

const ProductGroupPage = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const onClickFilter = () => {
    handleOpen();
  };
  const navigate = useNavigate();
  const data = [
    {
      quoteID: "01",
      siNo: 2,
      questionGroup: "Group 2",
      noOfQuestions: 10,

      status: "Unavailable",
    },
  ];
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];

  return (
    <>
      <div className="">
        <SubHeader
          headings={[
            {
              name: "Products",
            },
            {
              name: "Products Group",
            },
          ]}
          actions={[
            {
              type: "button",
              name: "Group",

              onclick: () => {
                navigate("/product/productgroupform");
              },
              icon: (
                <Add
                  sx={{
                    fontSize: "18px",
                    marginRight: "5px",
                    color: "#7F7F7F",
                  }}
                />
              ),
            },
          ]}
        />
        <div>
          <FilterSortby
            exportBtn="false"
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            onClickFilter={onClickFilter}
          />
          <div className="overflow-auto">
            <CustomTable
              tableTitle={[
                "#",
                "GROUP name",
                "TOTAL ITEMS",
                "CREATED BY",
                "CREATED AT",
                "STATUS",

                "ACTIONS",
              ]}
              navigateView={() => navigate("/employee/view")}
              tableData={data}
              viewButton={true}
              editButton={true}
              deleteButton={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductGroupPage;
