import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneIcon from "@mui/icons-material/Done";
import { OpenInNew, QrCode } from "@mui/icons-material";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../CustomButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { TableIconConstant } from "../../../constants";

const TableButton = ({
  buttonType,
  size,
  statusVal = "",
  onClickBtn = (e) => {},
  disable = false,
  lableText,
  permission,
}) => {
  const renderBtn = () => {
    switch (buttonType) {
      case TableIconConstant.view:
        return <RemoveRedEyeIcon sx={{ fontSize: "18px", color: "#7F7F7F" }} />;
      case TableIconConstant.answer:
        return (
          <QuestionAnswerIcon sx={{ fontSize: "18px", color: "#7F7F7F" }} />
        );
      case TableIconConstant.edit:
        return <EditIcon sx={{ fontSize: "18px", color: "#7F7F7F" }} />;
      case TableIconConstant.addUser:
        return <PersonAddIcon sx={{ fontSize: "18px", color: "#7F7F7F" }} />;
      case TableIconConstant.status:
        return statusVal ? (
          <RemoveCircleOutlineIcon
            sx={{ fontSize: "18px", color: "#7F7F7F" }}
          />
        ) : (
          <DoneIcon sx={{ fontSize: "18px", color: "#7F7F7F" }} />
        );
      case TableIconConstant.time:
        return <AccessTimeIcon sx={{ fontSize: "18px", color: "#7F7F7F" }} />;
      case TableIconConstant.delete:
        return <DeleteIcon sx={{ fontSize: "18px", color: "#7F7F7F" }} />;
      case TableIconConstant.copy:
        return <ContentCopyIcon sx={{ fontSize: "18px", color: "#7F7F7F" }} />;
      case TableIconConstant.qrcode:
        return <QrCode sx={{ fontSize: "18px", color: "#7F7F7F" }} />;
      case TableIconConstant.barcode:
        return (
          <FormatAlignJustifyIcon
            style={{ transform: "rotate(90deg)", color: "#7F7F7F" }}
          />
        );
      case TableIconConstant.location:
        return (
          <LocationOnOutlinedIcon sx={{ fontSize: "18px", color: "#7F7F7F" }} />
        );
      case TableIconConstant.close:
        return <CloseIcon sx={{ fontSize: "18px", color: "#7F7F7F" }} />;
      case TableIconConstant.history:
        return (
          <HistoryToggleOffIcon sx={{ fontSize: "18px", color: "#7F7F7F" }} />
        );
      case TableIconConstant.add:
        return <AddIcon sx={{ fontSize: "18px", color: "#7F7F7F" }} />;
      case TableIconConstant.remove:
        return <RemoveIcon sx={{ fontSize: "18px", color: "#7F7F7F" }} />;
      case TableIconConstant.link:
        return <OpenInNew sx={{ fontSize: "18px", color: "#7F7F7F" }} />;
      case TableIconConstant.reject:
        return <ThumbDownIcon sx={{ fontSize: "18px", color: "#7F7F7F" }} />;
    }
  };

  return (
    <div permission={permission} className={`flex items-center`}>
      <CustomButton
        size={size}
        btnType="secondary"
        onClick={(e) => {
          onClickBtn(e);
        }}
        data-testid={buttonType}
        disabled={disable}
        permission={permission}
        className="p-1 h-full flex items-center"
      >
        {renderBtn()}
      </CustomButton>
    </div>
  );
};

export default TableButton;
