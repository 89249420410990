import SubHeader from "../../Components/Molecules/Subheader";
import Whitespace from "../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../Components/Atoms/ComponentTitle";
import PersonIcon from "@mui/icons-material/Person";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import DateRangeIcon from "@mui/icons-material/DateRange";
import GroupIcon from "@mui/icons-material/Group";
import { useNavigate } from "react-router-dom";

const SettingPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <SubHeader headings={[{ name: "Settings" }]} />

      <div className="w-full">
        <Whitespace className={"mt-5 px-10"}>
          <ComponentTitle title={"GENERAL SETTINGS"} mb="7" />
          <div className="flex gap-x-40 mb-8">
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/accountsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <PersonIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Account Settings
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Update your profile
                  </p>
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <CorporateFareIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Organization Settings
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Update company configs
                  </p>
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/customroles`)}
                className={`flex  gap-1 items-center  cursor-pointer transition-all ease-in-out duration-200`}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <RecentActorsIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Custom Roles
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Roles & Permissions
                  </p>
                </div>
              </li>
            </ul>{" "}
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center  cursor-pointer transition-all ease-in-out duration-200`}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <ImportExportIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Data Import
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Import your existing data
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </Whitespace>

        <Whitespace className={"mt-5 px-10"}>
          <ComponentTitle title={"Configuration settings"} mb="7" />
          <div className="flex gap-x-40 gap-y-10 mb-8 flex-wrap">
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <SupervisorAccountIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Customer Settings
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage customer
                  </p>
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/employee/customsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <SupervisorAccountIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Employee Settings
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage Employee
                  </p>
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/timesheetsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <TimelapseIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Timesheet Settings
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage timesheet configs
                  </p>
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/quoteandinvoicesettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <ReceiptIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Quotes & Invoices
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage payments, taxes, etc..,
                  </p>
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <ContentPasteIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Contracts
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage packages, billings, etc.,
                  </p>
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <ImportantDevicesIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Assets
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage asset configs
                  </p>
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <NotificationsIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Notification & Alerts
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage notification configs
                  </p>
                </div>
              </li>
            </ul>

            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/leadsettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <GroupIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">Leads</p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage Lead configs
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </Whitespace>

        <Whitespace className={"mt-5 px-10"}>
          <ComponentTitle title={"custom fields settings"} mb="7" />
          <div className="flex gap-x-40 gap-y-10 mb-8 flex-wrap">
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <SupervisorAccountIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Customer Fields
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage customer
                  </p>
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <TimelapseIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Employee Fields
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage timesheet configs
                  </p>
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <ReceiptIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Asset Fields
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage payments, taxes, etc..,
                  </p>
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <ContentPasteIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Organization Fields
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage packages, billings, etc.,
                  </p>
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <ImportantDevicesIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Quotation Fields
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage asset configs
                  </p>
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-2 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <NotificationsIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Job Checklist
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage notification configs
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </Whitespace>

        <Whitespace className={"mt-5 px-10"}>
          <ComponentTitle title={"OTHER SETTINGS"} mb="7" />
          <div className="flex gap-x-40 gap-y-10 mb-8 flex-wrap">
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                onClick={() => navigate(`/settings/holidaysettings`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <DateRangeIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Holidays
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage holidays
                  </p>
                </div>
              </li>
            </ul>
            <ul className="grid grid-cols-[repeat(auto-fill,minmax(264px,1fr))] gap-4 ml-0 list-none pl-0">
              <li
                //   onClick={() => navigate(`${each1?.url}`)}
                className={`flex  gap-1 items-center cursor-pointer transition-all ease-in-out duration-200 `}
              >
                <div className="rounded-full bg-[#efefef] text-[#696969] h-10 w-10 flex justify-center items-center mr-2">
                  <MarkEmailReadIcon />
                </div>
                <div>
                  <p className="text-sm font-bold text-[#FF8046] mb-1">
                    Email Templates
                  </p>
                  <p className="text-xs font-medium text-[##696D6E]">
                    Manage email templates
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </Whitespace>
      </div>
    </>
  );
};

export default SettingPage;
