import React, { useCallback, useState } from "react";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../../Components/Molecules/CustomTable";
import Whitespace from "../../../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../../../Components/Atoms/ComponentTitle";
import CustomButton from "../../../../Components/Atoms/CustomButton";

const QuotesAndInvoiceTemplateSettings = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const navigate = useNavigate();
  const data = [
    {
      siNo: 1,
      questionGroup: "Group 1",
      noOfQuestions: "template name",
      notifyEndDate: "leorm epsum",
    },
    {
      siNo: 2,
      questionGroup: "Group 2",
      noOfQuestions: "template name",
      notifyEndDate: "leorm epsum",
    },
  ];

  return (
    <>
      <div className="">
        <Whitespace>
          <div className="flex items-center mb-7">
            <ComponentTitle title="QUOTE & INVOICE TEMPLATES" mb="0" />
            <CustomButton size="medium" onClick={() => handleOpen()}>
              <Add sx={{ fontSize: "15px" }} />
              New&nbsp;Template{" "}
            </CustomButton>
          </div>
          <div className="overflow-auto">
            <CustomTable
              tableTitle={[
                "#",
                "TYPE",
                "TEMPLATE NAME",
                "TEMPLATE description",
                "ACTIONS",
              ]}
              navigateView={() => navigate("/employee/view")}
              tableData={data}
              viewButton={true}
              editButton={true}
              deleteButton={false}
            />
          </div>
        </Whitespace>
      </div>
    </>
  );
};

export default QuotesAndInvoiceTemplateSettings;
