import React from "react";
import EmptyImage from "../../../../Assets/EmptyImage.svg";
import Whitespace from "../../../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../../../Components/Atoms/ComponentTitle";
import CustomButton from "../../../../Components/Atoms/CustomButton";
import { useState } from "react";
import { useCallback } from "react";
import { Add } from "@mui/icons-material";
import CustomDialog from "../../../../Components/Molecules/CustomDialog";
import ProductCategoryForm from "./productCategoryForm";

function ProductCategory() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);
  return (
    <>
      <CustomDialog
        width="600px"
        minWidth="750px"
        Title={"Create New Product Category"}
        open={open}
        handleClose={handleClose}
      >
        <ProductCategoryForm />
      </CustomDialog>
      <Whitespace>
        <div className="flex items-center mb-7">
          <ComponentTitle title="product CATEGORIES" mb="0" />
          <CustomButton size="medium" onClick={() => handleOpen()}>
            <Add sx={{ fontSize: "15px" }} />
            New&nbsp;Category
          </CustomButton>
        </div>
        <table className="min-w-full bg-white">
          <thead>
            <tr className="border">
              <th className="py-3 px-4 text-left text-[#140C00] text-[14px] font-bold">
                #
              </th>
              <th className="py-3 px-4 text-left text-[#140C00] text-[14px] font-bold">
                CATEGORY NAME
              </th>
              <th className="py-3 px-4 text-left text-[#140C00] text-[14px] font-bold">
                DESCRIPTION
              </th>
              <th className="py-3 px-4 text-left text-[#140C00] text-[14px] font-bold">
                ACTIONS{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border">
              <td className="py-2 px-4 text-[#140C00] text-[14px] font-medium">
                18
              </td>

              <td className="py-2 px-4 text-[#140C00] text-[14px] font-medium   cursor-pointer flex items-center gap-8 rounded">
                <img className="w-16 h-16" src={EmptyImage} />
                <p>Civil</p>
              </td>
              <td className="py-2 px-4 text-[#140C00] text-[14px] font-medium">
                New
              </td>
              <td className="py-2 px-4 text-[#140C00] text-[14px] font-medium">
                18 %
              </td>
            </tr>
          </tbody>
        </table>
      </Whitespace>
    </>
  );
}

export default ProductCategory;
