import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import DoneIcon from "@mui/icons-material/Done";

import TableButton from "../../Atoms/TableButton";
import { TableIconConstant } from "../../../constants";
import AvailableStatus from "../../Atoms/AvailableStatus";

const CustomTable = ({
  tableTitle,
  permission,
  navigateView,
  navigateEdit,
  loading,
  toggleStatus,
  deleteItem,
  tableData,
  viewButton,
  editButton,
  deleteButton,
  statusButton,
  enableDeleteLastIndex = false,
  add,
  show,
  edit,
  del,
}) => {
  const renderTd = (each, statusClass) => {
    const tdList = [];
    for (let key in each) {
      if (key === "_id") {
        continue;
      }
      const textColorClass =
        key === "quoteID" ? "text-primColor" : "text-[#002e25]";
      if (key === "status") {
        tdList.push(
          <td className="text-[13px] font-medium leading-4 tracking-normal text-left text-[#474747] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
            <AvailableStatus
              status={each[key]}
              type={each.status === "Active" || false}
            />
          </td>
        );
      } else {
        tdList.push(
          <td
            className={`text-sm font-medium leading-4 tracking-normal text-left py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis ${textColorClass}`}
          >
            {each[key]}
          </td>
        );
      }
    }
    return tdList;
  };

  return (
    <table className="bg-[#ffffff] border border-gray-300 border-collapse w-full">
      <thead>
        <tr className="border-b border-gray-300">
          {tableTitle.map((title, index) => (
            <th
              key={index}
              className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase"
            >
              {title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData?.length > 0 ? (
          tableData.map((each, index) => {
            const isDeleteButtonVisible = enableDeleteLastIndex
              ? tableData.length - 1 === index
              : deleteButton;
            const statusClass =
              each.status === "Inactive" ? "status-red" : "status-green";

            return (
              <tr key={each._id} className="border-b border-gray-300">
                {renderTd(each, statusClass)}
                <td className="text-[13px] font-medium leading-4 tracking-normal text-left text-[#474747] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                  <div className="flex gap-2">
                    {viewButton && (
                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.view}
                        onClickBtn={navigateView}
                      />
                    )}
                    {editButton && (
                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.edit}
                        onClickBtn={navigateEdit}
                      />
                    )}
                    {statusButton && (
                      <button onClick={() => toggleStatus(each._id)}>
                        {each.status === "Inactive" ? (
                          <DoneIcon sx={{ fontSize: "14px" }} />
                        ) : (
                          <RemoveCircleOutlineIcon sx={{ fontSize: "14px" }} />
                        )}
                      </button>
                    )}
                    {isDeleteButtonVisible && (
                      // <button onClick={() => deleteItem(each?._id)}>
                      //   <DeleteIcon sx={{ fontSize: "14px" }} />
                      // </button>
                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.delete}
                      />
                    )}
                  </div>
                </td>
              </tr>
            );
          })
        ) : !loading ? (
          <tr>
            <td colSpan={tableTitle.length} className="text-center py-4">
              No data available
            </td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
};

export default CustomTable;
