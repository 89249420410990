import React from "react";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";

const Preview = () => {
  return (
    <>
      <Whitespace mt="23px" className={"px-14 py-7 w-full"}>
        <ComponentTitle title="PREVIEW & SAVE Lead" />
        <p className="text-[#8F8F8F] text-[16px] font-medium mb-4">
          Primary Details
        </p>
        <div className="columnFour">
          <div>
            <ComponentTitle title="Lead Title" mb="0" />
            <p className="text-[12px]">Lead title</p>
          </div>
          <div>
            <ComponentTitle title="Lead Category" mb="0" />
            <p className="text-[12px]">new category</p>
          </div>
          <div>
            <ComponentTitle title="Lead Priority" mb="0" />
            <p className="text-[12px]">priority 1</p>
          </div>
          <div>
            <ComponentTitle title="Lead Type" mb="0" />
            <p className="text-[12px]">new type</p>
          </div>
        </div>

        <p className="text-[#8F8F8F] text-[16px] font-medium mb-4 mt-8">
          Primary Details
        </p>
        <div className="columnFour">
          <div>
            <ComponentTitle title="Customer Summary" mb="0" />
            <p className="text-[12px]">Name</p>
            <p className="text-[12px]">Address</p>
            <p className="text-[12px]">Mobile / contact</p>
          </div>
          <div>
            <ComponentTitle title="Service Contact Info" mb="0" />
            <p className="text-[12px]">Name</p>
            <p className="text-[12px]">Address</p>
            <p className="text-[12px]">Mobile / contact</p>
          </div>
          <div>
            <ComponentTitle title="Billing Contact Info" mb="0" />
            <p className="text-[12px]">Name</p>
            <p className="text-[12px]">Address</p>
            <p className="text-[12px]">Mobile / contact</p>
          </div>
          <div></div>
        </div>

        <p className="text-[#8F8F8F] text-[16px] font-medium mb-4 mt-8">
          Other Details
        </p>
        <div className="columnFour">
          <div>
            <ComponentTitle title="Lead Id" mb="0" />
            <p className="text-[12px]">123455</p>
          </div>
          <div>
            <ComponentTitle title="Assigned User/Team" mb="0" />
            <p className="text-[12px]">Pavithraa</p>
          </div>
          <div>
            <ComponentTitle title="Lead Description" mb="0" />
            <p className="text-[12px]">The description</p>
          </div>
          <div>
            <ComponentTitle title="Lead Attachments" className="mb-0" />
            <p className="text-[12px]">Sample Attachments</p>
          </div>
        </div>
      </Whitespace>
    </>
  );
};

export default Preview;
