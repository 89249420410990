import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SubHeader from "../../Components/Molecules/Subheader";
import FilterSortby from "../../Components/Molecules/FilterSortBy";
import TableButton from "../../Components/Atoms/TableButton";
import { TableIconConstant } from "../../constants";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import AvailableStatus from "../../Components/Atoms/AvailableStatus";

const CustomerPage = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];

  return (
    <>
      <div className="">
        <SubHeader
          headings={[
            {
              name: "Customers",
            },
          ]}
          actions={[
            {
              name: "New Customer",
              onclick: () => {
                navigate("/customer/customerform");
              },
              icon: <AddIcon sx={{ fontSize: "small" }} />,
              type: "button",
            },
          ]}
        />
        <>
          <FilterSortby
            exportBtn="false"
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            onClickFilter={() => {}}
          />
          <div className="overflow-auto">
            <table className="bg-[#ffffff] border border-gray-300 border-collapse w-full">
              <tbody>
                <tr className="border-b border-gray-300">
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"NAME"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"HOME / MOBILE"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"NUMBER OF JOBS"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"category"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"Tags"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"status"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"created on"}
                  </th>

                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"actions"}
                  </th>
                </tr>

                <tr className="border-b border-gray-300">
                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <div className="">
                      <p className="text-[13px] font-bold">Sample customer</p>
                      <p className="text-[12px] text-[#140C00] opacity-80 font-medium -mb-2">
                        12, Sample street, Sample city,
                      </p>
                      <p className="text-[12px] text-[#140C00] opacity-80 font-medium">
                        sample state, sample country - 12345
                      </p>
                      <p className="text-primColor text-[12px] m-0 font-medium">
                        customer@new.com
                      </p>
                    </div>
                  </td>
                  <td className="text-[12px] font-bold  tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7">
                    9876543210 / 9876543210
                  </td>
                  <td className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    140
                  </td>
                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    Web development
                  </td>
                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <button className="bg-primColor px-3 py-[6px] rounded text-white text-[12px]">
                      Tag 1
                    </button>
                  </td>

                  <td className="text-sm font-medium leading-4 tracking-normal text-left  py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <AvailableStatus status={"Active"} type={"Active"} />
                  </td>

                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    24/24/24
                  </td>

                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <div className="flex gap-2">
                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.edit}
                      />
                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.view}
                        // onClickBtn={() => navigate("/lead/leadview")}
                      />
                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.status}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      </div>{" "}
    </>
  );
};

export default CustomerPage;
