import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SubHeader from "../../Components/Molecules/Subheader";
import FilterSortby from "../../Components/Molecules/FilterSortBy";
import TableButton from "../../Components/Atoms/TableButton";
import { TableIconConstant } from "../../constants";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";

const LeadPage = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];

  return (
    <>
      <div className="main-container">
        <SubHeader
          headings={[
            {
              name: "Leads",
            },
          ]}
          actions={[
            {
              name: "Remaining Leads",

              type: "button",
            },
            {
              name: "New Lead",
              onclick: () => {
                navigate("/lead/leadform");
              },
              icon: (
                <AddIcon
                  className="employee-add-icon"
                  sx={{ fontSize: "small" }}
                />
              ),
              type: "button",
            },
          ]}
        />
        <>
          <FilterSortby
            exportBtn="false"
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            onClickFilter={() => {}}
          />
          <div className="overflow-auto">
            <table className="bg-[#ffffff] border border-gray-300 border-collapse w-full">
              <tbody>
                <tr className="border-b border-gray-300">
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"id"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"LeAD  title"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"customer"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"users / team assigned"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"category"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"status"}
                  </th>
                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"Priority"}
                  </th>

                  <th className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"actions"}
                  </th>
                </tr>

                <tr className="border-b border-gray-300">
                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    123
                  </td>
                  <td className="text-[11px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    <div className="">
                      <p className="text-[13px] font-bold">Title Lead WF</p>
                      <p className="text-primColor text-[11px] font-medium">
                        2025/07/16 04:00 2027/01/19 08:35
                      </p>
                      <p className="text-[9px] text-[#140C00] opacity-80 font-medium">
                        Created At 2023/07/28 02:58
                      </p>
                    </div>
                  </td>
                  <td className="text-[10px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    <div className="">
                      <p className="text-[11px] font-bold">User One</p>
                      <div className="flex items-center gap-1">
                        <LocalPhoneIcon sx={{ fontSize: "12px" }} />
                        <p className="text-[9px] text-[#140C00] opacity-80 font-medium">
                          Created At 2023/07/28 02:58
                        </p>
                      </div>
                      <h1 className="text-[9px] text-[#140C00] font-medium mt-1">
                        Address line 1
                      </h1>
                      <h1 className="text-[9px] text-[#140C00] font-medium mt-1">
                        Address line 1
                      </h1>
                      <h1 className="text-[9px] text-[#140C00] font-medium mt-1">
                        city, state, country
                      </h1>
                    </div>
                  </td>
                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <div className="flex items-center gap-1">
                      <AccountCircleIcon
                        sx={{ fontSize: "44px" }}
                        className="bg-white text-gray-300 w-14 h-14 rounded-full overflow-hidden"
                      />

                      <div>
                        <h1>pavithra</h1>
                        <p className="text-[12px] text-[#140C00] opacity-80 font-medium">
                          Business development
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    Business development
                  </td>

                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#00933B] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    New
                  </td>

                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#0071B2] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <button className="bg-[#DCF2FF] py-2 px-4 rounded-md">
                      Medium
                    </button>
                  </td>

                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <div className="flex gap-2">
                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.edit}
                      />
                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.view}
                        onClickBtn={() => navigate("/lead/leadview")}
                      />
                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.status}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      </div>{" "}
    </>
  );
};

export default LeadPage;
