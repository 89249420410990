import React from "react";
import CustomInput from "../../../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../../../Components/Atoms/CustomCancelSubmit";
import TextArea from "../../../../Components/Atoms/TextArea";

const LeadCategoryForm = () => {
  return (
    <>
      <div className="">
        <div className="columnTwo">
          <CustomInput
            margin="true"
            name="User"
            labelText="Category Name (Max 26 Characters)"
            placeholder="Eg: Sprint Document"
            type="text"
          />
          <CustomInput
            margin="true"
            name="lastname"
            labelText="Category Color"
            type="text"
          />
        </div>

        <TextArea
          margin="true"
          labelText="Category Description"
          name="lastname"
          placeholder="Enter Description"
          type="date"
        />
        <div className="flex justify-between mt-10">
          <div className="flex items-center gap-2">
            <input className="h-4 w-4" type="checkbox"></input>
            Auto Create Lead Status
          </div>
          <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
        </div>
      </div>
    </>
  );
};

export default LeadCategoryForm;
