import React from "react";
import CustomInput from "../../../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../../../Components/Atoms/CustomCancelSubmit";
import ToggleButton from "../../../../Components/Atoms/ToggleButton";

const TaxGroupForm = () => {
  return (
    <>
      <div className="mx-auto ">
        <div>
          <CustomInput
            labelText={"Tax Group Name"}
            placeholder={"Tax Group Name"}
          />
        </div>
        <div className="mb-3 mt-2">
          <p className="font-semibold">Taxes</p>
        </div>
        <table className="min-w-full bg-white">
          <thead>
            <tr className="">
              <th className="py-2 px-4 text-left text-[#140C00] text-[14px] font-bold">
                Tax Name
              </th>
              <th className="py-2 px-4 text-left text-[#140C00] text-[14px] font-bold">
                Percentage
              </th>
              <th className="py-2 px-4 text-left text-[#140C00] text-[14px] font-bold"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="border">
              <td className="py-3 px-4 text-[#140C00] text-[14px] font-medium">
                CGST
              </td>
              <td className="py-2 px-4 text-[#140C00] text-[14px] font-medium">
                18 %
              </td>
              <td className="py-2 px-4 text-right">
                <ToggleButton name="notes.is_private" value={""} />
              </td>
            </tr>
          </tbody>
        </table>

        <div className="mt-8">
          <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
        </div>
      </div>
    </>
  );
};

export default TaxGroupForm;
