import TodayIcon from "@mui/icons-material/Today";
import * as React from "react";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import CustomTable from "../../../Components/Molecules/CustomTable";
import SubHeader from "../../../Components/Molecules/Subheader";

const TimesheetApprovalView = (props) => {
  return (
    <>
      <SubHeader
        headings={[
          {
            name: "TimeSheet",
          },
          {
            name: "Timesheet Approvals",
          },
          {
            name: "Approval Request - Pavi",
          },
        ]}
        actions={[
          {
            type: "button",
            btnType: "submit",
            name: "Recalculate",
          },
        ]}
      />
      <div className="flex gap-4 overflow-hidden mt-2">
        <div className="w-[365px] h-full sticky top-0">
          <div className="p-5 bg-white border mb-3 -gray-300 rounded-sm">
            <div className="flex justify-between">
              <ComponentTitle title="USER DETAILS" className="mb-0" />
              <h1 className="text-xs font-semibold text-primColor cursor-pointer transition-all duration-200 hover:font-bold whitespace-nowrap">
                View Details
              </h1>
            </div>
            <div className="flex items-center gap-1 mt-3">
              <div className="flex justify-center items-center mr-2">
                <div className="w-12 h-12 flex justify-center items-center rounded-full bg-gray-300 text-lg font-medium">
                  K
                </div>
              </div>
              <div>
                <p className="text-sm font-semibold text-teal-900">Pavi</p>
                <p className="text-xs font-normal text-teal-900">Developer</p>
                <p className="text-xs font-normal text-teal-900">
                  email@gmail.com
                </p>
              </div>
            </div>
          </div>
          <div className="p-5 bg-white border mb-3 -gray-300 rounded-sm">
            <div className="flex justify-between">
              <ComponentTitle title="APPROVAL PERIOD" />
            </div>
            <div className="flex justify-between items-center">
              <p className="text-sm">
                <span>Tuesday 24/01/2022</span>
                <p>to</p>
                <span>Tuesday 24/2022/2</span>
              </p>
              <TodayIcon className="text-[32px] opacity-65 text-[#767070]" />
            </div>
          </div>
          <div className="p-5 bg-white border -gray-300 rounded-sm">
            <div className="flex justify-between">
              <ComponentTitle title="APPROVAL HIERARCHY" className="mb-0" />
            </div>
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <div className="w-9 h-9 flex justify-center items-center rounded-full bg-gray-300 text-lg font-medium">
                  K
                </div>
                <div>
                  <p className="text-sm font-semibold">Pavithra</p>
                  <p className="text-xs  text-primColor">Pending</p>
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center gap-2">
                <div className="w-9 h-9 flex justify-center items-center rounded-full bg-gray-300 text-lg font-medium">
                  K
                </div>
                <div>
                  <p className="text-sm font-semibold">Pavithra</p>
                  <p className="text-xs  text-primColor">Pending</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-5/6">
          <Whitespace className={"overflow-hidden"}>
            <div className="relative">
              <div className="absolute right-[-3rem] rotate-[36deg] transform  w-32 h-5 bg-[#DB7618] flex items-center justify-center z-[2]">
                <p className="text-xs text-white">Pending</p>
              </div>
            </div>
            <div className="grid  grid-cols-3 gap-6">
              <div className="grid border grid-cols-3 gap-4 mb-2">
                <div className="p-6 min-w-44">
                  <p className="text-sm font-semibold min-w-44 mb-2">
                    Total Work Hours
                  </p>
                  <p className="text-xl font-bold">2h 3m</p>
                </div>
              </div>
              <div className="grid border grid-cols-3 gap-4 mb-2">
                <div className="p-6 min-w-44">
                  <p className="text-sm font-semibold min-w-44 mb-2">
                    Shift Hours
                  </p>
                  <p className="text-xl font-bold">19h</p>
                </div>
              </div>
              <div className="grid border grid-cols-3 gap-4 mb-2">
                <div className="p-6 min-w-44">
                  <p className="text-sm font-semibold min-w-44 mb-2">
                    Work Hours
                  </p>
                  <p className="text-xl font-bold">2h 3m</p>
                </div>
              </div>
            </div>
            <div className="grid  grid-cols-3 gap-6 mt-4">
              <div className="grid border grid-cols-3 gap-4 mb-2">
                <div className="p-6 min-w-44">
                  <p className="text-sm font-semibold min-w-44 mb-2">
                    Break Hours
                  </p>
                  <p className="text-xl font-bold">2</p>
                </div>
              </div>
              <div className="grid border grid-cols-3 gap-4 mb-2">
                <div className="p-6 min-w-44">
                  <p className="text-sm font-semibold min-w-44 mb-2">
                    Overtime Hrs
                  </p>
                  <p className="text-xl font-bold">41h 24m</p>
                </div>
              </div>
              <div className="grid border grid-cols-3 gap-4 mb-2">
                <div className="p-6 min-w-44">
                  <p className="text-sm font-semibold min-w-44 mb-2">
                    Time-off Hrs
                  </p>
                  <p className="text-xl font-bold">92h 30m</p>
                </div>
              </div>
            </div>
            <div className="grid  grid-cols-3 gap-6 mt-4">
              <div className="grid border grid-cols-3 gap-4 mb-2">
                <div className="p-6 min-w-44">
                  <p className="text-sm font-semibold  mb-2">Payable Amount</p>
                  <p className="text-xl font-bold">₹ 0.00</p>
                </div>
              </div>
            </div>
            <div className="mt-7">
              <ComponentTitle title="TIMESHEET HISTORY" className="mb-7" />
            </div>

            <div className="overflow-auto">
              <CustomTable
                tableTitle={["date", "day", "punch in", "punch out", "ACTIONS"]}
                viewButton={true}
                editButton={true}
                deleteButton={false}
              />
            </div>
          </Whitespace>
        </div>
      </div>
    </>
  );
};

export default TimesheetApprovalView;
