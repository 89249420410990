import React from "react";
import CustomInput from "../../../../Components/Atoms/CustomInput";
import CustomSelect from "../../../../Components/Atoms/CustomSelect";

import CustomCancelSubmit from "../../../../Components/Atoms/CustomCancelSubmit";

const HolidayForm = () => {
  return (
    <>
      <div className="">
        <div className="columnTwo">
          <CustomInput
            margin="true"
            name="User"
            labelText="Holiday Name?"
            placeholder={"Eg: VAT"}
            type="text"
          />

          <CustomInput
            margin="true"
            name="lastname"
            labelText="Holiday Date"
            placeholder={"Eg: 2%"}
            type="date"
          />
        </div>{" "}
        <div className="columnTwo mb-4">
          <CustomSelect
            margin="true"
            name="User"
            labelText="Allow jobs to be assigned on this day?"
            placeholder={"Choose an option"}
            type="text"
          />
          <CustomSelect
            margin="true"
            name="User"
            labelText="Is this a holiday for all the users?"
            placeholder={"Choose an option"}
            type="text"
          />
        </div>
        <CustomCancelSubmit cancelLabel="Close" submitLabel="Create" />
      </div>
    </>
  );
};

export default HolidayForm;
