import React, { useEffect, useState } from "react";
import SettingsTabsList from "../../../Components/Molecules/SettingsTabsList";
import SubHeader from "../../../Components/Molecules/Subheader";
import removear from "../../../Utils/RemoveArrayEle";
import TaxSettings from "./TaxSettings";
import PaymentModesSettings from "./PaymentModes";
import QuotesAndInvoiceTemplateSettings from "./QuoteAndInvoiceTemplate";
import FollowUpRemindersSettings from "./FollowUpReminders";

function QuoteAndInvoiceSettings() {
  const [page, setPage] = useState("Job Category");
  const list = [
    "Tax Settings",
    "Payment Modes",
    "Quote & Invoice Templates",
    "Follow-up Reminders",
  ];
  const [tablist, setTablist] = useState([]);
  useEffect(() => {
    let res = removear(list, null);
    setPage(res?.[0]);
    setTablist(res);
  }, []);

  const renderPage = () => {
    switch (page) {
      case "Tax Settings":
        return <TaxSettings />;
      case "Payment Modes":
        return <PaymentModesSettings />;
      case "Payment Terms":
        return "name";
      case "Quote & Invoice Templates":
        return <QuotesAndInvoiceTemplateSettings />;
      case "Follow-up Reminders":
        return <FollowUpRemindersSettings />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="">
        <SubHeader
          headings={[
            {
              name: "settings",
              url: "/setting",
            },
            {
              name: " Quotes & Invoice",
            },
          ]}
        />

        <div className="flex gap-8 mt-5">
          <section className="flex-[2]">
            <SettingsTabsList
              list={tablist}
              current={page}
              title={"configurations"}
              page={setPage}
            />
          </section>

          <section className=" flex flex-col flex-[8]">{renderPage()}</section>
        </div>
      </div>
    </>
  );
}
export default QuoteAndInvoiceSettings;
