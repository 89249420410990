import React from "react";
import CustomInput from "../../../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../../../Components/Atoms/CustomCancelSubmit";
import TextArea from "../../../../Components/Atoms/TextArea";
import CustomSelect from "../../../../Components/Atoms/CustomSelect";

const PaymentModesForm = () => {
  return (
    <>
      <div className="">
        <div className="columnTwo">
          <CustomInput
            margin="true"
            name="User"
            labelText="Payment Mode Name"
            placeholder="Eg: Cash"
            type="text"
          />
          <CustomSelect
            margin="true"
            name="lastname"
            labelText="Type"
            type="text"
            placeholder={"Pick a type"}
          />
        </div>

        <TextArea
          margin="true"
          labelText="Description"
          name="lastname"
          placeholder="Eg: cash to be recieved on completion"
          type="date"
        />
        <div className="mt-10">
          <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
        </div>
      </div>
    </>
  );
};

export default PaymentModesForm;
