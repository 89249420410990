import React from "react";
import SubHeader from "../../Components/Molecules/Subheader";
import FileInput from "../../Components/Atoms/FileInput";
import EmptyImage from "../../Assets/EmptyImage.svg";
import ComponentTitle from "../../Components/Atoms/ComponentTitle";
import Whitespace from "../../Components/Atoms/WhiteSpace";
import CustomInput from "../../Components/Atoms/CustomInput";
import TextArea from "../../Components/Atoms/TextArea";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CustomSelect from "../../Components/Atoms/CustomSelect";
import InputLable from "../../Components/Atoms/InputLable";

export default function ProductForm() {
  return (
    <>
      <div className="main-container">
        <SubHeader
          headings={[
            {
              name: "Product",
              url: "/product",
            },
            {
              name: "New Product",
            },
          ]}
          actions={[
            {
              name: "Save",

              btnType: "submit",
              type: "button",
            },
          ]}
        />

        <div className="flex gap-4 mt-2">
          <Whitespace className="w-[23%] h-full overflow-hidden">
            <div className="bg-white rounded-lg border">
              <div className="flex justify-center  items-center">
                <img
                  className="w-full h-full"
                  alt="preview image"
                  src={EmptyImage}
                />
              </div>
            </div>
            <div className="mt-3">
              <FileInput
                id="multiple-file-inp"
                type="file"
                accept=".jpg,.jpeg,.png"
                multiple
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              />
              <p className="text-xs font-light text-red-600 mt-2 mb-2">
                Formats: jpg, .jpeg, .pngs
              </p>
            </div>
          </Whitespace>

          <Whitespace className="w-[77%]">
            <div className="flex justify-between items-center">
              <div>
                <ComponentTitle title="PRODUCT INFORMATION" />
              </div>

              <div className="mt-2">
                <button className="bg-white text-green-900 font-medium text-xs px-4 py-2 rounded-l border border-gray-300">
                  Available
                </button>
                <button className="bg-primColor text-white font-medium text-xs px-4 py-2 rounded-r border border-gray-300">
                  Unavailable
                </button>
              </div>
            </div>
            <div className="columnTwo">
              <CustomInput labelText={"Choose Type"} placeholder={"Part"} />
              <CustomInput
                labelText={"Prefix"}
                placeholder={"Enter Prefix"}
              />{" "}
              <CustomInput
                labelText={"Part Number"}
                placeholder={"Eg: ABC002"}
              />
              <CustomInput labelText={"Part Name"} placeholder={"Enter Name"} />
              <CustomInput
                labelText={"Part Category"}
                placeholder={"Pick An Option"}
              />
              <CustomInput
                labelText={"Brand"}
                placeholder={"Enter Brand Name"}
              />
              <CustomInput
                labelText={"Specification"}
                placeholder={"Enter Specifications, if any"}
              />
              <CustomInput
                labelText={"Unit Of Measurement"}
                placeholder={"Enter Units Of Measurements"}
              />
              <CustomInput
                labelText={"Available Quantity"}
                placeholder={"Enter Quantity"}
              />
              <CustomInput
                labelText={"Minimum Quantity"}
                placeholder={"Enter Quantity"}
              />
              <div className="w-full">
                <InputLable labelText={"Unit Selling Price"} />

                <div className="flex flex-row items-center space-x-0 w-full mt-[5px]">
                  <div className="w-[15%]">
                    <CustomSelect customCls="mb-0 rounded-r-none" />
                  </div>
                  <div className="w-full">
                    <CustomInput customCls="rounded-l-none" />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <InputLable labelText={"Unit Selling Price"} />

                <div className="flex flex-row items-center space-x-0 w-full mt-[5px]">
                  <div className="w-[15%]">
                    <CustomSelect customCls="mb-0 rounded-r-none" />
                  </div>
                  <div className="w-full">
                    <CustomInput customCls="rounded-l-none" />
                  </div>
                </div>
              </div>
              <CustomInput
                labelText={"Brand"}
                placeholder={"Enter Brand Name"}
              />
              <CustomInput
                labelText={"Track Quantity"}
                placeholder={"Pick an option"}
              />
              <CustomInput
                labelText={"Has Custom Tax?"}
                placeholder={"Pick an option"}
              />
            </div>
            <TextArea
              labelText={"Part Description"}
              placeholder={"Enter Description"}
            />
            <div>
              <ComponentTitle title="OTHER DETAILS" />

              <div className="columnThree">
                <CustomInput
                  labelText={"Serial Number"}
                  placeholder={"Enter Number"}
                />
                <CustomInput
                  labelText={"Asset Ref Number"}
                  placeholder={"Sample  Placeholder"}
                />
                <CustomInput
                  labelText={"Sample Label"}
                  placeholder={"Sample Placeholder"}
                />
              </div>
            </div>
            <div>
              <ComponentTitle title="Test group" />
              <div className="columnThree">
                <CustomInput
                  labelText={"Serial Number"}
                  placeholder={"Enter Number"}
                />
                <CustomInput
                  labelText={"Asset Ref Number"}
                  placeholder={"Sample  Placeholder"}
                />
                <CustomInput
                  labelText={"Sample Label"}
                  placeholder={"Sample Placeholder"}
                />
              </div>
            </div>
            <div>
              <ComponentTitle
                title="AVAILABILITY BY LOCATION"
                className="mb-0 mt-3"
              />
            </div>
            <div className="flex gap-1 items-center">
              <div className="mr-3 w-full">
                <CustomSelect
                  name="applicable_to_state"
                  labelText={"Location"}
                  placeholder={"Choose Location"}
                />
              </div>
              <div className="mr-3 w-full">
                <CustomSelect
                  placeholder="Eg: 10"
                  showOption="name"
                  labelText="Available Qty"
                />
              </div>
              <CustomSelect
                placeholder="Eg: 2"
                showOption="name"
                labelText="Min Qty"
              />
              <div className="flex flex-col items-center mt-2 gap-1">
                <AddCircleIcon
                  sx={{ fontSize: "20px" }}
                  className="text-[12px] font-normal leading-[15px] tracking-normal text-left text-[#009e90]"
                />
                <CancelIcon
                  sx={{ fontSize: "20px" }}
                  className="text-[#c20c0c]"
                />
              </div>
            </div>
          </Whitespace>
        </div>
      </div>
    </>
  );
}
