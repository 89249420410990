import React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default function ComponentTitle(props) {
  return (
    <div
      onClick={props.onClick}
      className={`flex items-center justify-between w-full  ${
        props.onClick ? "cursor-pointer" : ""
      } ${props.mt ? `mt-${props.mt}` : ""} ${
        props.mb ? `mb-${props.mb}` : "mb-5"
      } ${props.className ?? ""}`}
      permission={props.permission}
    >
      {props.accordion ? (
        <div style={props.style || { display: "flex", alignItems: "center" }}>
          {props.children}
          <h4 className={`text-xs uppercase font-bold m-0 text-${props.class}`}>
            {props?.title}
            {props?.span && (
              <span className="text-primColor">{props.span}</span>
            )}
          </h4>
        </div>
      ) : (
        <>
          <h4 className="text-xs uppercase font-bold m-0 text-current">
            {props?.title}
            {props?.span && (
              <span className="text-primColor">{props.span}</span>
            )}
          </h4>
          {props.children}
        </>
      )}

      {props?.addbutton && (
        <AddCircleIcon
          onClick={props.onClick}
          sx={{ fontSize: "20px" }}
          className="text-black-700"
        />
      )}
    </div>
  );
}
