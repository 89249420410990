import React, { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ComponentTitle from "../../Components/Atoms/ComponentTitle";
import TableButton from "../../Components/Atoms/TableButton";
import Whitespace from "../../Components/Atoms/WhiteSpace";
import { TableIconConstant } from "../../constants";
import CustomTable from "../../Components/Molecules/CustomTable";
import FilterSortby from "../../Components/Molecules/FilterSortBy";
import SubHeader from "../../Components/Molecules/Subheader";
import { Edit } from "@mui/icons-material";

const EmployeeViewPage = () => {
  const [currentmenu, setCurrentmenu] = useState("Current Jobs");
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];

  return (
    <>
      <SubHeader
        headings={[
          {
            name: "Employees",
          },
          {
            name: "View Employees",
          },
        ]}
        actions={[
          {
            type: "button",
            name: "New Employee",

            icon: (
              <Edit
                sx={{
                  fontSize: "18px",
                  marginRight: "5px",
                  color: "#7F7F7F",
                }}
              />
            ),
          },
        ]}
      />
      <div className="flex gap-10 mt-5">
        <Whitespace mb="10px" pd="0px">
          <div className="">
            <div className="flex gap-4 border-b pl-4 pt-4 pb-4 pr-40 items-center">
              <AccountCircleIcon
                sx={{ fontSize: "60px" }}
                className="bg-white text-gray-300  w-14 h-14 rounded-full overflow-hidden"
              />
              <div className="">
                <p className="tableColor font-semibold text-[16px]">Pavi</p>
                <p className="tableColor text-[11px]">100 - FE</p>
              </div>
            </div>
            <div className="pl-4 pt-3 pb-2 border-b">
              <p className="tableColor font-medium  text-[13px]">Role</p>
              <p className="tableColor font-medium  text-[15px]">FE</p>
            </div>
            <div className="pl-4 pt-3 pb-2 border-b">
              <p className="tableColor  font-medium text-[13px]">Email</p>
              <p className="tableColor font-medium  text-[15px]">pavi.com</p>
            </div>
            <div className="pl-4 pt-3 pb-2 border-b">
              <p className="tableColor  font-medium text-[13px]">Contact No.</p>
              <p className="tableColor font-medium  text-[15px]">
                +91 9876543210
              </p>
            </div>{" "}
            <div className="pl-4 pt-3 pb-2 border-b">
              <p className="tableColor  font-medium text-[13px]">
                Hourly Labour Charge
              </p>
              <p className="tableColor font-medium  text-[15px]">Rs. 300</p>
            </div>{" "}
            <div className="pl-4 pt-3 pb-2 border-b">
              <p className="tableColor  font-medium text-[13px]">
                Joining Date
              </p>
              <p className="tableColor font-medium  text-[15px]">5 /5 /2022</p>
            </div>
          </div>
        </Whitespace>
        <div className="bg-white p-2.5 outline-none flex-grow border border-gray-300 rounded h-fit">
          <ul className="flex justify-between bg-gray-200 p-0.5 list-none border border-gray-300 my-0">
            <li
              className={`w-1/3 flex justify-center items-center cursor-pointer text-xs font-semibold leading-[15px] text-[#002e25] m-0 p-2.5 transition-all duration-200 ${
                currentmenu === "Current Jobs"
                  ? "bg-white rounded shadow-sm"
                  : ""
              }`}
              onClick={() => setCurrentmenu("Current Jobs")}
            >
              Current Jobs
            </li>
            <li
              className={`w-1/3 flex justify-center items-center cursor-pointer text-xs font-semibold leading-[15px] text-[#002e25] m-0 p-2.5 transition-all duration-200 ${
                currentmenu === "Work Hours" ? "bg-white rounded shadow-sm" : ""
              }`}
              onClick={() => setCurrentmenu("Work Hours")}
            >
              Work Hours
            </li>
            <li
              className={`w-1/3 flex justify-center items-center cursor-pointer text-xs font-semibold leading-[15px] text-[#002e25] m-0 p-2.5 transition-all duration-200 ${
                currentmenu === "Document" ? "bg-white rounded shadow-sm" : ""
              }`}
              onClick={() => setCurrentmenu("Document")}
            >
              Document
            </li>
          </ul>
          {currentmenu === "Current Jobs" ? (
            <>
              <FilterSortby
                exportBtn="false"
                totalPage={"3"}
                getPageNumber={setPage}
                getQuickFilter={setQuickFilter}
                getSortByValue={setSortBy}
                sortList={sortList}
                roleList={[
                  { name: "All Roles", _id: "All Roles", disabled: true },
                ]}
                onClickFilter={() => {}}
              />
              <CustomTable
                tableTitle={["JOB ID", "JOB TITLE", "STATUS"]}
                viewButton={true}
                editButton={true}
                deleteButton={true}
              />
            </>
          ) : null}
        </div>

        <div>
          <Whitespace mb="10px">
            <div className="flex justify-between mb-3">
              <ComponentTitle title="job location" mb="0" />
              <TableButton size="small" buttonType={TableIconConstant.edit} />
            </div>
            <div className="pr-20">
              <p className="font-medium text-[14px]">
                GWAY ERP - CHENNAI SOUTH
              </p>
              <p className="font-medium text-[14px]">Chrompet</p>
            </div>
          </Whitespace>
          <Whitespace mb="10px">
            <div className="flex justify-between mb-3">
              <ComponentTitle title="TEAM" mb="0" />
              <TableButton size="small" buttonType={TableIconConstant.edit} />
            </div>
            <div className="pr-20">
              <p className="font-medium text-[14px]">FE team</p>
            </div>
          </Whitespace>
          <Whitespace pd={"0px"}>
            <div className="flex justify-between mb-3 pt-3 pl-3 pr-3">
              <ComponentTitle title="Skills" mb="0" />
              <TableButton size="small" buttonType={TableIconConstant.edit} />
            </div>
            <div className="mb-4 border-t px-2 font-medium text-[14px]">
              <p className="font-medium mt-4 text-[14px]">HTML</p>
            </div>
            <div className="border-t mb-4 px-3 font-medium text-[14px]">
              <p className="font-medium mt-4  text-[14px]">CSS</p>
            </div>
          </Whitespace>
        </div>
      </div>
    </>
  );
};

export default EmployeeViewPage;
