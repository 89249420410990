import React from "react";
import CustomButton from "../CustomButton";

const CustomCancelSubmit = ({
  classProp = "",
  cancelLabel = "Cancel",
  clearLabel = "Clear",
  submitLabel = "Save",
  handleClear,
  handleClose,
  permission,
  handleSubmit,
  showClear = false,
  showCancel = true,
  type = true,
}) => {
  return (
    <div
      className={`flex justify-end gap-2 items-center ${
        classProp ? classProp : ""
      }`}
    >
      <CustomButton
        text={cancelLabel}
        btnType={"secondary"}
        size="large"
        onClick={handleClose}
      >
        {cancelLabel}
      </CustomButton>
      {showClear && (
        <CustomButton
          permission={permission}
          text={clearLabel}
          btnType={"secondary"}
          size="large"
          onClick={handleClear}
        >
          {clearLabel}
        </CustomButton>
      )}
      <CustomButton
        permission={permission}
        btnType={"primary"}
        text={submitLabel}
        type={type ? "submit" : ""}
        onClick={handleSubmit}
      >
        {submitLabel}
      </CustomButton>
    </div>
  );
};

export default CustomCancelSubmit;
