import React from "react";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../../Components/Atoms/CustomCancelSubmit";
import TextArea from "../../../Components/Atoms/TextArea";
import CustomSelect from "../../../Components/Atoms/CustomSelect";
import ToggleButton from "../../../Components/Atoms/ToggleButton";

const TimeOffRequestForm = () => {
  return (
    <>
      <div className="">
        <div className="columnTwo ">
          <CustomInput labelText="Start Date" type="date" />
          <CustomInput labelText="To Date" type="date" />
        </div>

        <div className={`flex items-center gap-3 mb-5`}>
          <h3 className="text-sm font-medium">All Day</h3>
          <ToggleButton />
        </div>
        <div className="columnTwo ">
          <CustomSelect
            labelText="Choose Team"
            placeholder={"Choose Team Name"}
          />
          <CustomSelect labelText="User" placeholder={"Choose User"} />
        </div>
        <div className="columnTwo ">
          <CustomSelect
            labelText="Time Off Type"
            placeholder={"Choose Time Off Type"}
          />
        </div>

        <TextArea labelText="Remarks" placeholder="Enter Remarks" />
        <div className="mt-3">
          <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
        </div>
      </div>
    </>
  );
};

export default TimeOffRequestForm;
