import React from "react";
import EmptyImage from "../../../../Assets/EmptyImage.svg";
import Whitespace from "../../../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../../../Components/Atoms/ComponentTitle";
import CustomButton from "../../../../Components/Atoms/CustomButton";
import { useState } from "react";
import { useCallback } from "react";
import { Add } from "@mui/icons-material";
import CustomDialog from "../../../../Components/Molecules/CustomDialog";
import CustomTable from "../../../../Components/Molecules/CustomTable";
import { useNavigate } from "react-router-dom";
import DiscountFeeForm from "./DiscountFeeForm";

function DiscountFeePage() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);
  const data = [
    {
      siNo: 1,
      questionGroup: "Group 1",
      noOfQuestions: 5,
      notifyEndDate: "22.07.2024",
    },
  ];
  return (
    <>
      <CustomDialog
        width="600px"
        minWidth="750px"
        Title={"Create New Discount / Fee"}
        open={open}
        handleClose={handleClose}
      >
        <DiscountFeeForm />
      </CustomDialog>
      <Whitespace>
        <div className="flex items-center mb-7">
          <ComponentTitle title="DISCOUNT & FEES" mb="0" />
          <CustomButton size="medium" onClick={() => handleOpen()}>
            <Add sx={{ fontSize: "15px" }} />
            New&nbsp;Discount
          </CustomButton>
        </div>
        <div className="overflow-auto">
          <CustomTable
            tableTitle={["#", "NAME", "TYPE", "AMOUNT", "ACTIONS"]}
            navigateView={() => navigate("/employee/view")}
            tableData={data}
            viewButton={true}
            editButton={true}
            deleteButton={false}
          />
        </div>
      </Whitespace>
    </>
  );
}

export default DiscountFeePage;
