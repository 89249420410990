import React, { useState } from "react";
import { Add } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import SubHeader from "../../../Components/Molecules/Subheader";
import ShiftCalendar from "./ShiftManagementTable";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import ShiftForm from "./ShiftManagementForm";

const ShiftManagementPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomDialog
        width="600px"
        minWidth="750px"
        Title={"Create New User Shift"}
        open={open}
        handleClose={handleClose}
      >
        <ShiftForm />
      </CustomDialog>
      <div className="">
        <SubHeader
          headings={[
            {
              name: "Employees",
            },
            {
              name: "Shift Management",
            },
          ]}
          actions={[
            {
              type: "button",
              name: "New Employee Shift",

              onclick: () => {
                handleOpen();
              },
              icon: (
                <Add
                  className="custom-role-add-icon"
                  sx={{
                    fontSize: "18px",
                    marginRight: "5px",
                    color: "#7F7F7F",
                  }}
                />
              ),
            },
          ]}
        />
        <Whitespace mt="23px" className={"px-8 py-3"}>
          <ShiftCalendar />
        </Whitespace>
      </div>
    </>
  );
};

export default ShiftManagementPage;
