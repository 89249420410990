import React from "react";
import ComponentTitle from "../../../Components/Atoms/ComponentTitle";
import Whitespace from "../../../Components/Atoms/WhiteSpace";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomButton from "../../../Components/Atoms/CustomButton";

const PasswordSettingsForm = () => {
  return (
    <>
      <section className="flex flex-col">
        <Whitespace>
          <>
            <ComponentTitle title="PASSWORD SETTINGS" />
            <form className="flex flex-col bg-white">
              <div className="flex flex-col w-[40%]">
                <CustomInput
                  name="currentpassword"
                  labelText="Current Password"
                  placeholder="Enter Current Password"
                  type="password"
                />

                <CustomInput
                  name="newpassword"
                  labelText="New Password"
                  placeholder="Enter New Password"
                  type="password"
                  required
                />

                <CustomInput
                  name="confirmpassword"
                  labelText="Confirm Password"
                  placeholder="Enter Confirm Password"
                  type="password"
                  required
                />

                <div className="flex justify-end mb-4">
                  <CustomButton btnType="primary">Save</CustomButton>
                </div>
              </div>
            </form>
          </>
        </Whitespace>
      </section>
    </>
  );
};

export default PasswordSettingsForm;
