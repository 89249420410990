import React, { useState } from "react";
import SubHeader from "../../../Components/Molecules/Subheader";
import FilterSortby from "../../../Components/Molecules/FilterSortBy";
import TableButton from "../../../Components/Atoms/TableButton";
import { TableIconConstant } from "../../../constants";
import CustomDialog from "../../../Components/Molecules/CustomDialog";

import { useCallback } from "react";
import { Add } from "@mui/icons-material";
import AvailableStatus from "../../../Components/Atoms/AvailableStatus";
import TimesheetApprovalForm from "./timeSheetApprovalsForm";

const TimeSheetApprovalPage = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];

  return (
    <>
      <CustomDialog
        width="600px"
        minWidth="750px"
        Title={"Create New Timesheet Approval"}
        open={open}
        handleClose={handleClose}
      >
        <TimesheetApprovalForm />
      </CustomDialog>

      <div className="">
        <SubHeader
          headings={[
            {
              name: "TimeSheet",
            },
            {
              name: "Timesheet Approvals",
            },
          ]}
          actions={[
            {
              type: "button",

              name: "New Timesheet Approvals",
              onclick: handleOpen,

              icon: (
                <Add
                  sx={{
                    fontSize: "18px",
                    marginRight: "5px",
                    color: "#7F7F7F",
                  }}
                />
              ),
            },
          ]}
        />
        <>
          <FilterSortby
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
          />
          <div className="overflow-auto">
            <table className="bg-[#ffffff] border border-gray-300 border-collapse w-full">
              <tbody>
                <tr className="border-b border-gray-300">
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"S.No"}
                  </th>
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"NAME"}
                  </th>
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"TYPE"}
                  </th>
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"PUNCHED TIME"}
                  </th>
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"auth picture"}
                  </th>
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"LOCATION"}
                  </th>

                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"actions"}
                  </th>
                </tr>

                <tr className="border-b border-gray-300">
                  <td className="text-[13px] font-medium leading-4 tracking-normal text-left text-primColor py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    01
                  </td>

                  <td className="text-[13px] font-medium leading-4 tracking-normal text-left text-[#474747] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    #1234 - User One
                  </td>
                  <td className="text-[13px] font-medium leading-4 tracking-normal text-left text-[#474747] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    2024/01/10 to 2024/01/08
                  </td>
                  <td className="text-[13px] font-medium leading-4 tracking-normal text-left text-[#474747] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    0h 0m
                  </td>
                  <td className="text-[13px] font-medium leading-4 tracking-normal text-left text-[#474747] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    0h 0m
                  </td>
                  <td className="text-sm font-medium leading-4 tracking-normal text-left  py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <AvailableStatus status={"Active"} type={"Active"} />
                  </td>

                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <div className="flex gap-2">
                      <TableButton
                        size={"small"}
                        buttonType={TableIconConstant.edit}
                        onClickBtn={() => handleOpen()}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      </div>
    </>
  );
};

export default TimeSheetApprovalPage;
