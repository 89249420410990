import { useRef, useEffect } from "react";
import InputLable from "../InputLable";

const CustomSelect = ({
  value,
  onChange,
  labelText,
  permission,
  customCls = "",
  name,
  setValue = "value",
  setOption = "name",
  placeholder,
  errorMsg,
  required,
  onBlur,
  data_testid,
  data,
  multiple,
  disabled,
  defaultValue,
  scrollIntoView = () => {},
}) => {
  const errorRef = useRef(null);

  useEffect(() => {
    if (errorRef.current) {
      scrollIntoView(errorRef.current);
    }
  });

  const getOption = (each) => {
    if (Array.isArray(setOption)) {
      return `${each[setOption[0]]} - ${each[setOption[1]] || ""}`;
    } else {
      const [key1, key2] = setOption.split(".");
      return key2 ? each[key1][key2] : each[key1];
    }
  };

  return (
    <div
      className={`w-full flex flex-col mb-2.5 relative md:mb-0`}
      permission={permission}
    >
      {labelText ? (
        <InputLable labelText={labelText} required={required} />
      ) : null}
      <select
        disabled={disabled}
        data-testid={data_testid}
        multiple={multiple ? true : false}
        value={value || placeholder}
        className={`w-full h-[50px] mb-5 rounded border border-gray-400 font-xs pl-3 text-[#2B2B2B] bg-no-repeat bg-right-2 pr-8 ${
          errorMsg ? "bounce" : ""
        } ${customCls}`}
        onChange={onChange}
        name={name}
        onBlur={onBlur}
      >
        <option value={placeholder} disabled>
          {placeholder}
        </option>
        {data?.map((each, index) => (
          <option key={index} value={each[setValue]}>
            {getOption(each)}
          </option>
        ))}
      </select>
      {errorMsg && (
        <p ref={errorRef} className="input-field-error-msg">
          {errorMsg}
        </p>
      )}
    </div>
  );
};

export default CustomSelect;
