import React from "react";
import CustomInput from "../../../../Components/Atoms/CustomInput";
import CustomCancelSubmit from "../../../../Components/Atoms/CustomCancelSubmit";

const ApprovalForm = () => {
  return (
    <>
      <div className="">
        <div className=" ">
          <CustomInput
            margin="true"
            name="User"
            labelText="Team"
            placeholder="Choose Team*"
            type="text"
          />
        </div>
        <div className="columnTwo">
          <CustomInput
            margin="true"
            name="User"
            labelText="Choose User"
            placeholder="Choose User"
            type="text"
          />
          <CustomInput
            margin="true"
            name="User"
            labelText="Enter Hierarchy Level"
            placeholder="Eg: 1"
            type="text"
          />
        </div>
        <div className="mt-3">
          <CustomCancelSubmit cancelLabel="Close" submitLabel="Save" />
        </div>
      </div>
    </>
  );
};

export default ApprovalForm;
