import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SendIcon from "@mui/icons-material/Send";
import React, { useRef } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TableButton from "../../Components/Atoms/TableButton";
import SubHeader from "../../Components/Molecules/Subheader";
import Whitespace from "../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../Components/Atoms/ComponentTitle";
import { TableIconConstant } from "../../constants";
import { useState } from "react";
import CustomButton from "../../Components/Atoms/CustomButton";
import { Add } from "@mui/icons-material";

const tabConstant = {
  draft: "DRAFT",
  sent: "AWAIT_PAYMENT",
  paid: "PAID",
  partially_paid: "PARTIALLY_PAID",
  archived: "ARCHIVED",
  cancelled: "CANCELED",
};

const InvoiceView = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <>
        <div className="">
          <SubHeader
            headings={[
              {
                name: "invoice",
                url: "/invoice",
              },
              {
                name: `ivo`,
              },
            ]}
            actions={[
              {
                type: "button",
                btnType: "secondary",
                name: "print",
                onclick: () => {
                  window.print();
                },
                icon: (
                  <PrintIcon
                    sx={{
                      fontSize: "16px",
                      marginRight: "8px",
                    }}
                  />
                ),
              },
              {
                type: "button",
                name: "pdf",

                icon: (
                  <PictureAsPdfIcon
                    sx={{
                      fontSize: "16px",
                      marginRight: "8px",
                    }}
                  />
                ),
              },
              {
                type: "button",
                name: "send",

                icon: (
                  <SendIcon
                    sx={{
                      fontSize: "16px",
                      marginRight: "8px",
                    }}
                  />
                ),
              },
              {
                name: "more",
                icon: (
                  <KeyboardArrowDownIcon
                    sx={{ fontSize: "16px", marginLeft: "8px" }}
                  />
                ),
                iconPosition: "right",
                type: "menu_drop_down",
                children: [
                  {
                    name: "edit_invoice",
                  },
                  {
                    name: "cancel_invoice",
                  },
                  {
                    name: "mark_as_paid",
                  },

                  {
                    name: "archive_invoice",
                  },
                  {
                    name: "delete_invoice",
                  },
                ],
              },
            ]}
          />
          <div className="flex gap-4">
            <div className="w-[75%]">
              <div className="flex justify-center p-2.5">
                <div className="flex flex-col items-center w-24">
                  <div className="flex items-center">
                    <div className="w-5 h-5 rounded-full border-4 border-gray-300   items-center justify-center">
                      {/* <DoneIcon
                        className="text-white"
                        style={{ fontSize: "18px" }}
                      /> */}
                    </div>
                  </div>
                  <p className="text-xs font-bold text-teal-900 mt-2">draft</p>
                </div>
                {/* SENT */}
                <div className="flex flex-col items-center w-24">
                  <div className="flex items-center">
                    <div className="w-5 h-5 rounded-full border-4 border-gray-300 bg-white flex items-center justify-center">
                      <CancelIcon
                        className="text-red-700"
                        style={{ fontSize: "22px" }}
                      />
                    </div>
                  </div>
                  <p className="text-xs font-bold text-teal-900 mt-2">sent</p>
                </div>
                {/* PAID */}
                <div className="flex flex-col items-center w-24">
                  <div className="flex items-center">
                    <div className="w-5 h-5 rounded-full border-4 border-gray-300 bg-teal-900 flex items-center justify-center">
                      <DoneIcon
                        className="text-white"
                        style={{ fontSize: "18px" }}
                      />
                    </div>
                  </div>
                  <p className="text-xs font-bold text-teal-900 mt-2">paid</p>
                </div>
                {/* CANCELED */}
                <div className="flex flex-col items-center w-24">
                  <div className="flex items-center">
                    <div className="w-5 h-5 rounded-full border-4 border-gray-300 bg-teal-900 flex items-center justify-center">
                      <DoneIcon
                        className="text-white"
                        style={{ fontSize: "18px" }}
                      />
                    </div>
                  </div>
                  <p className="text-xs font-bold text-teal-900 mt-2">
                    archived
                  </p>
                </div>
              </div>
              {/* <Draft tabConstant={tabConstant} Componentpdf={Componentpdf} /> */}
              draft
            </div>

            <div className="w-[25%]">
              <div className="mb-4">
                <Whitespace pd={""} mb="10px">
                  <div className="flex justify-between items-center mb-2">
                    <ComponentTitle title="ORGANIZATION" mb="0" />
                    <TableButton
                      size="small"
                      buttonType={TableIconConstant.edit}
                    />
                  </div>
                  <div className="flex gap-1">
                    <AccountCircleIcon
                      className="bg-white text-gray-300 mr-2.5 w-14 h-14 rounded-full overflow-hidden"
                      sx={{ fontSize: "60px" }}
                    />

                    <div className="">
                      <p className="font-medium text-[14px]">
                        {"Sample Organization"}
                      </p>
                      <div className="flex items-center gap-3">
                        <MailIcon
                          className=" "
                          sx={{ fontSize: "14px", color: "grey" }}
                        />
                        <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                          {"Email"}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <LocationOnIcon
                          className=""
                          sx={{ fontSize: "14px", color: "grey" }}
                        />
                        <div>
                          <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                            Sample Address,
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Whitespace>
              </div>
              <div className="mb-4">
                <Whitespace pd={""} mb="10px">
                  <div className="flex justify-between items-center mb-2">
                    <ComponentTitle title="ORGANIZATION" mb="0" />
                    <TableButton
                      size="small"
                      buttonType={TableIconConstant.edit}
                    />
                  </div>
                  <div className="flex gap-1">
                    <AccountCircleIcon
                      className="bg-white text-gray-300 mr-2.5 w-14 h-14 rounded-full overflow-hidden"
                      sx={{ fontSize: "60px" }}
                    />

                    <div className="">
                      <p className="font-medium text-[14px]">
                        {"Sample Organization"}
                      </p>
                      <div className="flex items-center gap-3">
                        <MailIcon
                          className=" "
                          sx={{ fontSize: "14px", color: "grey" }}
                        />
                        <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                          {"Email"}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <LocationOnIcon
                          className=""
                          sx={{ fontSize: "14px", color: "grey" }}
                        />
                        <div>
                          <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                            Sample Address,
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Whitespace>
              </div>
              <div className="mb-4">
                <Whitespace>
                  <ComponentTitle title="attachments" />
                  <div className="flex flex-col gap-2 items-center mb-8">
                    <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                      No Data Found
                    </p>
                    <CustomButton size="medium" onClick={handleOpen}>
                      <Add sx={{ color: "#696969", fontSize: "17px" }} />
                      Add Attachments
                    </CustomButton>
                  </div>
                </Whitespace>
              </div>
              <div className="mb-4">
                <Whitespace>
                  <ComponentTitle title="notes" />
                  <div className="flex flex-col gap-2 items-center mb-8">
                    <p className="text-[14px] font-medium leading-6 text-left opacity-80 m-0">
                      No Notes Found
                    </p>
                    <CustomButton size="medium" onClick={handleOpen}>
                      <Add sx={{ color: "#696969", fontSize: "17px" }} />
                      Add Notes
                    </CustomButton>
                  </div>
                </Whitespace>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default InvoiceView;
