import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import InputLable from "../InputLable";

const InputField = ({
  value,
  permission,
  onChange,
  onBlur,
  customCls = "",
  name,
  labelText,
  placeholder,
  datemax,
  accept,
  disabled,
  type,
  multiple,
  maximumDate,
  minimumDate,
  customErrMsg,
  data_testid,
  required,
  errorMsg,
  hiddenFileInput,
  id = "",
  visibility = "",
  defaultUnit = "",
  toggleDefaultUnit = () => {},
  scrollIntoView = () => {},
}) => {
  const [inputType, setInputType] = useState(type);
  const errorRef = useRef(null);

  useEffect(() => {
    if (errorRef.current) {
      scrollIntoView(errorRef.current);
    }
  }, [errorRef.current]);

  return (
    <div
      className={`flex flex-col mb-5 ${
        type === "checkbox" || type === "radio"
          ? "flex-row-reverse items-center justify-end"
          : ""
      }`}
    >
      {labelText && (
        <InputLable type={type} required={required} labelText={labelText} />
      )}

      {type === "password" ? (
        <div className="relative flex items-center">
          <input
            disabled={disabled}
            className={`${
              errorMsg ? "border-red-500 animate-bounce" : "border-gray-400"
            } px-4 py-3 rounded-md w-full border`}
            value={value}
            onChange={onChange}
            name={name}
            placeholder={placeholder}
            type={inputType}
            onBlur={onBlur}
            data-testid={name}
          />
          <span
            onClick={() => {
              if (!disabled) {
                setInputType((prev) =>
                  prev === "password" ? "text" : "password"
                );
              }
            }}
            className="absolute right-4 cursor-pointer"
          >
            {inputType === "password" ? (
              <VisibilityIcon sx={{ fontSize: "24px", color: "grey" }} />
            ) : (
              <VisibilityOffIcon sx={{ fontSize: "24px", color: "grey" }} />
            )}
          </span>
        </div>
      ) : defaultUnit ? (
        <div className="relative flex items-center">
          <input
            disabled={disabled}
            className={`${
              errorMsg ? "border-red-500 animate-bounce" : "border-gray-400"
            } px-4 py-3 rounded-md w-full border`}
            value={value}
            onChange={onChange}
            name={name}
            placeholder={placeholder}
            type={type}
            onBlur={onBlur}
          />
          <span
            onClick={toggleDefaultUnit}
            className="absolute right-4 cursor-pointer percentage-color"
          >
            {defaultUnit}
          </span>
        </div>
      ) : type === "checkbox" ? (
        <input
          permission={permission}
          disabled={disabled}
          className={`${
            errorMsg ? "border-red-500 animate-bounce" : "border-gray-400"
          } ${customCls} px-4 py-3 rounded-md w-full border`}
          value={value && value !== "false" ? value : false}
          checked={value && value !== "false" ? value : false}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
          type={type}
          onBlur={onBlur}
          id={id}
        />
      ) : type === "color" ? (
        <input
          permission={permission}
          max={
            datemax
              ? new Date().toISOString().split("T")[0]
              : maximumDate
              ? new Date(maximumDate).toISOString().slice(0, -5)
              : ""
          }
          min={
            minimumDate ? new Date(minimumDate).toISOString().slice(0, -5) : ""
          }
          disabled={disabled}
          className={`${
            errorMsg ? "border-red-500 animate-bounce" : "border-gray-400"
          } ${customCls} h-full p-1 rounded-md w-full border`}
          value={value}
          checked={value}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
          data-testid={data_testid}
          type={type}
          onBlur={onBlur}
          accept={accept}
          multiple={multiple}
          ref={hiddenFileInput}
          id={id}
          style={{ visibility: visibility }}
        />
      ) : (
        <input
          permission={permission}
          max={
            datemax
              ? new Date().toISOString().split("T")[0]
              : maximumDate
              ? new Date(maximumDate).toISOString().slice(0, -5)
              : ""
          }
          min={
            minimumDate ? new Date(minimumDate).toISOString().slice(0, -5) : ""
          }
          disabled={disabled}
          className={`${
            errorMsg ? "border-red-500 animate-bounce" : "border-gray-400"
          } ${customCls} px-4 py-3 rounded-md w-full border`}
          value={value}
          checked={value}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
          data-testid={data_testid}
          type={type}
          onBlur={onBlur}
          accept={accept}
          multiple={multiple}
          ref={hiddenFileInput}
          id={id}
          style={{ visibility: visibility }}
        />
      )}

      {customErrMsg ? (
        <p
          ref={errorRef}
          className="text-red-500 text-xs absolute bottom-0 left-0 mb-4"
        >
          {customErrMsg}
        </p>
      ) : (
        errorMsg && (
          <p
            ref={errorRef}
            className={
              type === "checkbox"
                ? "text-red-500 text-xs absolute bottom-0 left-0 mb-4 input-field-error-msg-radio"
                : "text-red-500 text-xs absolute bottom-0 left-0 mb-4 input-field-error-msg"
            }
            data-testid={errorMsg}
          >
            {errorMsg}
          </p>
        )
      )}
    </div>
  );
};

export default InputField;

InputField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  customErrMsg: PropTypes.string,
  errorMsg: PropTypes.string,
};
