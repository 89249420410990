import React from "react";

const TimeSheetDefaulerPage = () => {
  return (
    <>
      <div className="">
        <>
          <div className="overflow-auto">
            <table className="bg-[#ffffff] border border-gray-300 border-collapse w-full">
              <tbody>
                <tr className="border-b border-gray-300">
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"#"}
                  </th>
                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"Co-Worker"}
                  </th>

                  <th className="text-[12px] font-bold leading-3.5 tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7 uppercase">
                    {"shift time"}
                  </th>
                </tr>

                <tr className="border-b border-gray-300">
                  <td className="text-[12px] font-bold  tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7">
                    0
                  </td>
                  <td className="text-sm font-medium leading-4 tracking-normal text-left text-[#002e25] py-4 px-7 max-w-[20rem] whitespace-nowrap overflow-hidden text-ellipsis">
                    <div className="">
                      <p className="text-[13px] font-bold">Pavi</p>
                      <p className="text-[12px] text-[#140C00] opacity-80 font-medium -mb-2">
                        pavi@gmail.com
                      </p>
                    </div>
                  </td>
                  <td className="text-[12px] font-bold  tracking-normal text-left text-tableColor border-b-[var(--border)] py-4 px-7">
                    09:00 to 17:00
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      </div>{" "}
    </>
  );
};

export default TimeSheetDefaulerPage;
