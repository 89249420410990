import React, { useCallback, useState } from "react";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../../Components/Molecules/CustomTable";
import Whitespace from "../../../../Components/Atoms/WhiteSpace";
import ComponentTitle from "../../../../Components/Atoms/ComponentTitle";
import CustomButton from "../../../../Components/Atoms/CustomButton";
import CustomDialog from "../../../../Components/Molecules/CustomDialog";
import PaymentModesForm from "./PaymentModesForm";

const PaymentModesSettings = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const navigate = useNavigate();
  const data = [
    {
      siNo: 1,
      questionGroup: "Group 1",
      noOfQuestions: 5,
      notifyEndDate: "22.07.2024",
    },
    {
      siNo: 2,
      questionGroup: "Group 2",
      noOfQuestions: 10,
      notifyEndDate: "22.07.2024",
    },
  ];

  return (
    <>
      <CustomDialog
        handleClose={handleClose}
        open={open}
        Title={"Create Payment Mode"}
      >
        <PaymentModesForm />
      </CustomDialog>
      <div className="">
        <Whitespace>
          <div className="flex items-center mb-7">
            <ComponentTitle title="pAYMENT MODES" mb="0" />
            <CustomButton size="medium" onClick={() => handleOpen()}>
              <Add sx={{ fontSize: "15px" }} />
              New&nbsp;PaymentMode
            </CustomButton>
          </div>
          <div className="overflow-auto">
            <CustomTable
              tableTitle={["#", "name", "type", "description", "ACTIONS"]}
              navigateView={() => navigate("/employee/view")}
              tableData={data}
              viewButton={true}
              editButton={true}
              deleteButton={false}
            />
          </div>
        </Whitespace>
      </div>
    </>
  );
};

export default PaymentModesSettings;
