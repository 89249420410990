import React, { useCallback, useState } from "react";
import { Add } from "@mui/icons-material";
import SubHeader from "../../Components/Molecules/Subheader";
import CustomTable from "../../Components/Molecules/CustomTable";
import FilterSortby from "../../Components/Molecules/FilterSortBy";
import { useNavigate } from "react-router-dom";
import InventoryIcon from "@mui/icons-material/Inventory";

const ProductPage = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const onClickFilter = () => {
    handleOpen();
  };
  const navigate = useNavigate();
  const data = [
    {
      quoteID: "01",
      siNo: 2,
      questionGroup: "Group 2",
      noOfQuestions: 10,
      notifyEndDate: "22.07.2024",
      answeredStatus: "Unanswered",
      answeredStatuss: "Unanswered",
      answeredStatus1: "98989",
      reviewedStatus: "98989",
      status: "Unavailable",
    },
  ];
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const sortList = [
    { name: "Created Date", value: "createdAt-ASC" },
    { name: "Created Date", value: "createdAt-DSC" },
    { name: "Name-ASC", value: "name-ASC" },
    { name: "Name-DSC", value: "name-DSC" },
  ];

  return (
    <>
      <div className="">
        <SubHeader
          headings={[
            {
              name: "Products",
            },
          ]}
          actions={[
            {
              type: "button",
              name: "New Products",

              onclick: () => {
                navigate("/product/productform");
              },
              icon: (
                <Add
                  sx={{
                    fontSize: "18px",
                    marginRight: "5px",
                    color: "#7F7F7F",
                  }}
                />
              ),
            },
            {
              type: "button",
              name: "Manage Groups",
              onclick: () => {
                navigate("/product/productgroup");
              },

              icon: (
                <InventoryIcon
                  className="employee-add-icon"
                  sx={{
                    fontSize: "18px",
                    color: "#7F7F7F",
                    marginRight: "5px",
                  }}
                />
              ),
            },
          ]}
        />
        <div>
          <FilterSortby
            // showFilter={true}
            exportBtn="false"
            totalPage={"3"}
            getPageNumber={setPage}
            getQuickFilter={setQuickFilter}
            getSortByValue={setSortBy}
            sortList={sortList}
            roleList={[{ name: "All Roles", _id: "All Roles", disabled: true }]}
            onClickFilter={onClickFilter}
          />
          <div className="overflow-auto">
            <CustomTable
              tableTitle={[
                "#",
                "NAME",
                "PRODUCT NO",
                "type",
                "CATEGORY",

                "BRAND",
                "SPECIFICATION",
                "quantity",
                "UNIT PRICE (inr)",
                "AVAILABILITY",

                "ACTIONS",
              ]}
              navigateView={() => navigate("/employee/view")}
              tableData={data}
              viewButton={true}
              editButton={true}
              deleteButton={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
