import React from "react";
import ComponentTitle from "../../Atoms/ComponentTitle";

export default function SettingsTabsList(props) {
  return (
    <div className=" bg-white border border-gray-300 rounded-md">
      <div className="p-3">
        <ComponentTitle title={props.title} mb="0" />
      </div>
      {props.list.map((each) => (
        <div
          id={each?.value || each}
          className={`flex justify-between p-3 cursor-pointer border-t ${
            (each?.value || each) === props.current ? "bg-gray-200" : ""
          }`}
          onClick={() => props.page(each?.value || each)}
          onKeyDown={() => {}}
        >
          <h3 className="m-0 text-xs font-medium max-w-xs truncate">
            {each?.name || each}
          </h3>
        </div>
      ))}
    </div>
  );
}
